import ReactApexChart from "react-apexcharts";
import React from "react";
import {ALL_COLORS} from "@dashboard/dashboard/config/common/colors";

interface IDonutProps {
  series: number[];
  categories: string[];
  [x: string]: any;
}

const Donut = (props: IDonutProps) => {
  const {series, categories, ...restProps} = props;

  return (
    <ReactApexChart
      type="donut"
      height={'95%'}
      width={'95%'}
      series={series}
      options={{
        theme: {
          mode: 'light',
        },
        labels: categories,
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%"
          },
        },
        colors: ALL_COLORS,
        legend: {
          position: 'right',
        }
      }}
      {...restProps}
    />
  );
};

export default Donut;