import Donut from '@dashboard/dashboard/components/chart/donut';
import {useGetByPurpose} from '@dashboard/dashboard/services/activity';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const ActivityPurpose = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetByPurpose(props?.filters ?? {});
  });

  const { results = [] } = data ?? {};

  let chartData: any = {
    l: [],
    s: [],
  };
  if (!!results && !!results?.length) {
    chartData = results.reduce((f: any, v: any) => {
      f['l'].push(v?.purpose?.languageKey ?? v?.purpose?.keyName ?? '-- No Purpose Name --');
      f['s'].push(v?.total ?? 0);
      return f;
    }, chartData);
  }

  return <Donut series={chartData?.s} categories={chartData?.l} />;
};

export default withChartContainer(ActivityPurpose);
