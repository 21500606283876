import * as keyNames from '@desk/ticket/config/key-names';
import * as commonConfig from '@base/config/view-field';
import * as components from './components';
import * as commonComponents from '@base/config/view-field/components';
import { IFieldConfig } from '@base/types/interfaces/common';
import {
  //useDeleteTicketProduct,
  //useDeleteTicketTag,
  //useTicketChannels,
  usePublicTicketTags,
} from '@desk/ticket/services/ticket-service';
import { useGetModuleProcesses } from '@settings/process/hooks/useProcess';

const viewFieldsConfig: IFieldConfig = {
  ...commonConfig?.default,
  [keyNames.KEY_TICKET_CODE]: {
    component: commonComponents.TextView,
    componentProps: {
      userPermission: {
        isEdit: false,
      },
    },
    schema: 'code',
  },
  [keyNames.KEY_TICKET_PRIORITY]: {
    component: components.TicketPriority,
    componentProps: {
      userPermission: {
        isEdit: false,
        isShow: true,
      },
    },
    schema: `priority {
      keyName
      languageKey
    }`,
  },
  [keyNames.KEY_TICKET_PRODUCT]: {
    schema: `product {
      id
      name
    }`,
  },
  [keyNames.KEY_TICKET_CATEGORY]: {
    component: components.CategoryProductView,
    schema: `
      category {
        id
        name
      }
    `,
    getValue: (viewData: any) => {
      //// console.log('TicketCategoryProduct', viewData);
      return {
        product: viewData?.product
          ? {
              ...viewData?.product,
              value: viewData?.product.id,
              label: viewData?.product.name,
            }
          : null,
        category: viewData?.category
          ? {
              ...viewData?.category,
              value: viewData?.category.id,
              label: viewData?.category.name,
            }
          : null,
      };
    },
    getMutationValue: (value: any) => {
      //// console.log('mutation value', value);
      const cateRule = value?.category?.rules?.[0] || null;

      return {
        product: value?.product
          ? {
              id: value.product.id,
              name: value.product.name,
            }
          : null,
        category: value?.category
          ? {
              id: value.category.id,
              name: value.category.name,
            }
          : null,
        priority: cateRule ? cateRule.priority.keyName : '',
      };
    },
  },
  [keyNames.KEY_TICKET_PROCESS]: {
    component: commonComponents.Lookup,
    componentProps: {
      fetchList: useGetModuleProcesses,
      fieldValue: 'id',
      fieldLabel: 'name',
      extraParams: { module: 'MODULE_DESK' },
      isSearch: false,
      userPermission: {
        isEdit: false,
      },
    },
    schema: `process {
      id
      name
    }`,
  },
  [keyNames.KEY_TICKET_RESPONSE_DUE]: {
    component: components.FristRespondDueView,
    componentProps: {
      userPermission: {
        isEdit: false,
      },
    },
    schema: 'firstRespondDue',
  },
  [keyNames.KEY_TICKET_RESOLUTION_DUE]: {
    component: components.ResolutionDueView,
    componentProps: {
      userPermission: {
        isEdit: false,
      },
    },
    schema: 'resolutionDue',
  },
  [keyNames.KEY_TICKET_DURATION]: {
    component: commonComponents.DurationView,
    componentProps: {
      userPermission: {
        isEdit: false,
      },
    },
    schema: `
      duration
      durationUnit
    `,
    getValue: (viewData: any) => {
      //// console.log('Duration getValue', viewData);
      return {
        duration: viewData?.duration,
        durationUnit: viewData?.durationUnit,
      };
    },
  },
  [keyNames.KEY_TICKET_ASSIGN_GROUP]: {
    //component: components.AssginedGroupView,
    component: components.AssignGroupRepView,
    componentProps: {
      single: true,
      userPermission: {
        isEdit: false,
      },
    },
    schema: `assignedGroup {
      id
      name
      reps {
        id
        user{
          id
          name
        }
      }
    }`,
    getValue: (viewData: any) => {
      return {
        [keyNames.KEY_TICKET_ASSIGN_GROUP]: viewData[keyNames.KEY_TICKET_ASSIGN_GROUP] || null,
        [keyNames.KEY_TICKET_ASSIGN_USER]: viewData[keyNames.KEY_TICKET_ASSIGN_USER]?.user
          ? {
              ...viewData[keyNames.KEY_TICKET_ASSIGN_USER]?.user,
              group: viewData[keyNames.KEY_TICKET_ASSIGN_USER]?.group,
            }
          : null,
      };
    },
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_TICKET_ASSIGN_GROUP]: value[keyNames.KEY_TICKET_ASSIGN_GROUP]
          ? {
              id: value[keyNames.KEY_TICKET_ASSIGN_GROUP].id,
              name: value[keyNames.KEY_TICKET_ASSIGN_GROUP].name,
            }
          : null,
        [keyNames.KEY_TICKET_ASSIGN_USER]: value[keyNames.KEY_TICKET_ASSIGN_USER]
          ? {
              user: {
                id: value[keyNames.KEY_TICKET_ASSIGN_USER].id,
                name: value[keyNames.KEY_TICKET_ASSIGN_USER].name,
              },
              group: {
                id: value[keyNames.KEY_TICKET_ASSIGN_USER].id,
                name: value[keyNames.KEY_TICKET_ASSIGN_USER].name,
              }, //value[keyNames.KEY_TICKET_ASSIGN_USER]?.group || null, @TODO
            }
          : null,
      };
    },
  },
  [keyNames.KEY_TICKET_ASSIGN_USER]: {
    schema: `assignedUser {
      user {
          id
          name
      }
      group {
        id
        name
      }
    }`,
  },
  [keyNames.KEY_TICKET_CC_USERS]: {
    component: components.CcUsersView,
    componentProps: {
      showAvatar: true,
      userPermission: {
        isEdit: false,
      },
    },
    schema: `ccUsers {
      user {
        id
        name
      }
      group {
        id
        name
      }
    }`,

    getMutationValue: (value: any) => {
      const nUsers =
        value?.length > 0
          ? value.map((_item: any) => {
              return {
                user: {
                  id: _item.id,
                  name: _item.name,
                },
                group: {
                  id: _item.id,
                  name: _item.name,
                }, //TODO
              };
            })
          : [];
      return {
        [keyNames.KEY_TICKET_CC_USERS]: nUsers,
      };
    },
  },
  [keyNames.KEY_TICKET_CUSTOMER]: {
    //component: components.CustomerView,
    component: components.CustomerContactView,
    componentProps: {
      userPermission: {
        isEdit: false,
      },
    },
    schema: `customer {
      id
      name
      category
      emails{
        id
        label {
          languageKey
          label
        }
        labelValue
        email
      }
      phones{
        id
        label {
          languageKey
          label
        }
        labelValue
        phoneNumber
        extension

      }
    }`,
    getValue: (viewData: any) => {
      return {
        [keyNames.KEY_TICKET_CUSTOMER]: viewData[keyNames.KEY_TICKET_CUSTOMER]
          ? {
              ...viewData[keyNames.KEY_TICKET_CUSTOMER],
              value: viewData[keyNames.KEY_TICKET_CUSTOMER].id,
              label: viewData[keyNames.KEY_TICKET_CUSTOMER].name,
            }
          : null,
        [keyNames.KEY_TICKET_CONTACT]: viewData[keyNames.KEY_TICKET_CONTACT]
          ? {
              ...viewData[keyNames.KEY_TICKET_CONTACT],
              value: viewData[keyNames.KEY_TICKET_CONTACT].id,
              label: viewData[keyNames.KEY_TICKET_CONTACT].name,
            }
          : null,
      };
    },
    getMutationValue: (value: any) => {
      //// console.log('mutaion value', value);
      return {
        [keyNames.KEY_TICKET_CUSTOMER]: value[keyNames.KEY_TICKET_CUSTOMER]
          ? {
              id: value[keyNames.KEY_TICKET_CUSTOMER].id,
              name: value[keyNames.KEY_TICKET_CUSTOMER].name,
            }
          : null,
        [keyNames.KEY_TICKET_CONTACT]: value[keyNames.KEY_TICKET_CONTACT]
          ? {
              id: value[keyNames.KEY_TICKET_CONTACT].id,
              name: value[keyNames.KEY_TICKET_CONTACT].name,
            }
          : null,
      };
    },
  },
  [keyNames.KEY_TICKET_CONTACT]: {
    schema: `contact {
      id
      name
      category
      emails{
        id
      label {
        languageKey
        label
      }
        labelValue
        email
      }
      phones{
        id
        label {
          languageKey
          label
        }
        labelValue
        phoneNumber
        extension

      }
    }`,
  },
  [keyNames.KEY_TICKET_TAG]: {
    component: components.TagView,
    componentProps: {
      fetchList: usePublicTicketTags,
      fieldValue: 'id',
      fieldLabel: 'name',
    },
    schema: `tags {
      id
      name
    }`,
  },
  // [keyNames.KEY_TICKET_CHANNEL]: {
  //   component: commonComponents.Lookup,
  //   componentProps: {
  //     fetchList: useTicketChannels,
  //     fieldValue: 'id',
  //     fieldLabel: 'name',
  //     userPermission: {
  //       isEdit: false,
  //     },
  //   },
  //   schema: `channel {
  //     id
  //     name
  //   }`,
  // },
  [keyNames.KEY_TICKET_CONTENT]: {
    component: components.EditorView,
    schema: 'content',
  },
  [keyNames.KEY_TICKET_CLOSED_AT]: {
    component: commonComponents.DateTimeView,
    componentProps: {
      userPermission: {
        isEdit: false,
      },
    },
    schema: 'closedAt',
  },
  [keyNames.KEY_TICKET_CLASSIFICATION]: {
    component: components.ClassificationView,
    schema: `classifications {
      classification {
        id
        name
      }
      value
    }`,
    getValueEdit: (apiValue: any) => {
      let inputValue: any = { region: null, language: null };
      apiValue?.map((_ele: any) => {
        if (_ele.classification.name === 'Language') {
          inputValue.language = { ..._ele.classification, value: _ele.value, label: _ele.value };
        }
        if (_ele.classification.name === 'Region') {
          inputValue.region = { ..._ele.classification, value: _ele.value, label: _ele.value };
        }
      });
      return inputValue;
    },
    getMutationValue: (value: any) => {
      //// console.log('mutation value', value);
      const newParams: any = [];
      if (value?.language) {
        newParams.push({
          classification: {
            id: value.language.id,
            name: value.language.name,
          },
          value: value.language.value,
        });
      }
      if (value?.region) {
        newParams.push({
          classification: {
            id: value.region.id,
            name: value.region.name,
          },
          value: value.region.value,
        });
      }
      return { [keyNames.KEY_TICKET_CLASSIFICATION]: newParams };
    },
    getChangedValue: (value: any) => {
      const newParams: any = [];
      if (value?.language) {
        newParams.push({
          classification: {
            id: value.language.id,
            name: value.language.name,
          },
          value: value.language.value,
        });
      }
      if (value?.region) {
        newParams.push({
          classification: {
            id: value.region.id,
            name: value.region.name,
          },
          value: value.region.value,
        });
      }
      return newParams;
    },
  },
};
export default viewFieldsConfig;
