import React from "react";
import {round} from "lodash";
import {ArrowDown, ArrowUp} from "react-feather";
import {useGetCustomerTrendCounting} from "@dashboard/dashboard/services/customer";
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

interface CustomerCountingBlockProps {
  title: string
  newValue?: number
  oldValue?: number
}

const CustomerCountingBlock: React.FC<CustomerCountingBlockProps> = (props: CustomerCountingBlockProps) => {
  const {title, newValue = 0, oldValue = 0} = props;

  const percent = oldValue > 0 ? round(((newValue * 100) / oldValue) - 100, 2) : (newValue > 0 ? 100 : 0);

  return (
    <div className="card card-body no-border d-flex flex-column justify-content-center">
      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">{title}</h6>
      <div className="d-flex d-lg-block d-xl-flex align-items-end">
        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{newValue || 0}</h3>
        <p className="tx-11 tx-color-03 mg-b-0">
          {percent >= 0 ? <span className="tx-medium tx-success">{Math.abs(percent)}%<ArrowUp size={14}/></span> :
            <span className="tx-medium tx-danger">{Math.abs(percent)}%<ArrowDown size={14}/></span>}
        </p>
      </div>
      <div className="d-flex d-lg-block d-xl-flex align-items-end mg-t-10">
        <p className="tx-11 tx-uppercase tx-spacing-1 tx-semibold tx-primary mg-b-0">Last:</p>
        <p className="tx-12 tx-normal tx-rubik tx-spacing--1 mg-0 mg-l-2">{oldValue || 0}</p>
      </div>
    </div>
  );
};

// ----

const CustomerTrend = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetCustomerTrendCounting(props?.filters ?? {})
  });

  const {firstCounting = {}, lastCounting = {}}: any = data ?? {};

  return (
    <div className="d-flex flex-column ht-100p">
      <CustomerCountingBlock title={'Total'} newValue={firstCounting?.total} oldValue={lastCounting?.total}/>
      <hr className="bd-dotted wd-90p align-self-center mg-y-5"/>
      <CustomerCountingBlock title={'Account'} newValue={firstCounting?.account} oldValue={lastCounting?.account}/>
      <hr className="bd-dotted wd-90p align-self-center mg-y-5"/>
      <CustomerCountingBlock title={'Contact'} newValue={firstCounting?.contact} oldValue={lastCounting?.contact}/>
    </div>
  );
};

export default withChartContainer(CustomerTrend);