import {ESectionType, ERepeatUnit, EWeekDayEnum} from "@dashboard/main/types/enum";
import {EDisplayModeType} from "@dashboard/report/types/enum";

export const repeatUnitOptions: any = {
  [ERepeatUnit.REPEAT_UNIT_DAILY]: 'Daily',
  [ERepeatUnit.REPEAT_UNIT_WEEKLY]: 'Weekly',
  [ERepeatUnit.REPEAT_UNIT_MONTHLY]: 'Monthly',
  [ERepeatUnit.REPEAT_UNIT_YEARLY]: 'Yearly',
};

export const weekDayOptions: any = {
  [EWeekDayEnum.WEEK_DAY_SUNDAY]: 'Sunday',
  [EWeekDayEnum.WEEK_DAY_MONDAY]: 'Monday',
  [EWeekDayEnum.WEEK_DAY_TUESDAY]: 'Tuesday',
  [EWeekDayEnum.WEEK_DAY_WEDNESDAY]: 'Wednesday',
  [EWeekDayEnum.WEEK_DAY_THURSDAY]: 'Thursday',
  [EWeekDayEnum.WEEK_DAY_FRIDAY]: 'Friday',
  [EWeekDayEnum.WEEK_DAY_SATURDAY]: 'Saturday'
};

export const sectionOptions: any = {
  [ESectionType.SECTION_CUSTOMER]: 'Customer Analysis',
  [ESectionType.SECTION_ACTIVITY]: 'Activity Analysis',
  [ESectionType.SECTION_DESK]: 'Ticket Analysis',
  [ESectionType.SECTION_CUSTOMER_SATISFACTION]: 'Customer Satisfaction Analysis'
};

export const displayModeOptions: any = {
  [EDisplayModeType.DISPLAY_MODE_PORTRAIT]: 'Portrait',
  [EDisplayModeType.DISPLAY_MODE_LANDSCAPE]: 'Landscape',
};