import {keys} from "lodash";
import {EPermit} from "@dashboard/main/types/enum";
import {
  sectionsAdminConfigs,
  sectionUserConfigs
} from "@dashboard/main/configs/sections";

export const keyValueToArray = (source: {[x: string|number]: string|number}) => {
  return keys(source).map((k) => ({label: source[k], value: k}));
}

export const keyValueToObject = (source: any[]) => {
  return source.reduce((f: any, v) => {
    f[v.value] = v.label
    return f;
  }, {});
}

export const getSectionConfigsBy = (userRole: string = EPermit.PERMIT_USER, opts?: any) => {
  return (userRole === EPermit.PERMIT_ADMIN ? sectionsAdminConfigs : sectionUserConfigs);
};

export const getChartConfigsBy = (sectionKey: string, userRole: string = EPermit.PERMIT_USER, opts?: any) => {
  const sectionObjectData = userRole === EPermit.PERMIT_ADMIN ? sectionsAdminConfigs : sectionUserConfigs;

  let objectData: any = {};
  if(sectionKey.toUpperCase() === 'ALL'){
    let reduceProcess = (f: any, s: any) => {
      f[s] = {
        ...f?.[s] ?? {},
        ...sectionObjectData[s]?.chartConfigs
      }
      return f;
    };

    if(opts?.onlyChartConfig){
      reduceProcess = (f: any, s: any) => {
        f = {
          ...f,
          ...sectionObjectData[s]?.chartConfigs
        }
        return f;
      };
    }

    objectData = keys(sectionObjectData).reduce(reduceProcess, {});
  } else {
    objectData = sectionObjectData?.[sectionKey]?.chartConfigs ?? {};
  }

  if(opts?.asArray){
    return keys(objectData)?.map((k: any) => {
      return objectData[k];
    }) ?? [];
  }

  return objectData;
}