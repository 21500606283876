import React, {useEffect, useMemo, useState} from "react";
import {Direction, DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {DndItem} from "@dashboard/dashboard/types/interfaces/dnd";

const getRenderItem = (items: any) => (provided: any, snapshot: any, rubric: any) => {
  const Component = items[rubric.source.index].component;
  const componentProps = items[rubric.source.index].componentProps;
  const draggableProps = items[rubric.source.index].draggableProps;
  const className = items[rubric.source.index].className;
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
      className={className}
      {...draggableProps}
    >
      <Component {...componentProps} />
    </div>
  );
}

interface IDashboardDndProps {
  id: string;
  dndItems: DndItem[];
  direction?: Direction;
  onChange?: (orderedItems: DndItem[]) => void;
}

const DashboardDnd: React.FC<IDashboardDndProps> = (props: IDashboardDndProps) => {
  const {dndItems: defaultItems, id, direction = 'horizontal', onChange} = props;

  const [dndItems, setDndItems] = useState<any>(defaultItems);

  const handleDragEnd = (result: any) => {
    const {source, destination} = result;
    if (!destination) {
      return;
    }

    const newItems = [...dndItems];
    const [removedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removedItem);
    setDndItems(newItems);
  };

  const renderItem = getRenderItem(dndItems);

  useEffect(() => {
    onChange && onChange(dndItems);
  }, [dndItems])

  const DragDropArea = useMemo(() => {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          droppableId={id}
          direction={direction}
          renderClone={renderItem}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className="row"
              style={{
                backgroundColor: snapshot.isDraggingOver
                  ? 'var(--background-hover-color)'
                  : 'var(--background-container)',
              }}
            >
              {dndItems.map((dndItem: any, i: number) => (
                <Draggable key={dndItem.key} draggableId={dndItem.key} index={i}>
                  {renderItem}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }, dndItems);

  return (
    <>{DragDropArea}</>
  );
}

export default DashboardDnd;