import styled from "@emotion/styled";
import React, {useEffect, useRef, useState} from "react";
import {Settings} from "react-feather";
import classNames from "classnames";
import './styles.scss';
import {isEqual, isFunction} from "lodash";
import {clickOutSide, generateUUID} from "@base/utils/helpers";

const ContainerStyled = styled.div`
  z-index: 999999;
  min-width: 340px;
  padding: 0;
  top: 100%;
  
  .list-group {
    .list-group-item {
      display: flex;
      justify-content: space-between;
      border-width: 0 0 1px;

      .handler {
        padding: 0;
      }
    }
  }
`;

interface IWidgetUsedSettingProps {
  id?: string;
  sourceItems: {
    key: string;
    name: string;
    unchecked?: boolean;
  }[];
  selectedKeys?: string[];
  onChange?: (values: string[]) => void;
}

const WidgetUsedSetting = (props: IWidgetUsedSettingProps) => {
  const {id = generateUUID(), sourceItems = [], selectedKeys: srcSelectedKeys = [], onChange} = props;

  const containerRef = useRef<any>(null);
  const initSelectedKeys = srcSelectedKeys.length ? srcSelectedKeys : sourceItems.reduce((f: string[], v) => {
    if(!v?.unchecked){
      f = [...f, v.key];
    }
    return f;
  }, []);

  const [toggled, setToggled] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(initSelectedKeys);

  const handleCheckboxOnChange = (key: string) => {
    const newSelectedKeys = [...selectedKeys];
    const i = newSelectedKeys.indexOf(key);
    if (i === -1) {
      newSelectedKeys.push(key);
    } else {
      newSelectedKeys.splice(i, 1);
    }
    setSelectedKeys(newSelectedKeys);
  }

  let destroy: any = null;
  const handleOnClose = () => {
    setToggled(false);
    if(isFunction(destroy)){
      destroy();
      destroy = null;
    }
  }

  const handleOnApply = () => {
    onChange && onChange(selectedKeys);
    handleOnClose();
  }

  const dropdownId = `widgetUsedSetting-${id}`

  useEffect(() => {
    if (toggled) {
      destroy = clickOutSide(dropdownId, handleOnClose);
    }
  }, [toggled]);

  return (
    <div className="dropdown" id={dropdownId}>
      <button
        type="button"
        className="btn btn-white btn-icon dropdown-toggle mg-l-5"
        aria-haspopup="true" aria-expanded="false"
        onClick={() => setToggled(!toggled)}
      >
        <Settings/>
      </button>
      <ContainerStyled
        className={classNames('dropdown-menu dropdown-menu-right', { show: toggled })}
        ref={containerRef}
      >
        <div className="c-widget">
          <div className="c-widget-header d-flex justify-content-between">
            <div className="c-widget-title">Widget Used/Unused Setting</div>
          </div>
          <div className="c-widget-body scroll-box">
            <ul className="list-group">
              {
                sourceItems.map((v, i) => {
                  return (
                    <li key={i} className="list-group-item d-flex justify-content-between align-items-center">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`checkbox-${v.key}`}
                          defaultChecked={selectedKeys.includes(v.key)}
                          onChange={() => handleCheckboxOnChange(v.key)}
                        />
                        <label className="custom-control-label" htmlFor={`checkbox-${v.key}`}>
                          <span>{v.name}</span>
                        </label>
                      </div>
                    </li>
                  );
                })
              }
            </ul>
          </div>
          <div className="widget-box c-widget-footer">
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-light mg-l-auto"
                onClick={handleOnClose}
              >
                Close
              </button>
              <button type="button" className="btn btn-primary mg-l-5" onClick={handleOnApply} disabled={isEqual(initSelectedKeys.sort(), selectedKeys.sort())}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </ContainerStyled>
    </div>
  )
};

export default WidgetUsedSetting;