import React, {useEffect, useState} from "react";
import {DndSourceItem} from "@dashboard/dashboard/types/interfaces/dnd";
import {SectionBody} from "@dashboard/dashboard/components/section";
import WidgetPositionSetting from "@dashboard/dashboard/components/widget-position-setting";
import {FormIcon} from "@base/components/form";
import WidgetUsedSetting from "@dashboard/dashboard/components/widget-used-setting";
import {getChartConfigsBy} from "@dashboard/main/utils";
import {EPermit, ESectionType} from "@dashboard/main/types/enum";
import classNames from "classnames";

interface SectionProps {
  title: string;
  keyName: ESectionType;
  userRole: EPermit;
  onDOMLoaded?: (is: boolean) => void;
  className?: string;
}

const Section: React.FC<SectionProps> = (props: SectionProps) => {
  const {title, keyName, userRole, onDOMLoaded, className} = props;

  const [isDndSetting, setIsDndSetting] = useState<boolean>(false);

  const chartConfigs = getChartConfigsBy(keyName, userRole, {asArray: true});

  const [sourceItems, setSourceItems] = useState<DndSourceItem[]>(chartConfigs);

  useEffect(() => {
    onDOMLoaded && onDOMLoaded(true)
  }, []);

  const handleOnSave = (items: any[]) => {
    setSourceItems(items);
  };

  const handleWidgetUsedOnChange = (keys: string[]) => {
    const newSourceItems = sourceItems.map((v) => {
      v.unused = !keys.includes(v.key);
      return v;
    });
    setSourceItems(newSourceItems);
  }

  return (
    <div className={classNames("card", className)}>
      <div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
        <div>
          <h5 className="mg-b-5">{title}</h5>
        </div>
        <div className="d-flex mg-t-20 mg-sm-t-0">
          <button className="btn btn-white btn-xs" type="button" onClick={() => setIsDndSetting(true)}>
            <FormIcon icon="column_settings" iconType="icon"/>
          </button>
          <div className="btn-group flex-fill mg-l-5">
            <WidgetUsedSetting
              id="customer-user"
              sourceItems={sourceItems.map((v) => {
                return {
                  key: v.key,
                  name: v?.componentProps?.title,
                  unchecked: v?.unused ?? false
                }
              })}
              onChange={handleWidgetUsedOnChange}
            />
          </div>
        </div>
      </div>
      <div className="card-body">
        <SectionBody sourceItems={sourceItems}/>
      </div>
      {
        isDndSetting &&
          <WidgetPositionSetting
              id="customer-user"
              sourceItems={sourceItems}
              onClose={() => setIsDndSetting(false)}
              onSave={handleOnSave}
          />
      }
    </div>
  );
};

export default Section;