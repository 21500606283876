import {EChartType} from "@dashboard/main/types/enum";
import CustomerTrend from "@dashboard/dashboard/containers/customer-trend";
import CustomerByPeriod from "@dashboard/dashboard/containers/customer-by-period";
import CustomerUpdated from "@dashboard/dashboard/containers/customer-updated";
import CustomerRating from "@dashboard/dashboard/containers/customer-rating";
import CustomerIndustry from "@dashboard/dashboard/containers/customer-industry";
import ActivityTrend from "@dashboard/dashboard/containers/activity-trend";
import ActivityTodo from "@dashboard/dashboard/containers/activity-todo";
import ActivityRepPerformance from "@dashboard/dashboard/containers/activity-rep-performance";
import ActivityByType from "@dashboard/dashboard/containers/activity-by-type";
import ActivityGroupTotal from "@dashboard/dashboard/containers/activity-group-total";
import ActivityDuration from "@dashboard/dashboard/containers/activity-duration";
import ActivityGroupDuration from "@dashboard/dashboard/containers/activity-group-duration";
import ActivityGroupPerformance from "@dashboard/dashboard/containers/activity-group-performance";
import ActivityTotalByGroup from "@dashboard/dashboard/containers/activity-total-by-group";
import ActivityTotalByRep from "@dashboard/dashboard/containers/activity-total-by-rep";
import ActivityTopPerformerByCall from "@dashboard/dashboard/containers/activity-top-performer-by-call";
import ActivityTopPerformerByTask from "@dashboard/dashboard/containers/activity-top-performer-by-task";
import ActivityTopPerformerByEmail from "@dashboard/dashboard/containers/activity-top-performer-by-email";
import ActivityTopPerformerBySms from "@dashboard/dashboard/containers/activity-top-performer-by-sms";
import ActivityPurpose from "@dashboard/dashboard/containers/activity-purpose";
import ActivityOverdueRepChart from "@dashboard/dashboard/containers/activity-overdue-rep-chart";
import ActivityPriorityChart from "@dashboard/dashboard/containers/activity-priority-chart";
import TicketTrend from "@dashboard/dashboard/containers/ticket-trend";
import TicketPerformerByResolveTimeEfficiency
  from "@dashboard/dashboard/containers/ticket-performer-by-resolve-time-efficiency";
import TicketOverview from "@dashboard/dashboard/containers/ticket-overview";
import TicketPerformerByResolveTime from "@dashboard/dashboard/containers/ticket-performer-by-resolve-time";
import TicketGroupPerformance from "@dashboard/dashboard/containers/ticket-group-performance";
import TicketGroupPerformanceByAssignedTicket
  from "@dashboard/dashboard/containers/ticket-group-performance-by-assigned-ticket";
import TicketGroupPerformanceByResolveTimeEfficiency
  from "@dashboard/dashboard/containers/ticket-group-performance-by-resolve-time-efficiency";
import TicketGroupPerformanceByResolvedTicket
  from "@dashboard/dashboard/containers/ticket-group-performance-by-resolved-ticket";
import TicketGroupPerformanceByUnresolvedTicket
  from "@dashboard/dashboard/containers/ticket-group-performance-by-unresolved-ticket";
import TicketGroupPerformanceByResolutionTime
  from "@dashboard/dashboard/containers/ticket-group-performance-by-resolution-time";
import TicketPriority from "@dashboard/dashboard/containers/ticket-priority";
import TicketTopIssue from "@dashboard/dashboard/containers/ticket-top-issue";
import KBRepPublishedArticle from "@dashboard/dashboard/containers/kb-rep-published-article";
import KBRepInsertedArticle from "@dashboard/dashboard/containers/kb-rep-inserted-article";
import TicketByCategory from "@dashboard/dashboard/containers/ticket-by-category";
import TicketAvgSupportPerformance from "@dashboard/dashboard/containers/ticket-avg-support-performance";
import TicketRepPerformance from "@dashboard/dashboard/containers/ticket-rep-performance";
import TicketTopPerformerByAvgResolutionTime
  from "@dashboard/dashboard/containers/ticket-top-performer-by-avg-resolution-time";
import TicketTopPerformerByResolvedTicket
  from "@dashboard/dashboard/containers/ticket-top-performer-by-resolved-ticket";
import TicketTopPerformerByResolveTimeEfficiency
  from "@dashboard/dashboard/containers/ticket-top-performer-by-resolve-time-efficiency";
import TicketTopPerformerByUnresolvedTicket
  from "@dashboard/dashboard/containers/ticket-top-performer-by-unresolved-ticket";
import TicketRepByAvgResolutionTime from "@dashboard/dashboard/containers/ticket-rep-by-avg-resolution-time";
import TicketRepByResolvedTicket from "@dashboard/dashboard/containers/ticket-rep-by-resolved-ticket";
import TicketRepByResolveTimeEfficiency from "@dashboard/dashboard/containers/ticket-rep-by-resolve-time-efficiency";
import TicketRepByUnresolvedTicket from "@dashboard/dashboard/containers/ticket-rep-by-unresolved-ticket";
import TicketTopGroupByAvgResolutionTime from "@dashboard/dashboard/containers/ticket-top-group-by-avg-resolution-time";
import TicketTopGroupByAvgResolveTimeEfficiency
  from "@dashboard/dashboard/containers/ticket-top-group-by-avg-resolve-time-efficiency";
import TicketTopGroupByResolvedTicket from "@dashboard/dashboard/containers/ticket-top-group-by-resolved-ticket";
import TicketTopGroupByUnresolvedTicket from "@dashboard/dashboard/containers/ticket-top-group-by-unresolved-ticket";
import KBOverview from "@dashboard/dashboard/containers/kb-overview";
import KBRepViewedArticle from "@dashboard/dashboard/containers/kb-rep-viewed-article";
import CustomerSatisfactionAvgCustomerSatisfaction
  from "@dashboard/dashboard/containers/customer-satisfaction-avg-customer-satisfaction";
import CustomerSatisfactionQuestion1 from "@dashboard/dashboard/containers/customer-satisfaction-question-1";
import CustomerSatisfactionQuestion2 from "@dashboard/dashboard/containers/customer-satisfaction-question-2";

export const chartComponents: any = {
  [EChartType.CHART_CUSTOMER_TREND]: {
    component: CustomerTrend
  },
  [EChartType.CHART_CUSTOMER_PERIOD]: {
    component: CustomerByPeriod
  },
  [EChartType.CHART_CUSTOMER_RATING]: {
    component: CustomerRating
  },
  [EChartType.CHART_CUSTOMER_LAST_UPDATED]: {
    component: CustomerUpdated
  },
  [EChartType.CHART_CUSTOMER_INDUSTRY]: {
    component: CustomerIndustry
  },

  [EChartType.CHART_ACTIVITY_TREND]: {
    component: ActivityTrend
  },
  [EChartType.CHART_ACTIVITY_TODO]: {
    component: ActivityTodo
  },
  [EChartType.CHART_ACTIVITY_REP_PERFORMANCE]: {
    component: ActivityRepPerformance
  },
  [EChartType.CHART_ACTIVITY_TYPE]: {
    component: ActivityByType
  },
  [EChartType.CHART_ACTIVITY_GROUP_TOTAL]: {
    component: ActivityGroupTotal
  },
  [EChartType.CHART_ACTIVITY_DURATION]: {
    component: ActivityDuration
  },
  [EChartType.CHART_ACTIVITY_GROUP_DURATION]: {
    component: ActivityGroupDuration
  },
  [EChartType.CHART_ACTIVITY_GROUP_PERFORMANCE]: {
    component: ActivityGroupPerformance
  },
  [EChartType.CHART_ACTIVITY_TOTAL_BY_GROUP]: {
    component: ActivityTotalByGroup
  },
  [EChartType.CHART_ACTIVITY_TOTAL_BY_REP]: {
    component: ActivityTotalByRep
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_CALL]: {
    component: ActivityTopPerformerByCall
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_TASK]: {
    component: ActivityTopPerformerByTask
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_EMAIL]: {
    component: ActivityTopPerformerByEmail
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_SMS]: {
    component: ActivityTopPerformerBySms
  },
  [EChartType.CHART_ACTIVITY_PURPOSE]: {
    component: ActivityPurpose
  },
  [EChartType.CHART_ACTIVITY_BY_TYPE]: {
    component: ActivityByType
  },
  [EChartType.CHART_ACTIVITY_OVERDUE_REP_CHART]: {
    component: ActivityOverdueRepChart
  },
  [EChartType.CHART_ACTIVITY_BY_PRIORITY_CHART]: {
    component: ActivityPriorityChart
  },

  [EChartType.CHART_TICKET_TREND]: {
    component: TicketTrend
  },
  [EChartType.CHART_TICKET_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY]: {
    component: TicketPerformerByResolveTimeEfficiency
  },
  [EChartType.CHART_TICKET_OVERVIEW]: {
    component: TicketOverview
  },
  [EChartType.CHART_TICKET_PERFORMER_BY_RESOLVE_TIME]: {
    component: TicketPerformerByResolveTime
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE]: {
    component: TicketGroupPerformance
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_ASSIGNED_TICKET]: {
    component: TicketGroupPerformanceByAssignedTicket
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVE_TIME_EFFICIENCY]: {
    component: TicketGroupPerformanceByResolveTimeEfficiency
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVED_TICKET]: {
    component: TicketGroupPerformanceByResolvedTicket
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_UNRESOLVED_TICKET]: {
    component: TicketGroupPerformanceByUnresolvedTicket
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLUTION_TIME]: {
    component: TicketGroupPerformanceByResolutionTime
  },
  [EChartType.CHART_TICKET_PRIORITY]: {
    component: TicketPriority
  },
  [EChartType.CHART_TICKET_TOP_ISSUE]: {
    component: TicketTopIssue
  },
  [EChartType.CHART_KB_REP_PUBLISHED_ARTICLE]: {
    component: KBRepPublishedArticle
  },
  [EChartType.CHART_KB_REP_INSERTED_ARTICLE]: {
    component: KBRepInsertedArticle
  },
  [EChartType.CHART_TICKET_BY_CATEGORY]: {
    component: TicketByCategory
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMER]: {
    component: TicketGroupPerformance
  },
  [EChartType.CHART_TICKET_REP_PERFORMER]: {
    component: TicketRepPerformance
  },
  [EChartType.CHART_TICKET_AVG_SUPPORT_PERFORMER]: {
    component: TicketAvgSupportPerformance
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_AVG_RESOLUTION_TIME]: {
    component: TicketTopPerformerByAvgResolutionTime
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_RESOLVED_TICKET]: {
    component: TicketTopPerformerByResolvedTicket
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY]: {
    component: TicketTopPerformerByResolveTimeEfficiency
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_UNRESOLVED_TICKET]: {
    component: TicketTopPerformerByUnresolvedTicket
  },
  [EChartType.CHART_TICKET_REP_BY_AVG_RESOLUTION_TIME]: {
    component: TicketRepByAvgResolutionTime
  },
  [EChartType.CHART_TICKET_REP_BY_RESOLVED_TICKET]: {
    component: TicketRepByResolvedTicket
  },
  [EChartType.CHART_TICKET_REP_BY_RESOLVE_TIME_EFFICIENCY]: {
    component: TicketRepByResolveTimeEfficiency
  },
  [EChartType.CHART_TICKET_REP_BY_UNRESOLVED_TICKET]: {
    component: TicketRepByUnresolvedTicket
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME]: {
    component: TicketTopGroupByAvgResolutionTime
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME_EFFICIENCY]: {
    component: TicketTopGroupByAvgResolveTimeEfficiency
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLVED_TICKET]: {
    component: TicketTopGroupByResolvedTicket
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_UNRESOLVED_TICKET]: {
    component: TicketTopGroupByUnresolvedTicket
  },
  [EChartType.CHART_KB_OVERVIEW]: {
    component: KBOverview
  },
  [EChartType.CHART_KB_REP_VIEWED_ARTICLE]: {
    component: KBRepViewedArticle
  },

  [EChartType.CHART_CUSTOMER_SATISFACTION_AVG_CUSTOMER_SATISFACTION]: {
    component: CustomerSatisfactionAvgCustomerSatisfaction
  },
  [EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_1]: {
    component: CustomerSatisfactionQuestion1
  },
  [EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_2]: {
    component: CustomerSatisfactionQuestion2
  },
};