import React from 'react';
import {useRecoilValue} from 'recoil';
import ViewSummary from '@base/containers/view-summary';
import {viewDataByMenuAtom} from '@base/recoil/atoms';
import {
  KEY_NAME_CREATED_AT,
  KEY_NAME_UPDATED_AT,
  KEY_NAME_UPDATED_BY
} from "@base/config/key-names";
import {ViewFields} from "@base/components/hook-form/view";
import {REPORT_NAME} from "@dashboard/report/configs/key-names";

interface IViewCenterProps {
  id: string;
  category: string;
  showTop?: boolean;
  onRefetch?: () => void;
}

const ViewCenter: React.FC<IViewCenterProps> = (props) => {
  const {showTop, category, id, onRefetch} = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(category));

  const hiddenFields: string[] = [
    REPORT_NAME,
    KEY_NAME_UPDATED_AT,
    KEY_NAME_UPDATED_BY,
    KEY_NAME_CREATED_AT
  ];

  const allowReFetchFields: string[] = [];

  let newBasicFields = viewData?.layout?.data?.[0]?.children ?? [];
  newBasicFields = newBasicFields.filter((_field: any) => !hiddenFields.includes(_field.keyName));

  return (
    <div className="h-100">
      <div
        className="flex-grow-1 scroll-box pd-20"
        style={{height: 'calc(100vh - 100px)'}}
      >
        <div className="form-row mg-x-0 mg-t-10">
          <ViewFields
            fields={newBasicFields}
            ignoreFields={hiddenFields}
            menu={viewData?.menu ?? ''}
            menuSource={viewData?.menuSource ?? ''}
            menuSourceId={viewData?.menuSourceId ?? ''}
            refetchFields={allowReFetchFields}
            onRefetch={onRefetch}
          />
        </div>
      </div>
    </div>
  );
};
export default ViewCenter;
