import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Input } from '@base/components/form';
import { Search } from 'react-feather';
import SelectDropdown from '@base/components/form/select-dropdown';
import {
  DATE_BY_OPTIONS,
  FILTER_BY_OPTIONS,
  GROUP_BY_OPTIONS,
} from '@site-page/config/desk/contants';
import SelectDropdownWithDates from '@base/components/form/select-dropdown-dates';
import SelectDropdownFilters from '@base/components/form/select-dropdown-filters';

const Header = (props: any) => {
  const { filterData, onFilterChange, onRefresh, onSearch } = props;
  //state
  const [date, setDate] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [groupBy, setGroupBy] = useState<any>('all');
  //// console.log('filterData', filterData);

  //filter change
  const handleFilterChange = (data: any, type: string) => {
    if (type === 'date') {
      const newData: any = {};
      for (const k in data) {
        newData[k] = [dayjs(data[k][0]).toISOString(), dayjs(data[k][1]).toISOString()];
      }
      setDate(newData);
      //callback
      onFilterChange && onFilterChange({ ...newData, ...filter, ...groupBy });
    } else if (type === 'filter') {
      setFilter(data);
      //callback
      onFilterChange && onFilterChange({ ...date, ...data, ...groupBy });
    } else {
      setGroupBy(data ? { groupBy: data } : {});
      //callback
      onFilterChange && onFilterChange({ ...date, ...filter, ...{ groupBy: data } });
    }
  };

  //selected dates
  let selectedDates: any = {};
  filterData &&
    DATE_BY_OPTIONS.map((_option: any) => {
      if (filterData[_option.value]) {
        if (filterData[_option.value]?.length > 0) {
          selectedDates[_option.value] = filterData[_option.value];
        }
      }
    });

  return (
    <div className="d-flex flex-wrap align-items-center pd-y-10 pd-x-15">
      <SelectDropdown
        options={GROUP_BY_OPTIONS}
        selected={filterData?.groupBy || GROUP_BY_OPTIONS[0].value}
        placeholder="Group By"
        onChange={(data: any) => handleFilterChange(data.value, 'groupBy')}
      />
      <SelectDropdownWithDates
        placeholder="Select date"
        options={DATE_BY_OPTIONS}
        selected={selectedDates}
        onChange={(data: any) => handleFilterChange(data, 'date')}
      />
      <SelectDropdownFilters
        placeholder="Filters"
        options={FILTER_BY_OPTIONS}
        //selected={filterData}
        onChange={(data: any) => handleFilterChange(data, 'filter')}
      />
      <div className="d-flex mg-l-auto">
        <Input
          type="text"
          rightIcon={<Search />}
          className="wd-300"
          onEnter={(text: string) => onSearch(text)}
        />
        <Button
          color="white"
          icon="RefreshCw"
          className="btn-icon mg-l-5"
          onClick={() => {
            setDate({});
            setFilter({});
            onRefresh();
          }}
        />
      </div>
    </div>
  );
};

export default Header;
