import classNames from "classnames";
import {ChartWrapper} from "@dashboard/main/components/chart-wrapper";
import {VirtualChart} from "@dashboard/main/components/virtual-chart";
import {forEach, keys} from "lodash";
import React from "react";
import SectionEmpty from "../section-empty";
import {DisplayModeProps} from "@dashboard/report/types/interfaces/components";

const preProcessSectionConfigs = (sectionConfigs: any, sectionKeys: string[], chartKeys: string[], chartsPerSection?: number) => {
  let selectedSections: any = [];
  forEach(sectionKeys, (sk) => {
    let {chartConfigs = {}, ...section} = sectionConfigs?.[sk] ?? {};
    const selectedChartConfigs: any = chartKeys.reduce((f: any, k) => {
      if(!!chartConfigs?.[k]){
        f[k] = chartConfigs[k];
      }
      return f;
    }, {});

    let newCharts: any[] = [], reached = 0, i = 0;
    const limited = !!chartsPerSection ? chartsPerSection : 24; // 24 means col-lg-12 and col-lg-12
    forEach(selectedChartConfigs, (chartConfig: any, ck: string) => {
      const gridCol = chartConfig?.wrapperProps?.gridColLandscape ?? chartConfig?.wrapperProps?.gridCol ?? 0;
      reached = reached + (chartsPerSection ? 1 : gridCol);
      newCharts = [...newCharts, chartConfig];
      const isLimited = reached >= limited;
      if(i === (keys(selectedChartConfigs)?.length - 1) || isLimited){
        selectedSections = [...selectedSections, {...section, chartConfigs: [...newCharts]}]
        reached = 0;
        newCharts = [];
        if(isLimited){
          section = {...section, componentProps: {...section?.componentProps ?? {}, isExtend: true}, chartConfigs: []};
        }
      }
      i++;
    });
  });

  return selectedSections;
};

interface DisplayLandscapeProps extends DisplayModeProps {
  parentProps: {
    width?: number;
  };
}

const DisplayLandscape = (props: DisplayLandscapeProps) => {
  const {
    sectionKeys = [],
    chartKeys = [],
    sectionConfigs = {},
    isDisplayGrid = false,
    isVirtualChart = false,
    parentProps= {}
  } = props;

  const newSectionConfigs = preProcessSectionConfigs(sectionConfigs, sectionKeys, chartKeys, isDisplayGrid ? 3 : 0);
  const howManySections: number = newSectionConfigs?.length ?? 0;
  const {width = 0} = parentProps ?? {};
  const scrollWidth = !!width ? (width * howManySections) + ((howManySections - 1) * 20) : 'auto';

  if (!howManySections){
    return <SectionEmpty/>;
  }

  return (
    <div className={classNames("wd-100p overflow-x-auto", {
      'ht-500': isVirtualChart,
      'ht-600': !isVirtualChart
    })} style={{overflowY: 'hidden'}}>
      <div className="ht-100p pd-y-5" style={{width: scrollWidth}}>
        {
          newSectionConfigs.map((s: any, i: number) => {
            const {
              chartConfigs: newChartConfigs = [],
              componentProps: sectionProps = {}
            } = s ?? {};
            return (
              <div className={classNames('card ht-100p d-inline-block', {'mg-t-10 mg-l-20': i > 0})} key={i} style={{
                width: !!width ? width : 'auto'
              }}>
                <div className="card-header tx-semibold">{sectionProps?.title}</div>
                <div className="pd-10 ht-100p bg-light">
                  <div className="row row-xs">
                    {
                      newChartConfigs.map((c: any, ii: number) => {
                        const {
                          component,
                          componentProps,
                          wrapperProps,
                        } = c ?? {};

                        const Component = isVirtualChart ? VirtualChart : component;

                        const {className, ...wrapperRestProps} = wrapperProps;

                        return (
                          <ChartWrapper className={classNames({
                            'col-4 ht-500': isDisplayGrid,
                            'ht-400': isVirtualChart,
                            [className]: !isDisplayGrid
                          })} {...wrapperRestProps} key={ii}>
                            <div className="bg-white wd-100p ht-100p overflow-auto">
                              <Component {...componentProps}/>
                            </div>
                          </ChartWrapper>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default DisplayLandscape;