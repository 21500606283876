export enum ERepeatUnit {
  REPEAT_UNIT_NONE = 'REPEAT_UNIT_NONE',
  REPEAT_UNIT_DAILY = 'REPEAT_UNIT_DAILY',
  REPEAT_UNIT_WEEKLY = 'REPEAT_UNIT_WEEKLY',
  REPEAT_UNIT_MONTHLY = 'REPEAT_UNIT_MONTHLY',
  REPEAT_UNIT_YEARLY = 'REPEAT_UNIT_YEARLY',
}

export enum EWeekDayEnum {
  WEEK_DAY_SUNDAY = 0,
  WEEK_DAY_MONDAY = 1,
  WEEK_DAY_TUESDAY = 2,
  WEEK_DAY_WEDNESDAY = 3,
  WEEK_DAY_THURSDAY = 4,
  WEEK_DAY_FRIDAY = 5,
  WEEK_DAY_SATURDAY = 6,
}

export enum EPermit {
  PERMIT_USER = 'PERMIT_USER',
  PERMIT_ADMIN = 'PERMIT_ADMIN',
  PERMIT_ALL = 'PERMIT_ALL',
}

export enum ESectionType {
  SECTION_CUSTOMER = 'SECTION_CUSTOMER',
  SECTION_ACTIVITY = 'SECTION_ACTIVITY',
  SECTION_DESK = 'SECTION_DESK',
  SECTION_CUSTOMER_SATISFACTION = 'SECTION_CUSTOMER_SATISFACTION',
}

export enum EChartType {
  CHART_CUSTOMER_TREND = 'CHART_CUSTOMER_TREND',
  CHART_CUSTOMER_RATING = 'CHART_CUSTOMER_RATING',
  CHART_CUSTOMER_PERIOD = 'CHART_CUSTOMER_PERIOD',
  CHART_CUSTOMER_INDUSTRY = 'CHART_CUSTOMER_INDUSTRY',
  CHART_CUSTOMER_LAST_UPDATED = 'CHART_CUSTOMER_LAST_UPDATED',

  CHART_ACTIVITY_TREND = 'CHART_ACTIVITY_TREND',
  CHART_ACTIVITY_TODO = 'CHART_ACTIVITY_TODO',
  CHART_ACTIVITY_REP_PERFORMANCE = 'CHART_ACTIVITY_REP_PERFORMANCE',
  CHART_ACTIVITY_TYPE = 'CHART_ACTIVITY_TYPE',
  CHART_ACTIVITY_GROUP_TOTAL = 'CHART_ACTIVITY_GROUP_TOTAL',
  CHART_ACTIVITY_DURATION = 'CHART_ACTIVITY_DURATION',
  CHART_ACTIVITY_GROUP_DURATION = 'CHART_ACTIVITY_GROUP_DURATION',
  CHART_ACTIVITY_GROUP_PERFORMANCE = 'CHART_ACTIVITY_GROUP_PERFORMANCE',
  CHART_ACTIVITY_TOTAL_BY_GROUP = 'CHART_ACTIVITY_TOTAL_BY_GROUP',
  CHART_ACTIVITY_TOTAL_BY_REP = 'CHART_ACTIVITY_TOTAL_BY_REP',
  CHART_ACTIVITY_TOP_PERFORMER_BY_CALL = 'CHART_ACTIVITY_TOP_PERFORMER_BY_CALL',
  CHART_ACTIVITY_TOP_PERFORMER_BY_TASK = 'CHART_ACTIVITY_TOP_PERFORMER_BY_TASK',
  CHART_ACTIVITY_TOP_PERFORMER_BY_EMAIL = 'CHART_ACTIVITY_TOP_PERFORMER_BY_EMAIL',
  CHART_ACTIVITY_TOP_PERFORMER_BY_SMS = 'CHART_ACTIVITY_TOP_PERFORMER_BY_SMS',
  CHART_ACTIVITY_PURPOSE = 'CHART_ACTIVITY_PURPOSE',
  CHART_ACTIVITY_BY_TYPE = 'CHART_ACTIVITY_BY_TYPE',
  CHART_ACTIVITY_OVERDUE_REP_CHART = 'CHART_ACTIVITY_OVERDUE_REP_CHART',
  CHART_ACTIVITY_BY_PRIORITY_CHART = 'CHART_ACTIVITY_BY_PRIORITY_CHART',

  CHART_TICKET_TREND = 'CHART_TICKET_TREND',
  CHART_TICKET_CUSTOMER_SATISFACTION = 'CHART_TICKET_CUSTOMER_SATISFACTION',
  CHART_TICKET_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY = 'CHART_TICKET_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY',
  CHART_TICKET_OVERVIEW = 'CHART_TICKET_OVERVIEW',
  CHART_TICKET_PERFORMER_BY_RESOLVE_TIME = 'CHART_TICKET_PERFORMER_BY_RESOLVE_TIME',
  CHART_TICKET_GROUP_PERFORMANCE = 'CHART_TICKET_GROUP_PERFORMANCE',
  CHART_TICKET_GROUP_PERFORMANCE_BY_ASSIGNED_TICKET = 'CHART_TICKET_GROUP_PERFORMANCE_BY_ASSIGNED_TICKET',
  CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVE_TIME_EFFICIENCY = 'CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVE_TIME_EFFICIENCY',
  CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVED_TICKET = 'CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVED_TICKET',
  CHART_TICKET_GROUP_PERFORMANCE_BY_UNRESOLVED_TICKET = 'CHART_TICKET_GROUP_PERFORMANCE_BY_UNRESOLVED_TICKET',
  CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLUTION_TIME = 'CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLUTION_TIME',
  CHART_TICKET_GROUP_PERFORMANCE_BY_CUSTOMER_SATISFACTION = 'CHART_TICKET_GROUP_PERFORMANCE_BY_CUSTOMER_SATISFACTION',
  CHART_TICKET_PRIORITY = 'CHART_TICKET_PRIORITY',
  CHART_TICKET_TOP_ISSUE = 'CHART_TICKET_TOP_ISSUE',
  CHART_KB_REP_PUBLISHED_ARTICLE = 'CHART_KB_REP_PUBLISHED_ARTICLE',
  CHART_KB_REP_INSERTED_ARTICLE = 'CHART_KB_REP_INSERTED_ARTICLE',
  CHART_TICKET_BY_CATEGORY = 'CHART_TICKET_BY_CATEGORY',
  CHART_TICKET_GROUP_PERFORMER = 'CHART_TICKET_GROUP_PERFORMER',
  CHART_TICKET_REP_PERFORMER = 'CHART_TICKET_REP_PERFORMER',
  CHART_TICKET_AVG_SUPPORT_PERFORMER = 'CHART_TICKET_AVG_SUPPORT_PERFORMER',
  CHART_TICKET_AVG_CUSTOMER_SATISFACTION = 'CHART_TICKET_AVG_CUSTOMER_SATISFACTION',
  CHART_TICKET_TOP_PERFORMER_BY_AVG_RESOLUTION_TIME = 'CHART_TICKET_TOP_PERFORMER_BY_AVG_RESOLUTION_TIME',
  CHART_TICKET_TOP_PERFORMER_BY_SATISFACTION_SCORE = 'CHART_TICKET_TOP_PERFORMER_BY_SATISFACTION_SCORE',
  CHART_TICKET_TOP_PERFORMER_BY_AVG_SATISFACTION_SCORE = 'CHART_TICKET_TOP_PERFORMER_BY_AVG_SATISFACTION_SCORE',
  CHART_TICKET_TOP_PERFORMER_BY_RESOLVED_TICKET = 'CHART_TICKET_TOP_PERFORMER_BY_RESOLVED_TICKET',
  CHART_TICKET_TOP_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY = 'CHART_TICKET_TOP_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY',
  CHART_TICKET_TOP_PERFORMER_BY_UNRESOLVED_TICKET = 'CHART_TICKET_TOP_PERFORMER_BY_UNRESOLVED_TICKET',
  CHART_TICKET_REP_BY_AVG_RESOLUTION_TIME = 'CHART_TICKET_REP_BY_AVG_RESOLUTION_TIME',
  CHART_TICKET_REP_BY_RESOLVED_TICKET = 'CHART_TICKET_REP_BY_RESOLVED_TICKET',
  CHART_TICKET_REP_BY_RESOLVE_TIME_EFFICIENCY = 'CHART_TICKET_REP_BY_RESOLVE_TIME_EFFICIENCY',
  CHART_TICKET_REP_BY_UNRESOLVED_TICKET = 'CHART_TICKET_REP_BY_UNRESOLVED_TICKET',
  CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME = 'CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME',
  CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME_EFFICIENCY = 'CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME_EFFICIENCY',
  CHART_TICKET_TOP_GROUP_BY_AVG_RESOLVED_TICKET = 'CHART_TICKET_TOP_GROUP_BY_AVG_RESOLVED_TICKET',
  CHART_TICKET_TOP_GROUP_BY_AVG_UNRESOLVED_TICKET = 'CHART_TICKET_TOP_GROUP_BY_AVG_UNRESOLVED_TICKET',
  CHART_KB_OVERVIEW = 'CHART_KB_OVERVIEW',
  CHART_KB_REP_VIEWED_ARTICLE = 'CHART_KB_REP_VIEWED_ARTICLE',

  CHART_CUSTOMER_SATISFACTION_AVG_CUSTOMER_SATISFACTION = 'CHART_CUSTOMER_SATISFACTION_AVG_CUSTOMER_SATISFACTION',
  CHART_CUSTOMER_SATISFACTION_QUESTION_1 = 'CHART_CUSTOMER_SATISFACTION_QUESTION_1',
  CHART_CUSTOMER_SATISFACTION_QUESTION_2 = 'CHART_CUSTOMER_SATISFACTION_QUESTION_2',
}