import React, {useEffect, useState} from 'react';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useGetTypeCounting} from '@dashboard/dashboard/services/activity';
import BarStacked from '@dashboard/dashboard/components/chart/bar-stacked';
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const ActivityByType = (props: ChartComponentProps) => {
  const { filters, ...restProps } = props;

  const [listFilter, setListFilter] = useState<any>({
    filter: {
      ...(filters?.filter ?? {}),
    }
  });

  useEffect(() => {
    setListFilter({
      filter: {
        ...listFilter.filter,
        ...(filters?.filter ?? {}),
      }
    });
  }, [filters])

  const {data, isLoading, isFetching} = useChartQuery(restProps, () => {
    return useGetTypeCounting(listFilter ?? {});
  });

  const { results = [], period } = data ?? {};

  let oData: any = {};
  if (!!results) {
    oData = results.reduce(
      (f: any, v: any) => {
        const { email, call, task, sms } = v?.counting ?? {};
        f['categories'].push(v?.date?.name ?? '-');
        f['email']['data'].push(email);
        f['call']['data'].push(call);
        f['task']['data'].push(task);
        f['sms']['data'].push(sms);
        return f;
      },
      {
        categories: [],
        email: {
          name: 'Email',
          data: [],
        },
        call: {
          name: 'Call',
          data: [],
        },
        task: {
          name: 'Task',
          data: [],
        },
        sms: {
          name: 'Sms',
          data: [],
        },
      },
    );
  }

  return <BarStacked
    categories={oData?.categories ?? []}
    series={[oData.email, oData.call, oData.task, oData.sms]}
  />;
};

export default withChartContainer(ActivityByType);
