import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ListBody, ListGrid, ListPagination } from '@base/components/list';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import ListLoading, { GridNothing, TableNothing } from '@base/components/list/list-loading';
import { deviceAtom } from '@base/recoil/atoms/app';
import {Checkbox, Switch} from '@base/components/form';
import {MENU_ANALYTIC} from '@base/config/menus';
import { ListType } from '@base/types/enums';
import { makeTable8Columns } from '@base/components/utils/helpers/react-table';
import { DESC, ASC } from '@base/config/constant';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import * as keyNames from '@dashboard/report/configs/key-names';
import ListGridItem from '@dashboard/report/components/list/list-grid/list-grid-item';
import {reportListFilterState} from "@dashboard/report/recoil/atom";
import {
  KEY_NAME_CREATED_AT,
  KEY_NAME_CREATED_BY,
  KEY_NAME_UPDATED_AT,
  KEY_NAME_UPDATED_BY,
} from '@base/config/key-names';
import { Eye } from 'react-feather';
import {find} from "lodash";

const PageBody: React.FC<any> = (props) => {
  const {
    isSplitMode,
    category,
    isFetching,
    fields,
    itemsList,
    paging,
    selectedIds,
    onChecked,
    onPreview
  } = props;
  const { t } = useTranslation();
  const { device } = useRecoilValue(deviceAtom);
  const [listFilter, setListFilter] = useRecoilState(reportListFilterState);
  let { filter, listType } = listFilter;

  //config disable sortBy columns
  const disableSortByColumns: string | any[] = [];

  //render columns components
  const getMapColumns = () => {
    return {
      [keyNames.REPORT_NAME](col: string, data: any) {
        return (
          <Link to={`/${MENU_ANALYTIC}/${category}/${data['id']}`}>
            {data[col] ? data[col] : '-'}
          </Link>
        );
      },
      [keyNames.REPORT_ACTIVE](col: string, data: any) {
        return <Switch value={data[col]} />;
      },
      [KEY_NAME_CREATED_BY](col: string, data: any) {
        return data?.[col]?.name || '(none)';
      },
      [KEY_NAME_CREATED_AT](col: string, data: any) {
        return convertDateTimeServerToClient({ date: data?.[col] });
      },
      [KEY_NAME_UPDATED_BY](col: string, data: any) {
        return data?.[col]?.name || '(none)';
      },
      [KEY_NAME_UPDATED_AT](col: string, data: any) {
        return convertDateTimeServerToClient({ date: data?.[col] });
      },
      [keyNames.REPORT_REPORTING_CONTENT](col: string, data: any) {
        return (
          <a className="tx-gray-600 lh-0 cursor-pointer" onClick={(e) => {
            e.stopPropagation();
            onPreview && onPreview(data);
          }}>
            <Eye size={14}/>
          </a>
        );
      },
    };
  };

  //set filter sort param
  const setSort = (sort: any) => {
    let newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        sort: sort,
      },
    };
    setListFilter(newListFilter);
  };

  //grid props
  const listProps = {
    category: category, //router category
    listData: itemsList,
    isFetching,
    fetching: <ListLoading />,
    onCheckedRow: (v: string|number) => {
      if(onChecked){
        const newSelectedIds = [...selectedIds];
        const indexOf = newSelectedIds.indexOf(v)
        if(indexOf !== -1){
          newSelectedIds.splice(indexOf, 1);
        } else {
          newSelectedIds.push(v);
        }
        onChecked(newSelectedIds);
      }
    },
    //grid
    isSplitMode,
    selectedIds,
    device: device !== undefined ? device : 'desktop',
    nodata: <GridNothing />,
    columns: fields,
    columnRenderRemap: getMapColumns(),
    hideColumns: [
      keyNames.REPORT_NAME,
      KEY_NAME_CREATED_AT,
      KEY_NAME_UPDATED_AT,
      KEY_NAME_UPDATED_BY
    ]
  };

  //table props
  const newFields = fields.map((_ele: any) => ({
    ..._ele,
    enableSorting: !disableSortByColumns.includes(_ele.keyName),
  }));

  //table paging
  const pagingProps = {
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: paging?.itemPerPage || 15,
    nextPage: paging?.nextPage || null,
    previousPage: paging?.previousPage || null,
    onChange: (page: number, size: number) => {
      const newListFilter = {
        ...listFilter,
        filter: {
          ...listFilter.filter,
          paging: { page, size }
        }
      };
      setListFilter(newListFilter);
    },
  };

  //build columns for table v8
  const columns = useMemo<ColumnDef<any>[]>(() => {
      let newColumns: ColumnDef<any>[] = [
        {
          id: 'select',
          width: '45px',
          header: ({table}) => (
            <Checkbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({row}) => (
            <div className="pd-x-1">
              <Checkbox
                {...{
                  checked: row.getIsSelected(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
        },
        ...makeTable8Columns(newFields, getMapColumns(), {category}, [keyNames.REPORT_REPORTING_CONTENT])
      ];

      if (!!find(newFields, (v) => v.keyName === keyNames.REPORT_REPORTING_CONTENT)) {
        newColumns = [...newColumns, (
          {
            id: keyNames.REPORT_REPORTING_CONTENT,
            width: '100px',
            header: ({table}) => <span>Preview</span>,
            cell: ({row}: any) => {
              return (
                <a className="tx-gray-600 lh-0 cursor-pointer" onClick={() => {onPreview && onPreview(row.original)}}>
                  <Eye size={14}/>
                </a>
              );
            },
          } as (ColumnDef<any>)
        )]
      }

      return newColumns;
    }, [newFields, selectedIds]);

  const listTableProps = {
    nodata: <TableNothing />,
    columns,
    data: itemsList,
    initialState: {
      pageSize: paging?.itemPerPage || 15
    },
    onRowSelect: onChecked,
    rowSelected: selectedIds,
    onSortBy: (clName: any, isSorted: any) => {
      if (isSorted !== false) {
        let orderBy = isSorted === 'desc' ? DESC : ASC;
        setSort({ field: clName, orderBy: orderBy });
      }
    },
  };

  const ListBodyMemo = useMemo(() => {
    return (
      <ListBody isScrollY={listType !== ListType.LIST}>
        {isSplitMode ? (
          <ListGrid
            {...listProps}
            listGridItemRender={(props: any) => <ListGridItem {...props} />}
          />
        ) : (
          <>
            {listType == ListType.LIST && <ListReactTable8 {...listTableProps} />}
            {listType == ListType.GRID && (
              <ListGrid
                {...listProps}
                listGridItemRender={(props: any) => <ListGridItem {...props} />}
              />
            )}
          </>
        )}
        <ListPagination isSplitMode={isSplitMode} type={'full'} {...pagingProps} />
      </ListBody>
    );
  }, [itemsList, isSplitMode, listType, columns]);

  return <>{ListBodyMemo}</>;
};

export default PageBody;
