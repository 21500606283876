import {DndItem, DndSourceItem} from "@dashboard/dashboard/types/interfaces/dnd";
import DashboardDnd from "@dashboard/dashboard/components/dnd";
import React, {useState} from "react";
import DndModal from "@dashboard/dashboard/components/dnd-modal";
import {VirtualChart} from "@dashboard/main/components/virtual-chart";

interface IWidgetPositionSettingProps {
  id: string;
  title?: string;
  sourceItems: DndSourceItem[];
  onClose: () => void;
  onSave: (dndItems: DndItem[]) => void;
  dndDirection?: string;
}

const WidgetPositionSetting = (props: IWidgetPositionSettingProps) => {
  const {
    id,
    title = 'Widget Position Setting',
    sourceItems,
    onClose,
    onSave,
    dndDirection = 'horizontal'
  } = props;

  const dndItems: DndItem[] = sourceItems.reduce((f: DndItem[], item) => {
    const {key, componentProps: {title}, dndItem, dndItemProps = {}, wrapperProps} = item;
    f = [...f, {
      key,
      component: dndItem ?? VirtualChart,
      componentProps: {
        title,
        ...dndItemProps
      },
      draggableProps: wrapperProps
    }];
    return f;
  }, []);

  const [dndDroppedItems, setDndProppedItems] = useState<any[]>([]);

  const handleOnChange = (dndDroppedItems: any[]) => {
    setDndProppedItems(dndDroppedItems)
  };

  const handleOnSave = () => {
    if (onSave) {
      const sortingKeys = dndDroppedItems.map(v => v.key);
      const newDndItems = [...sourceItems].sort((a, b) => {
        return sortingKeys.indexOf(a.key) - sortingKeys.indexOf(b.key);
      });
      onSave(newDndItems);
    }
    onClose();
  }

  return (
    <DndModal
      title={title}
      size='lg'
      onClose={onClose}
      onSave={handleOnSave}
      allowSave={!!dndDroppedItems?.length}
    >
      <div className="card">
        <div className="card-body">
          <DashboardDnd dndItems={dndItems} id={id} onChange={handleOnChange} direction={dndDirection as any}/>
        </div>
      </div>
    </DndModal>
  );
}

export default WidgetPositionSetting;