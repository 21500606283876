import {EAssignmentGroupType} from "@dashboard/report/types/enum";

export const assignmentGroupTypeOptions = [
  {
    value: EAssignmentGroupType.ASSIGNMENT_GROUP_TYPE_ALL,
    label: 'All Groups',
  },
  {
    value: EAssignmentGroupType.ASSIGNMENT_GROUP_TYPE_SPECIFIC,
    label: 'Specific Groups',
  },
];