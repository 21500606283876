import * as keyNames from '@dashboard/report/configs/key-names';
import {
  KEY_NAME_CREATED_AT,
  KEY_NAME_UPDATED_AT,
  KEY_NAME_CREATED_BY
} from "@base/config/key-names";
import {
  DataSourceSelect,
  UserAutoComplete
} from "@base/containers";

export const groupOptions = [
  { label: 'All Reports', value: 'ALL' },
  { label: 'My Reports', value: 'MY' },
];

export const dateOptions = [
  { label: 'Created', value: KEY_NAME_CREATED_AT },
  { label: 'Updated', value: KEY_NAME_UPDATED_AT },
];

export const filterOptions = [
  {
    label: 'Owner',
    value: KEY_NAME_CREATED_BY,
    component: UserAutoComplete,
    getValue: (value: any) => {
      return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : '';
    },
    setValue: (value: string) => {
      return value ? value.split(',') : [];
    },
  },
];

export const searchFields = [
  {
    name: keyNames.REPORT_NAME,
    type: 'custom',
    label: 'Name',
    component: DataSourceSelect,
    componentProps: {
      single: true,
      sourceKey: 'customer_category',
      sourceType: 'field',
    },
    getParam: (output: any) => {
      return output?.value;
    },
  }
];
