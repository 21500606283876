import React, { useCallback, useEffect, useRef, useState } from 'react';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import FullCalendar, { EventClickArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import dayjs from 'dayjs';
import ListReactTable from '@base/components/list/list-react-table';
import { TableNothing } from '@base/components/list/list-loading';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';
import { useGetTodoCounting, useGetTodosList } from '@dashboard/dashboard/services/activity';
import { ListPagination } from '@base/components/list';
import { PAGING_ITEMS_PER_PAGE } from '@customer/customer/config/constants';
import Loading from '@base/components/loading';
import styled from '@emotion/styled';
import { icons } from '@base/assets/icons/svg-icons';
import { Cpu } from 'react-feather';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { queryStrAssignToMe, makeDateQueryStr } from '@dashboard/dashboard/utils/filter-query';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import BlockBody from "@dashboard/dashboard/components/block-body";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const ACTION_EVENT_CLICK = 'ACTION_EVENT_CLICK';
const ACTION_EVENT_ON_RENDERED_DATE_CHANGE = 'ACTION_EVENT_ON_RENDERED_DATE_CHANGE';

const nowDayJs = dayjs().utc();
const todayDayJs = nowDayJs.startOf('day');
const thisMonthDayJs = todayDayJs.startOf('month');

// ---

interface ActivityCalendarHeaderProps {
  calRef: any;
  onChange?: (v: any, k: string) => void;
}

const ActivityCalendarHeader: React.FC<ActivityCalendarHeaderProps> = (
  props: ActivityCalendarHeaderProps,
) => {
  const { calRef, onChange } = props;

  const [renderedDate, setRenderedDate] = useState<any>(thisMonthDayJs.toDate());

  const previousView = useCallback(() => {
    const calendar = calRef.current as any;
    const api = calendar.getApi();
    api.prev();
    setRenderedDate(api.getDate());
  }, [calRef]);

  const nextView = useCallback(() => {
    const calendar = calRef.current as any;
    const api = calendar.getApi();
    api.next();
    setRenderedDate(api.getDate());
  }, [calRef]);

  const goToday = useCallback(() => {
    const calendar = calRef.current as any;
    calendar.getApi().today();
    setRenderedDate(thisMonthDayJs.toDate());
  }, [calRef]);

  const renderedDateDayJs = dayjs(renderedDate);

  const canPrevious = renderedDateDayJs.unix() > thisMonthDayJs.unix();

  const monthTitle = dayjs(renderedDate).format('YYYY-MM');

  useEffect(() => {
    onChange && onChange(renderedDate, ACTION_EVENT_ON_RENDERED_DATE_CHANGE);
  }, [renderedDate]);

  return (
    <div className="d-flex align-items-center pd-y-10">
      <h2 className="mg-b-0 mg-r-10 tx-20">{monthTitle}</h2>
      <button
        className="btn btn-sm btn-white btn-icon mg-l-5"
        data-han-tooltip="previous"
        onClick={() => previousView()}
        disabled={!canPrevious}
      >
        {icons['calendar_arrow_previous']}
      </button>
      <button
        className="btn btn-sm btn-white btn-icon mg-l-5"
        data-han-tooltip="next"
        onClick={() => nextView()}
      >
        {icons['calendar_arrow_next']}
      </button>
      <button className="btn btn-sm btn-white mg-l-5" onClick={() => goToday()}>
        Today
      </button>
    </div>
  );
};

// ---

const ActivityCalendarBody = styled.div`
  td.fc-day-sun .fc-daygrid-day-number,
  td.fc-day-sat .fc-daygrid-day-number,
  th.fc-day-sun .fc-col-header-cell-cushion,
  th.fc-day-sat .fc-col-header-cell-cushion {
    color: red;
  }

  td.fc-day-past .fc-daygrid-day-top {
    opacity: 0.3;
  }

  .fc-event.fc-event-custom {
    width: 80%;
    height: 30px;
    font-weight: bold;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    margin: 0 auto !important;
    background-color: #d97361;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .fc-event.fc-event-custom .fc-event-main {
    width: 100%;
  }
`;

interface ActivityCalendarProps {
  me?: boolean;
  onChange?: (action: string, data: any) => void;
}

const ActivityCalendar: React.FC<ActivityCalendarProps> = (props: ActivityCalendarProps) => {
  const { onChange, me = false } = props;

  const calRef = useRef(null);

  const defaultQueries = me ? [queryStrAssignToMe] : [];

  const [filters, setFilters] = useState<any>({
    filter: {
      query: [
        ...defaultQueries,
        makeDateQueryStr('date', thisMonthDayJs, thisMonthDayJs.endOf('month')),
      ].join(' '),
    },
  });

  const setQuery = (query: string) => {
    let newQueries = [...defaultQueries, query];
    setFilters({
      ...filters,
      filter: {
        ...filters.filter,
        query: newQueries.join(' '),
      },
    });
  };

  const { data, isLoading, isFetching } = useGetTodoCounting(filters);

  const { results = [] } = data ?? {};

  const handleEventClick = (args: EventClickArg) => {
    onChange && onChange(ACTION_EVENT_CLICK, args);
  };

  const handleHeaderOnChange = (v: any, k: string) => {
    onChange && onChange(k, v);

    const renderedDate = dayjs(v).utc();
    const start = renderedDate.startOf('month');
    const end = renderedDate.endOf('month');
    setQuery(makeDateQueryStr('date', start, end));
  };

  return (
    <>
      <ActivityCalendarHeader calRef={calRef} onChange={handleHeaderOnChange} />
      <ActivityCalendarBody>
        <FullCalendar
          ref={calRef}
          eventClick={handleEventClick}
          plugins={[dayGridPlugin]}
          timeZone={'UTC'}
          initialView="dayGridMonth"
          themeSystem={'bootstrap'}
          headerToolbar={false}
          eventContent={(args) => {
            return (
              <div
                className="fc-event-main-frame"
                data-han-tooltip={args?.event?.title + ' Todo(s)'}
              >
                <div className="fc-event-title-container">
                  <div className="fc-event-title fc-sticky">{args?.event?.title}</div>
                  <Cpu size={15} />
                </div>
              </div>
            );
          }}
          events={
            !!results.length
              ? results.reduce((final: any[], v: any) => {
                  if (v?.counting?.total) {
                    final.push({
                      title: v?.counting?.total ?? 0,
                      date: v?.value ?? '-',
                      classNames: ['fc-event-custom'],
                    });
                  }
                  return final;
                }, [])
              : []
          }
        />
      </ActivityCalendarBody>
    </>
  );
};

// ---

interface ActivityListProps {
  me?: boolean;
  args?: {
    action: string;
    data: any;
  };
}

const ActivityList: React.FC<ActivityListProps> = (props: ActivityListProps) => {
  const { args, me = false } = props;

  const defaultQueries = me
    ? [queryStrAssignToMe, 'classification=CLASSIFICATION_TODO']
    : ['classification=CLASSIFICATION_TODO'];

  const [filters, setFilters] = useState<any>({
    filter: {
      query: [
        ...defaultQueries,
        makeDateQueryStr('date', thisMonthDayJs, thisMonthDayJs.endOf('month')),
      ].join(' '),
      paging: {
        page: 1,
        size: 10,
      },
    },
  });

  const setQuery = (query: string) => {
    let newQueries = [...defaultQueries, query];
    setFilters({
      ...filters,
      filter: {
        ...filters.filter,
        query: newQueries.join(' '),
      },
    });
  };

  const setPaging = (paging: any) => {
    setFilters({
      ...filters,
      filter: {
        ...filters.filter,
        paging: {
          ...filters.filter.paging,
          ...paging,
        },
      },
    });
  };

  useEffect(() => {
    if (!!args && !!args?.data) {
      const { start, end } = args.data;

      let newQueries: string[] = [];

      if (start) {
        const startDayJs = dayjs(start);
        newQueries = [...newQueries, 'date>="' + startDayJs.toISOString() + '"'];
      }

      if (end) {
        const endDayJs = dayjs(end);
        newQueries = [...newQueries, 'date<="' + endDayJs.toISOString() + '"'];
      }

      setQuery(newQueries.join(' '));
    }
  }, [args]);

  const { data: todoData, isLoading, isFetching } = useGetTodosList(filters);

  const { results: todos = [], paging = {} } = todoData ?? {};

  const getMapColumns = () => {
    return {
      type(col: string, data: any) {
        return data[col] || '-';
      },
      priority(col: string, data: any) {
        return data[col] || '-';
      },
      subject(col: string, data: any) {
        return data[col] || '-';
      },
      customers(col: string, data: any) {
        return data[col] || '-';
      },
      status(col: string, data: any) {
        return data[col] || '-';
      },
      startTime(col: string, data: any) {
        return data?.[col]
          ? convertDateTimeServerToClient({
              date: data[col],
              humanize: false,
            })
          : '-';
      },
      endTime(col: string, data: any) {
        return data?.[col]
          ? convertDateTimeServerToClient({
              date: data[col],
              humanize: false,
            })
          : '-';
      },
    };
  };

  const fields = [
    {
      keyName: 'type',
      languageKey: 'Type',
      canSort: true,
    },
    {
      keyName: 'priority',
      languageKey: 'Priority',
    },
    {
      keyName: 'subject',
      languageKey: 'Subject',
    },
    {
      keyName: 'customer',
      languageKey: 'Customer',
    },
    {
      keyName: 'status',
      languageKey: 'Status',
    },
    {
      keyName: 'startTime',
      languageKey: 'Start Date',
    },
    {
      keyName: 'endTime',
      languageKey: 'End Date',
    },
  ];

  const tableProps = {
    nodata: <TableNothing />,
    data: todos ?? [],
    fetching: <Loading />,
    isFetching: isLoading || isFetching,
    columns: makeTableColumns(fields, getMapColumns(), {}, []),
    initialState: {
      pageSize: 0,
      pageIndex: 1,
      selectedIds: [],
    },
    isCheckboxTable: false,
    className: 'rounded-0 bd-l-0 bd-r-0',
  };

  const pagingProps = {
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: paging?.itemPerPage || PAGING_ITEMS_PER_PAGE,
    nextPage: paging?.nextPage || null,
    previousPage: paging?.previousPage || null,
    onChange: (page: number, size: number) => {
      setPaging({ page, size });
    },
  };

  return (
    <div className="list-wrap bd">
      <div className="list-body pd-0-f">
        <ListReactTable {...tableProps} />
      </div>
      <div className="pd-x-10">
        <ListPagination isSplitMode={false} type={'full'} {...pagingProps} />
      </div>
    </div>
  );
};

// ---

const VerticalDivider = styled.div`
  border-style: double;
`;

const ActivityTodo = (props: ChartComponentProps) => {
  const { me = false } = props;

  const [calendarArgs, setCalendarArgs] = useState<any>(null);

  const handleOnCalendarChange = (action: string, data: any) => {
    let start: any, end: any;

    if (action === ACTION_EVENT_CLICK) {
      const { event } = data;
      if (event?.start) {
        const startDayJs = dayjs(event.start).utc().startOf('day');
        start = startDayJs;
        if (event?.allDay) {
          end = startDayJs.endOf('day');
        }
      }
      if (event?.end) {
        end = dayjs(event.end).utc().endOf('day');
      }
    } else {
      const renderedDate = dayjs(data).utc();
      start = renderedDate.startOf('month');
      end = renderedDate.endOf('month');
    }

    setCalendarArgs({
      data: { start: start?.toDate() ?? null, end: end?.toDate() ?? null },
    });
  };

  return (
    <BlockBody className="ht-100p d-flex justify-content-center">
      <div className="wd-50p">
        <div className="calendar-wrap pd-10">
          <ActivityCalendar onChange={handleOnCalendarChange} me />
        </div>
      </div>
      <VerticalDivider className="bd-l bd-gray-300 bd-dashed" />
      <div className="wd-50p">
        <div className="todo-wrap pd-10 ht-100p">
          <ActivityList args={calendarArgs} me />
        </div>
      </div>
    </BlockBody>
  );
};

export default withChartContainer(ActivityTodo);
