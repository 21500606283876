import React  from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  Trash2,
} from 'react-feather';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import ListQuickAction from '@base/components/list/list-quick-action';
import {ANALYTIC_DELETEREPORT} from "@dashboard/report/services/graphqls";

const PageQuickAction: React.FC<any> = (props) => {
  const { selectedIds, setSelectedIds, onReload } = props;
  const { t } = useTranslation();

  const mDelete: any = useMutationPost<BaseMutationResponse>(
    ANALYTIC_DELETEREPORT,
    'analytic_deleteReport',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        onReload && onReload();
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Deleted failed: ' + JSON.parse(error).message);
      },
    },
  );

  const quickButtons = [
    {
      title: t('crm_new_common_delete'),
      icon: <Trash2 />,
      callback: () => {
        mDelete.mutate({ ids: selectedIds });
        setSelectedIds([]);
      },
    },
  ];

  return (
    <>
      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons}
        resetSelected={() => setSelectedIds([])}
        visible={selectedIds.length > 0}
      />
    </>
  );
};

export default PageQuickAction;
