import {DateRangeFilter2} from "@dashboard/dashboard/components/daterange-filter";
import {UserAutoComplete} from "@base/containers";
import {isArray} from "lodash";

export const filterDate: string = 'FILTER_DATE';
export const filterUser: string = 'FILTER_USER';
export const filterGroup: string = 'FILTER_GROUP';

export const filterDateUser: string[] = [filterDate, filterUser];
export const filterDateGroup: string[] = [filterDate, filterGroup];

export const filterComponents = {
  [filterDate]: {
    keyName: 'createdAt',
    component: DateRangeFilter2,
    componentProps: {
      noWrap: true
    },
    getValue: (value: any, otps: any) => {
      const {s, e} = value;
      const {keyName} = otps;
      return `${keyName}>="${s}" ${keyName}<="${e}"`;
    }
  },
  [filterUser]: {
    keyName: 'assignToUser',
    component: UserAutoComplete,
    componentProps: {
      className: "w-50 mg-l-5"
    },
    getValue: (value: any, otps: any) => {
      const {keyName} = otps;
      if(!isArray(value)){
        value = [value]
      }
      return `${keyName}=${value.map((v: any) => v.id).join(',')}`
    }
  },
  [filterGroup]: {
    keyName: 'assignToGroup',
    component: UserAutoComplete,
    componentProps: {
      className: "w-50 mg-l-5",
      placeholder: "Please select a group ..."
    },
    getValue: (value: any, otps: any) => {
      const {keyName} = otps;
      if(!isArray(value)){
        value = [value]
      }
      return `${keyName}=${value.map((v: any) => v.id).join(',')}`
    }
  }
}