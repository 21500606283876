import {useGetKbRepCounting} from '@dashboard/dashboard/services/desk';
import BarSimple from '@dashboard/dashboard/components/chart/bar-simple';
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";

const KBRepInsertedArticle = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetKbRepCounting(props?.filters ?? {});
  });

  const { results } = data ?? {};

  let chartData: any = { l: [], s: [] };
  if (!!results && !!results?.length) {
    chartData = results?.reduce((f: any, v: any) => {
      f['l'].push(v?.user?.name ?? '--No Name--');
      f['s'].push(v?.counting?.inserted ?? 0);
      return f;
    }, chartData);
  }

  return <BarSimple series={chartData.s} categories={chartData.l} />;
};

export default withChartContainer(KBRepInsertedArticle);
