import React from "react";
import {keys} from "lodash";
import dayjs from "dayjs";
import MixedMultipleY from "@dashboard/dashboard/components/chart/mixed-multiple-y";
import {useGetCustomerPeriodCounting} from "@dashboard/dashboard/services/customer";
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

const CustomerPeriod = (props: ChartComponentProps) => {
  const {
    filters = {},
    ...restProps
  } = props;

  console.log('CustomerPeriod', filters);

  const {data, isLoading} = useChartQuery(restProps, () => {
    return useGetCustomerPeriodCounting(filters);
  });

  const {results = []} = data ?? {};

  let oDatesByKey: any = {};
  let oData: any = {};

  if (!isLoading && !!results && !!results?.length) {
    for (const i in results) {
      const row = results[i];

      if (!!row?.date?.key) {
        const dateKey = row.date.key
        oDatesByKey[dateKey] = row.date.name;
        if (!!row?.counting) {
          oData[dateKey] = row.counting
        }
      }
    }
  }

  const aDateKeys = keys(oDatesByKey);

  const aDateNames = aDateKeys?.map((k) => {
    return oDatesByKey[k]
  })

  const aAccounts = aDateKeys.map((v) => {
    return oData[v]?.account ?? 0;
  })

  const aContacts = aDateKeys.map((v) => {
    return oData[v]?.contact ?? 0;
  })

  const aTotals = aDateKeys.map((v) => {
    return oData[v]?.total ?? 0;
  })

  const aSeries: any[] = [{
    name: 'Account',
    type: 'column',
    data: aAccounts
  }, {
    name: 'Contact',
    type: 'column',
    data: aContacts
  }, {
    name: 'Total',
    type: 'line',
    data: aTotals
  }];

  return <MixedMultipleY categories={aDateNames} series={aSeries}/>;
};

export default withChartContainer(CustomerPeriod);