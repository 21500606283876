import React, {useEffect, useState} from "react";
import {Button} from "@base/components/form";
import {generateUUID} from "@base/utils/helpers";
import ReportContentPage from "./report-content-page";
import ReportContentSetting from "./report-content-setting";
import {sectionOptions} from "@dashboard/main/configs/options";
import {keys} from "lodash";
import {EDisplayGridType, EDisplayModeType} from "@dashboard/report/types/enum";
import TextEditable from "@base/components/form/text-editable";
import styled from "@emotion/styled";
import ReportContentHeader from "@dashboard/report/containers/reporting-content/report-content-header";
import {analyticGeneralValue} from "@dashboard/main/recoil/general-query";
import {getChartConfigsBy} from "@dashboard/main/utils";

const TextEditableWrapper = styled.div`
  width: 100%;
  padding: 10px;

  .component-edit {
    > div:first-child {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
    }

    .crm-han-group-button {
      align-items: center;
    }
  }
`;

const DEFAULT_PAGES = [{
  name: 'Page 1',
  id: generateUUID()
}];

const DEFAULT_SETTING = {
  displayMode: EDisplayModeType.DISPLAY_MODE_PORTRAIT,
  displayGrid: EDisplayGridType.DISPLAY_GRID_SHOW,
  selectedSections: [],
  selectedCharts: []
};

interface ReportingContentProps {
  value?: any[];
  onChange?: (v: any[]) => void;

  [x: string]: any;
}

const ReportingContent: React.FC<ReportingContentProps> = (props: ReportingContentProps) => {
  const {onChange, value: iPages, ...remainProps} = props;

  const hasIPages = !!iPages && !!iPages?.length;

  const {userRole} = analyticGeneralValue();
  const chartConfigs = getChartConfigsBy('all', userRole);

  const defaultPages = hasIPages ? iPages?.map((p) => ({id: p.id, name: p.name})) : DEFAULT_PAGES;
  const defaultPageSettings = hasIPages ? iPages?.reduce((f, p) => {
    const {sections: iSections, displayMode, displayGrid} = p;
    f = {...f, [p.id]: {
        displayMode,
        displayGrid,
        selectedSections: iSections.map((v: any) => ({label: sectionOptions[v.section], value: v.section})),
        selectedCharts: iSections.reduce((f: string[], s: any) => {
          const {charts, section} = s;
          const chartRefer = chartConfigs?.[section] ?? {};
          f = [...f, ...(charts.map((k: string) => ({label: chartRefer[k]?.componentProps?.title ?? '-No title-', value: k})))];
          return f;
        }, [])
      }
    }
    return f;
  }, {}) : {
    [DEFAULT_PAGES[0]['id']]: DEFAULT_SETTING
  };

  const [pages, setPages] = useState(defaultPages);
  const [pageActive, setPageActive] = useState(defaultPages[0]);
  const [pageSettings, setPageSettings] = useState<any>(defaultPageSettings);
  const [pageNameEditing, setPageNameEditing] = useState<{ i: number, name: string } | null>(null);

  const handPageSetting = (id: string, isRemove: boolean = false) => {
    const newPageSettings: any = {...pageSettings}
    if (isRemove) {
      if (!!newPageSettings?.[id]) {
        delete newPageSettings[id];
      }
    } else {
      newPageSettings[id] = DEFAULT_SETTING;
    }
    setPageSettings(newPageSettings)
  };

  const handleAddPage = () => {
    const newPageId = generateUUID();
    const newPage = {
      name: 'Page ' + (pages.length + 1),
      id: newPageId,
      isNewPage: true
    }
    setPages([...pages, newPage]);
    setPageActive(newPage)
    handPageSetting(newPageId);
  };

  const handleRemovePage = (e: any, i: number) => {
    e.stopPropagation();
    const newPages = [...pages];
    const pageId = newPages[i].id;
    if (pageId === pageActive.id) {
      setPageActive({...(newPages[i - 1])});
    }
    newPages.splice(i, 1);
    setPages(newPages);
    handPageSetting(pageId, true);
  };

  const handleSavePageName = (name: string) => {
    const newPages = pages.map((p, i) => {
      if (i === pageNameEditing?.i) {
        p.name = name;
      }
      return p;
    });
    setPages(newPages);
    setPageNameEditing(null);
  }

  useEffect(() => {
    const newPages = pages.map((p, i) => {
      const {
        displayGrid,
        displayMode,
        selectedSections = [],
        selectedCharts = []
      } = pageSettings?.[p.id] ?? {};
      const sections = selectedSections?.map((v: any) => {
        const k = v.value;
        const chartKeysBySection = keys(chartConfigs[k]);
        return {
          section: k,
          charts: selectedCharts.filter((v: any) => chartKeysBySection.includes(v.value)).map((v: any) => v.value)
        };
      }) ?? [];
      return {
        id: p.id,
        name: p.name,
        displayMode,
        displayGrid,
        sections
      };
    });
    onChange && onChange(newPages);
  }, [pages, pageSettings]);

  return (
    <div className="row row-xs">
      <div className="col-lg-8">
        <div className="card ht-100p">
          <div className="card-header pd-0">
            {
              pageNameEditing !== null && (
                <TextEditableWrapper>
                  <TextEditable isSaving={false} onClose={() => setPageNameEditing(null)} onSave={handleSavePageName}
                                setIsSaving={() => {
                                }} value={pageNameEditing?.name ?? ''}/>
                </TextEditableWrapper>
              )
            }
            {
              pageNameEditing === null && (
                <>
                  <ReportContentHeader
                    handleRemovePage={handleRemovePage}
                    pageActive={pageActive}
                    pages={pages}
                    setPageActive={setPageActive}
                    setPageNameEditing={setPageNameEditing}
                    navLimit={remainProps?.navLimit ?? 2}
                  />
                  <Button
                    color="primary"
                    size="sm"
                    icon="Plus"
                    name="Add"
                    className="mg-l-auto mg-r-10"
                    onClick={handleAddPage}
                  />
                </>
              )
            }
          </div>
          <div className="card-body">
            {
              pages.map((p, i) => {
                if (pageActive.id !== p.id) {
                  return null;
                }
                return (
                  <ReportContentPage key={i} data={pageSettings[p.id]}/>
                );
              })
            }
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <ReportContentSetting
          data={pageSettings[pageActive.id] as any}
          onChange={(v: any) => setPageSettings({...pageSettings, [pageActive.id]: v})}
        />
      </div>
    </div>
  );
};

export default ReportingContent;