import {useGetTicketPerformance} from '@dashboard/dashboard/services/desk';
import BarHorizontal from '@dashboard/dashboard/components/chart/bar-horizontal';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const TicketTopPerformerByResolvedTicket = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetTicketPerformance(props?.filters ?? {});
  });

  const { results = [] } = data ?? {};

  const chartData = results?.reduce(
    (f: any, v: any) => {
      f['l'].push(v?.group?.name ?? '-');
      f['s'].push(v?.counting?.resolved ?? 0);
      return f;
    },
    { l: [], s: [] },
  ) ?? { l: [], s: [] };

  return <BarHorizontal categories={chartData?.l ?? []} series={chartData?.s ?? []} />;
};

export default withChartContainer(TicketTopPerformerByResolvedTicket);
