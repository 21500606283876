import React from 'react';
import Classification from '@desk/ticket/containers/classification';
import { extractUrlParams } from '@site-page/utils/Params';
interface IEditProps {
  value: any;
  onChange: (params: any) => {};
}
const Edit: React.FC<IEditProps> = (props: IEditProps) => {
  const { value, onChange } = props;
  const siteParams = extractUrlParams();

  return <Classification isPublic={true} token={siteParams?.tk || ''} value={value} onChange={onChange} />;
};

export default Edit;
