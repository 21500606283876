import React, { useEffect } from 'react';
import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import { useForm } from 'react-hook-form';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import Field from '@base/components/hook-form/field';
import Button from '@base/components/form/button';
import { ChevronUp } from 'react-feather';
import { finalizeParams } from '@dashboard/report/containers/write-form/payload';
import { ANALYTIC_CREATEREPORT } from '@dashboard/report/services/graphqls';

interface WriteFormProps {
  fullScreen: boolean;
  menuApi: string;
  onClose: () => void;
  onReload?: () => void;
  defaultValues: any;
  fields: any[];
  tabs: any[];
  getParams: any;
  onGoView?: (id: string) => void;
}

const WriteForm: React.FC<WriteFormProps> = (props) => {
  const { fullScreen, onClose, onReload, defaultValues, fields, getParams, onGoView } = props;

  const {
    handleSubmit,
    watch,
    reset,
    resetField,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  const mCreate: any = useMutationPost<BaseMutationResponse>(
    ANALYTIC_CREATEREPORT,
    'analytic_createReport',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Created successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Created failed: ' + JSON.parse(error).message);
      },
    },
  );

  useEffect(() => {
    if (mCreate.isSuccess) {
      onGoView && onGoView(mCreate.data.id);
      onReload && onReload();
      onClose && onClose();
    }
  }, [mCreate.isSuccess]);

  const onSubmit = ({ formData }: any) => {
    const configParams = getParams(formData);
    const newParams = finalizeParams(configParams);
    mCreate.mutate({ report: newParams });
  };

  const onError = (errors: any, e: any) => {
    // console.log('error', errors, e);
  };

  const renderFields = () => {
    let formHeight = 'calc(100vh - 250px)';
    if (fullScreen) {
      formHeight = 'calc(100vh - 120px)';
    }

    return (
      <div
        className="pd-15 form-row scroll-box"
        style={{ maxHeight: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        {fields.map((f, i) => {
          const newComponentProps = { ...f?.componentProps };
          return (
            <Field
              key={i}
              item={{
                ...f,
                componentProps: newComponentProps,
              }}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                handleSubmit((data) => onSubmit({ formData: data }), onError)();
              }}
              disabled={mCreate.isLoading || !isValid}
              loading={mCreate.isLoading}
              name="Save"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  handleSubmit((data) => onSubmit({ formData: data }), onError)();
                }}
                disabled={mCreate.isLoading || !isValid}
                loading={mCreate.isLoading}
                name="Save and Create"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values watch', watch()); //get form values when inputing
  //// console.log('form values', getValues()); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  return (
    <div className="pd-5">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>is required field</span>
          </span>
        </div>
      </div>
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        {renderFields()}
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(withWriteForm(WriteForm));
