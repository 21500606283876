import React from "react";
import {DndSourceItem} from "@dashboard/dashboard/types/interfaces/dnd";
import {ChartWrapper} from "@dashboard/main/components/chart-wrapper";
import Section from "@dashboard/dashboard/components/section/section";
import classNames from "classnames";

interface ISectionBodyProps {
  sourceItems: DndSourceItem[];
}

export const SectionBody: React.FC<ISectionBodyProps> = (props: ISectionBodyProps) => {
  const {sourceItems} = props;
  return (
    <div className="row">
      {
        sourceItems.map((sourceItem, i) => {
          const {
            component: Component,
            componentProps: {filterProps = {}, ...restComponentProps},
            wrapperProps,
            unused = false
          } = sourceItem;

          if(unused){
            return null;
          }

          const componentProps = {
            ...restComponentProps,
            filterProps: {
              ...{
                isShow: true
              },
              ...filterProps,
            }
          };

          if (wrapperProps) {
            const {className = '', ...restWrapperProps} = wrapperProps;

            return (
              <ChartWrapper className={classNames('mg-y-15', className)} {...restWrapperProps} key={i}>
                <Component {...componentProps} />
              </ChartWrapper>
            );
          }
          return <Component {...componentProps} key={i} />;
        })
      }
    </div>
  );
}

export default Section;