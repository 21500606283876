import React, {useMemo, useState} from 'react';
import ChartContainer, {ToolbarProps} from "@dashboard/dashboard/components/chart-container";
import Loading from "@base/components/loading";
import {queryStrAssignToMe} from "@dashboard/dashboard/utils/filter-query";
import {thisMonth} from "@dashboard/main/utils/date";
import {cloneDeep} from "lodash";

export interface ChartComponentProps {
  me?: boolean;
  filters?: any;
  setLoading?: (is: boolean) => void;
  setToolbarProps?: (props: ToolbarProps) => void;
}

export function withChartContainer(chartComponent: any, opts?: any) {
  return (props: any) => {
    const {
      me = false,
      filterProps: iFilterProps = {},
      ...restProps
    } = props;

    const {
      defaultQuery = '',
      dateKey = 'createdAt',
      ...restIFilterProps
    } = iFilterProps ?? {};

    let rootQueries: string[] = [];
    if(!!defaultQuery){
      rootQueries = [...rootQueries, defaultQuery]
    }
    else {
      if(me){
        rootQueries = [...rootQueries, queryStrAssignToMe]
      }
    }

    const time = thisMonth();
    const timeQuery = `${dateKey}>="${time.startDate.toISOString()}" ${dateKey}<="${time.endDate.toISOString()}"`;
    const [filters, setFilters] = useState<any>({
      filter: {
        query: rootQueries.join(' ') + ' ' + timeQuery
      }
    });

    const setQuery = (query: string) => {
      let queries: string[] = [...rootQueries, query]
      let newFilter = {
        ...filters,
        filter: {
          ...filters.filter,
          query: queries.join(' ')
        },
      };
      setFilters(newFilter);
    };

    const [loading, setLoading] = useState(false);

    const filterProps = {...restIFilterProps, onChange: setQuery};
    const [toolbarProps, setToolbarProps] = useState<ToolbarProps|null>(null);

    const ChartComponent = useMemo(() => chartComponent, [filters])

    const filtersClone: any = cloneDeep(filters);
    const chartComponentProps: ChartComponentProps = {
      filters: filtersClone,
      setLoading,
      setToolbarProps: (props: any) => {
        setToolbarProps(props);
      }
    };

    return (
      <ChartContainer {...{...restProps, filterProps, toolbarProps}}>
        <ChartComponent {...chartComponentProps}/>
        {loading && <div
            style={{
              position: 'absolute',
              top: 0,
              backgroundColor: '#0000000d',
              width: '100%',
              height: 'calc(100% - 0.75rem)',
              borderRadius: '0.25rem',
            }}
        >
            <Loading />
        </div>}
      </ChartContainer>
    );
  };
}

export default withChartContainer;
