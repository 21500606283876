import {EChartType} from "@dashboard/main/types/enum";
import {chartComponents} from "@dashboard/dashboard/config/chart";

export const userConfigs = {
  [EChartType.CHART_CUSTOMER_TREND]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_TREND],
    key: EChartType.CHART_CUSTOMER_TREND,
    componentProps: {
      title: "My Customer Trends",
      me: true,
      filterProps: {
        isDateNoPeriod: true
      }
    },
    wrapperProps: {
      gridCol: 2,
      className: "col-md-6 col-xl-2 col-lg-3 order-md-1 order-xl-0 ht-500"
    }
  },
  [EChartType.CHART_CUSTOMER_PERIOD]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_PERIOD],
    key: EChartType.CHART_CUSTOMER_PERIOD,
    componentProps: {
      title: "Customer By Period",
      me: true
    },
    wrapperProps: {
      gridCol: 8,
      className: "col-md-6 col-xl-8 col-lg-6 order-md-1 order-xl-0 ht-500",
    }
  },
  [EChartType.CHART_CUSTOMER_LAST_UPDATED]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_LAST_UPDATED],
    key: EChartType.CHART_CUSTOMER_LAST_UPDATED,
    componentProps: {
      title: "Customer Last Updated",
      me: true,
      filterProps: {
        isShow: false
      },
      bodyContentProps: {
        isScroll: true
      }
    },
    wrapperProps: {
      gridCol: 2,
      className: "col-md-6 col-xl-2 col-lg-3 order-md-1 order-xl-0 ht-500",
    }
  }
};

export const adminConfigs = {
  [EChartType.CHART_CUSTOMER_TREND]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_TREND],
    key: EChartType.CHART_CUSTOMER_TREND,
    componentProps: {
      title: "Customer Trend",
      filterProps: {
        isDateNoPeriod: true
      }
    },
    wrapperProps: {
      className: "col-md-6 col-xl-2 order-md-1 order-xl-0"
    }
  },
  [EChartType.CHART_CUSTOMER_RATING]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_RATING],
    key: EChartType.CHART_CUSTOMER_RATING,
    componentProps: {
      title: "Customer Rating",
      filterProps: {
        isDateGroup: true
      }
    },
    wrapperProps: {
      className: "col-md-6 col-xl-6 order-md-1 order-xl-0",
    }
  },
  [EChartType.CHART_CUSTOMER_INDUSTRY]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_INDUSTRY],
    key: EChartType.CHART_CUSTOMER_INDUSTRY,
    componentProps: {
      title: "Customer Industry",
      filterProps: {
        isShow: false
      },
    },
    wrapperProps: {
      className: "col-md-6 col-xl-4 order-md-1 order-xl-0",
    }
  }
};