import styled from "@emotion/styled";

export const ViewEditContainer = styled.div`
  .component-edit {
    min-height: 37px;
    padding: 5px;
    border-radius: 10px;
    border: 1px dashed var(--border-color-2);
  }
`;

export const ViewContainer = styled.div`
  padding: 5px;
  border: 1px dashed transparent;
  position: relative;
  
  .btn-edit {
    position: absolute;
    top: 15px;
    right: 15px;
    transition: all 0.3s;
  }
  
  &:hover {
    border: 1px dashed var(--border-color-2);
    border-radius: 5px;

    .btn-edit {
      z-index: 999;
    }
  }
`;