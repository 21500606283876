import { lazy } from 'react';

const FirstPage = lazy(() => import('./main'));

export default [
  {
    path: '/analytic/*',
    component: FirstPage,
  }
];
