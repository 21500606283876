import React from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { ListType } from '@base/types/enums';
import { reportListFilterState, reportWriteState } from '@dashboard/report/recoil/atom';
import {MENU_ANALYTIC} from '@base/config/menus';
import ListToolbarContainer from '@base/containers/list/toolbar';
import {allCategories, CATEGORY_REPORT} from "@dashboard/main/configs/general";

const PageToolbar: React.FC<any> = (props) => {
  const { isSplitMode, setIsSplitMode, category, refetch } = props;
  const [listFilter, setListFilter] = useRecoilState(reportListFilterState);
  const [, setWriteOption] = useRecoilState(reportWriteState);
  let { listType } = listFilter;
  const navigate = useNavigate();

  return (
    <>
      <ListToolbarContainer
        listTypeProps={{
          listTypeStr: listType,
          onChangeListType: (listType: ListType) => {
            let newListFilter = {
              ...listFilter,
              listType: listType,
            };
            setListFilter(newListFilter);
          },
          listTypes: [ListType.GRID, ListType.LIST, ListType.SPLIT],
        }}
        isSplitMode={isSplitMode}
        setIsSplitMode={setIsSplitMode}
        onRefresh={refetch}
        onAdd={(selectedCategory: string | undefined) =>
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: selectedCategory === undefined ? category : selectedCategory,
            writeMenu: 'list',
          }))
        }
        categoryOptions={allCategories}
        categorySelected={CATEGORY_REPORT}
        onCategoryChange={(category: string) => navigate(`/${MENU_ANALYTIC}/${category}`)}
        menu={MENU_ANALYTIC}
      />
    </>
  );
};

export default PageToolbar;
