import React  from 'react';
import classNames from 'classnames';
import HeaderMultiTheme from '@base/containers/header-multi-theme';
import ViewTabsBody from '@base/components/view/tabs-body/indext';
import Loading from '@base/components/loading';

export interface IDestopLayout1ViewProps {
  className?: string;
  isLoading?: boolean;
  headerProps: any;
  componentTop?: any;
  topProps?: any;
  componentCenter?: any;
  centerProps?: any;
  contentStyle?: any;
}

const DesktopLayout1View: React.FC<IDestopLayout1ViewProps> = (props: IDestopLayout1ViewProps) => {
  let {
    className = '',
    isLoading,
    headerProps,
    componentCenter,
    centerProps,
    componentTop,
    contentStyle,
  } = props;

  return (
    <div className={classNames("view-wrap", className)}>
      {isLoading && <Loading />}
      {headerProps && <HeaderMultiTheme {...headerProps} />}
      {componentTop}
      <div className="row no-gutters flex-nowrap flex-grow-1" style={contentStyle}>
        <div className="view-content">
          <div className="view-body">
            {componentCenter ? componentCenter : <ViewTabsBody {...centerProps} />}
          </div>
        </div>
      </div>
    </div>
  );
};

DesktopLayout1View.defaultProps = {
  isLoading: false,
  topProps: null,
  componentCenter: null,
  centerProps: {
    tab: {},
    tabs: [],
    onChangeTab: () => null,
  },
  componentTop: null,
  contentStyle: {}
};

export default DesktopLayout1View;
