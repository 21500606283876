import React from "react";
import TreeMap from "@dashboard/dashboard/components/chart/tree-map";
import {t} from "i18next";
import {useGetCustomerIndustryCounting} from "@dashboard/dashboard/services/customer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";

const CustomerIndustry = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetCustomerIndustryCounting(props?.filters);
  });

  const {results} = data ?? {};

  let aSeries: any = [];
  if (!!results && !!results?.length) {
    aSeries = [
      {
        data: results?.map((v: any) => {
          return {
            x: t(v?.industry?.languageKey) || '',
            y: v?.counting.total || 0
          }
        }) ?? []
      }
    ];
  }

  return (
    <div className="ht-100p pd-l-20 pd-b-20">
      <TreeMap series={aSeries}/>
    </div>
  );
}

export default withChartContainer(CustomerIndustry);