import React, {useState} from 'react';
import Loading from "@base/components/loading";
import {keys} from "lodash";
import {sectionOptions} from "@dashboard/main/configs/options";
import Section from "@dashboard/dashboard/components/section";
import classNames from "classnames";
import {EPermit, ESectionType} from "@dashboard/main/types/enum";

const AdminPage = () => {
  const userRole = EPermit.PERMIT_ADMIN;

  const [domLoaded, setDomLoaded] = useState<any>({});

  const handleDomLoaded = (k: string, is: boolean) => {
    setDomLoaded({...domLoaded, [k]: is})
  }

  return (
    <div className="pd-10 scroll-box" style={{height: "calc(100vh - 65px)"}}>
      {
        keys(sectionOptions).map((k: any, i: number) => {
          const title = sectionOptions[k];

          /*if([ESectionType.SECTION_CUSTOMER, ESectionType.SECTION_DESK].includes(k)){
            return null;
          }*/

          return (
            <Section
              key={i}
              title={title}
              keyName={k}
              userRole={userRole}
              className={classNames({'mg-t-20': i > 0})}
              onDOMLoaded={(loaded) => handleDomLoaded(k, loaded)}
            />
          );
        })
      }
      {domLoaded?.[ESectionType.SECTION_CUSTOMER] && <Loading top={100} />}
    </div>
  );
}

export default AdminPage;