import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import Button from '@base/components/form/button';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { TICKET_COMMENT_CREATE } from '@desk/ticket/services/graphql/ticket';
import TuiEditor from '@base/components/form/tui-editor';
import UploadFile from '@base/components/form/upload/file';
import * as keyNames from '@desk/ticket/config/key-names';
import { ChevronUp } from 'react-feather';
import {
  TICKET_COMMENT_DISPLAY_PRIVATE,
  TICKET_COMMENT_DISPLAY_PUBLIC,
  TICKET_COMMENT_KIND_NEW,
} from '@desk/ticket/config/contants';
import { useUploadMutation } from '@base/hooks/useFileUploadMutation';
import { TRACKING_SURVEY_GET, TRACKING_TICKET_COMMENT_CREATE } from '@site-page/services/graphql';
import SurveyView from '@settings/online-digital-content/survey/containers/survey-view';
import { useTrackingSurveys } from '@site-page/services/service';
import classNames from 'classnames';
import Loading from '@base/components/loading';
import usePost from '@base/hooks/usePost';

interface ISurveyFormProps {
  token: string;
  onClose?: () => void;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const SurveyForm = (props: ISurveyFormProps) => {
  const { token, onClose } = props;
  //const { t } = useTranslation();
  //state
  const [surveysList, setSurveysList] = useState<any[]>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>(null);
  const [surveyData, setSurveyData] = useState<any>(null);

  //get available surveys
  const { data: surveysPost, isFetching: isSurveysFetching } = useTrackingSurveys(token);
  //// console.log('surveysData', surveysData);
  const { data: surveyPost, isFetching: isSurveyFetching } = usePost(
    ['tracking_survey', selectedSurvey?.id, token],
    TRACKING_SURVEY_GET,
    { id: selectedSurvey?.id, token },
    { enabled: selectedSurvey?.id !== undefined },
  );
  //// console.log('surveyPost', surveyPost);

  //init surveys (tabs) list
  useEffect(() => {
    if (surveysPost?.data) {
      const newItems = surveysPost.data.filter((_ele: any) => _ele !== null);
      setSurveysList(newItems);
      if (newItems.length > 0) {
        setSelectedSurvey(newItems[0]);
      }
    }
  }, [surveysPost]);

  //set servey data when change
  useEffect(() => {
    if (surveyPost) {
      setSurveyData(surveyPost);
    }
  }, [surveyPost]);

  //form fields
  const SurveyContentRender = useMemo(() => {
    let curSections: any[] = [];
    try {
      if (surveyData?.sections) {
        curSections = JSON.parse(surveyData.sections);
      }
    } catch {
      // console.log('parse json error');
    }

    return (
      <div className="pd-15 scroll-box" style={{ height: 'calc(100vh - 185px)' }}>
        {isSurveyFetching && <Loading />}
        {surveyData && (
          <SurveyView
            id={surveyData.id}
            name={surveyData.name}
            previewData={{
              headerImg: surveyData.surveyHeaderImg,
              headerLineColor: surveyData.surveyHeadLineColor,
              bgColor: surveyData.surveyBgColor,
              //image: surveyData.surveyImage,
              sections: curSections,
            }}
            isPublic={true}
            token={token}
          />
        )}
      </div>
    );
  }, [surveyData, isSurveyFetching]);

  //render footer
  const FooterRender = useMemo(() => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button
            type="button"
            className="btn btn-light mg-r-5"
            onClick={() => onClose && onClose()}
          >
            Close
          </button>
          {/* <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                //setIsPublic(true);
                //handleSubmit((data) => onSubmit({ formData: data, isPublic: true }), onError)();
              }}
              disabled={mAdd.isLoading}
              loading={mAdd.isLoading}
              name="Submit"
            />
          </div> */}
        </div>
      </div>
    );
  }, [onClose]);

  //tabs for surveys list
  const TabsRender = useMemo(() => {
    return (
      <ul className="nav nav-tabs flex-grow-1">
        {surveysList.map((_item: any, index: number) => (
          <li key={index} className="nav-item">
            <button
              type="button"
              className={classNames('nav-link', { active: _item.id === selectedSurvey?.id })}
              onClick={(e) => {
                setSelectedSurvey(_item);
              }}
            >
              {_item.name}
            </button>
          </li>
        ))}
      </ul>
    );
  }, [surveysList, selectedSurvey]);

  //render
  return (
    <div className="pos-relative">
      <div className="pos-relative pd-15">
        {isSurveysFetching && <Loading />}
        {TabsRender}
        {SurveyContentRender}
      </div>
      {FooterRender}
    </div>
  );
};

export default withMiModal(SurveyForm);
