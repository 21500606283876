export const COLOR_DARK_SKY_BLUE = '#388BF1';
// export const COLOR_LAPIS_BLUE_273A7B = '#273A7B';
export const COLOR_CYAN_OPAQUE = '#93CABF';
export const COLOR_LAPIS_BLUE_263A7B = '#263A7B';
export const COLOR_GOLDENROD = '#F7D27A';
export const COLOR_TERRA_COTTA = '#D97361';
export const COLOR_DEFAULT = [COLOR_DARK_SKY_BLUE, COLOR_CYAN_OPAQUE, COLOR_LAPIS_BLUE_263A7B, COLOR_GOLDENROD, COLOR_TERRA_COTTA];

export const COLOR_DARK_PASTEL_PURPLE = '#8B75D7';
export const COLOR_STEEL_GREY = '#6D848E';
export const COLOR_BRINK_PINK = '#FF6178';
export const COLOR_PURPLE_PINK = '#D830EB';
export const COLOR_VERDIGRIS = '#46B3A9';
export const COLOR_EXTRA = [COLOR_DARK_PASTEL_PURPLE, COLOR_STEEL_GREY, COLOR_BRINK_PINK, COLOR_PURPLE_PINK, COLOR_VERDIGRIS];

export const ALL_COLORS = [...COLOR_DEFAULT, ...COLOR_EXTRA]