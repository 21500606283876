import React, { useMemo } from 'react';
//import { useTranslation } from 'react-i18next';
import { ListBody, ListPagination } from '@base/components/list';
import { TableNothing } from '@base/components/list/list-loading';
import { makeTable8Columns } from '@base/components/utils/helpers/react-table';
import { DESC, ASC } from '@base/config/constant';
import { ColumnDef } from '@tanstack/react-table';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
import { Link } from 'react-router-dom';
import { ListPageConfig } from '@desk/ticket/config/pages/list-page';

const Body: React.FC<any> = (props) => {
  const {
    category, //router category
    token = '',
    fields,
    itemsList,
    filter,
    setFilter,
    onIdChange
  } = props;
  //const { t } = useTranslation();
  //config disable sortBy columns
  const disableSortByColumns: any = [];

  //set filter sort param
  const setSort = (sort: any) => {
    let newFilter = {
      ...filter,
      sort: sort
    };
    setFilter(newFilter);
  };

  //table props
  const newFields = fields.map((_ele: any) => ({
    ..._ele,
    enableSorting: true,
    disableSortBy: disableSortByColumns.includes(_ele.keyName),
  }));

  //render column data
  const getColumnRender = (extraParams: any) => {
    const { category, token } = extraParams;
    return {
      ...ListPageConfig.getColumnRenderRemap({ category }),
      subject(col: string, row: ITicket) {
        return (
          <Link onClick={() => onIdChange && onIdChange(row.id)} to={`/site/view/?tk=${token}&utm_id=${row.id}`}>
            {row.subject}
          </Link>
        );
      }
    };
  };

  /** ================================== React Table 8 ================================== */
  //build columns for table v8
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      ...makeTable8Columns(
        newFields,
        getColumnRender({ token, category }),
        { category },
        [],
      ),
    ],
    [newFields],
  );

  const listTableProps = {
    nodata: <TableNothing />,
    data: itemsList,
    // loading: isFetching,
    columns: columns, //tableHideColumns
    initialState: {
      pageSize: filter.paging?.itemPerPage || 15,
    },
    // rowSelected: selectedIds,
    onSortBy: (clName: any, isSorted: any) => {
      // refetch();
      //let orderBy = isSortedDesc ? DESC : ASC;
      //setSort({ field: clName, orderBy: orderBy });
      if (isSorted !== false) {
        let orderBy = isSorted === 'desc' ? DESC : ASC;
        setSort({ field: clName, orderBy: orderBy });
      }
    },
    className: '',
  };

  //table paging
  const pagingProps = {
    totalPage: filter.paging?.totalPage || 1,
    totalItems: filter.paging?.totalItems || 0,
    currentPage: filter.paging?.page || 1,
    itemPerPage: filter.paging?.itemPerPage || 15,
    nextPage: filter.paging?.nextPage || null,
    previousPage: filter.paging?.previousPage || null,
    onChange: (page: number, size: number) => {
      let newFilter = {
        ...filter,
        paging: {
          ...filter.paging,
          page,
          size
        },
      };
      setFilter(newFilter);
    },
  };

  //list
  const ListBodyMemo = useMemo(() => {
    return (
      <ListBody className="pd-10" isScrollY={false} styles={{ height: "100%" }}>
        <ListReactTable8 {...listTableProps} />

        <ListPagination isSplitMode={false} type='simple' {...pagingProps} />
      </ListBody>
    );
  }, [itemsList, filter]);

  //main
  return <>{ListBodyMemo}</>;
};

export default Body;