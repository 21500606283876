import usePost from '@base/hooks/usePost';
import {
  ActivityResultsResponse,
  ActivityTrendCountingResponse,
  IGeneralCounting,
  Activity,
  ActivityPerformanceCounting,
  ActivityDateCountingResponse,
  ActivityPurposeCounting,
} from '@dashboard/dashboard/types/interfaces/activity';
import { FilterInput } from '@base/types/interfaces/common';
import { keyStringify } from '@base/utils/helpers/list-page-utils';
import { BaseResponse, ResponsePaging } from '@base/types/interfaces/response';
import {
  ANALYTIC_ACTIVITIES,
  ANALYTIC_ACTIVITYBASECOUNTING,
  ANALYTIC_ACTIVITYBYPRIORITY,
  ANALYTIC_ACTIVITYBYPURPOSE,
  ANALYTIC_ACTIVITYDATECOUNTING,
  ANALYTIC_ACTIVITYPERFORMANCE,
  ANALYTIC_ACTIVITYTODOCOUNTING,
  ANALYTIC_ACTIVITYTREND,
} from '@dashboard/dashboard/services/graphqls/activity';

export const useGetActivityTrend = (filter: FilterInput) => {
  const menu = `analytic_activityTrend`;

  const usePostResult = usePost<ActivityTrendCountingResponse>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_ACTIVITYTREND,
    filter,
  );

  return usePostResult;
};

export const useGetActivityPerformance = (filter: FilterInput) => {
  const menu = `analytic_activityPerformance`;

  const usePostResult = usePost<BaseResponse<ActivityPerformanceCounting>>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_ACTIVITYPERFORMANCE,
    filter,
  );

  return usePostResult;
};

export const useGetByPurpose = (filter: FilterInput) => {
  const menu = `analytic_activityByPurpose`;

  const usePostResult = usePost<BaseResponse<ActivityPurposeCounting>>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_ACTIVITYBYPURPOSE,
    filter,
  );

  return usePostResult;
};

export const useGetByPriority = (filter: FilterInput) => {
  const menu = `analytic_activityByPriority`;

  const usePostResult = usePost<ActivityResultsResponse>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_ACTIVITYBYPRIORITY,
    filter,
  );

  return usePostResult;
};

export const useGetTypeCounting = (filter: FilterInput) => {
  const menu = `analytic_activityDateCounting`;

  const usePostResult = usePost<ActivityDateCountingResponse>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_ACTIVITYDATECOUNTING,
    filter,
  );

  return usePostResult;
};

export const useGetTodoCounting = (filter: FilterInput) => {
  const menu = `analytic_activityTodoCounting`;

  const usePostResult = usePost<BaseResponse<IGeneralCounting> | any>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_ACTIVITYTODOCOUNTING,
    filter,
  );

  return usePostResult;
};

export const useGetTodosList = (filter: FilterInput) => {
  const menu = `analytic_activities`;

  const usePostResult = usePost<ResponsePaging<Activity> | any>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_ACTIVITIES,
    filter,
  );

  return usePostResult;
};

export const useGetBaseCounting = (filter: FilterInput) => {
  const menu = `analytic_activityBaseCounting`;

  const usePostResult = usePost<BaseResponse<IGeneralCounting> | any>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_ACTIVITYBASECOUNTING,
    filter,
  );

  return usePostResult;
};
