export enum EAssignmentGroupType {
  ASSIGNMENT_GROUP_TYPE_NONE = 'ASSIGNMENT_GROUP_TYPE_NONE',
  ASSIGNMENT_GROUP_TYPE_ALL = 'ASSIGNMENT_GROUP_TYPE_ALL',
  ASSIGNMENT_GROUP_TYPE_SPECIFIC = 'ASSIGNMENT_GROUP_TYPE_SPECIFIC',
}

export enum EDisplayModeType {
  DISPLAY_MODE_NONE = 'DISPLAY_MODE_NONE',
  DISPLAY_MODE_PORTRAIT = 'DISPLAY_MODE_PORTRAIT',
  DISPLAY_MODE_LANDSCAPE = 'DISPLAY_MODE_LANDSCAPE',
}

export enum EDisplayGridType {
  DISPLAY_GRID_NONE = 'DISPLAY_GRID_NONE',
  DISPLAY_GRID_SHOW = 'DISPLAY_GRID_SHOW',
  DISPLAY_GRID_NEVER = 'DISPLAY_GRID_NEVER',
}