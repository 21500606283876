import React, {ReactNode} from "react";
import Loading from "@base/components/loading";
import {NoData} from "@base/components";
import classNames from "classnames";

interface IBlockBodyProps {
  isNoData?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  children?: ReactNode;
  isFlexContentCenter?: boolean;

  [x: string]: any;
}

const BlockBody: React.FC<IBlockBodyProps> = (props: IBlockBodyProps) => {
  const {
    isNoData = false,
    isLoading = false,
    isFetching = false,
    children = null,
    isFlexContentCenter = false,
    ...restProps
  } = props;

  const isLoadingOrFetching = isLoading || isFetching;

  return (
    <div className={classNames("mg-t-10 ht-400", {"d-flex justify-content-center": isFlexContentCenter})} {...restProps}>
      {isLoadingOrFetching && <Loading/>}
      {isNoData && <NoData/>}
      {!isNoData && !isLoading && children}
    </div>
  );
}

export default BlockBody;