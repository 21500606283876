import React from 'react';

type DesktopLayout1ViewProps = {
  header?: React.ReactNode;
  body: React.ReactNode;
};

const DesktopLayout1View = ({ header, body }: DesktopLayout1ViewProps) => {
  return (
    <div className="view-wrap">
      {header}
      {body}
    </div>
  );
};

export default DesktopLayout1View;
