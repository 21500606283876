import usePost from '@base/hooks/usePost';
import {
  ICustomer,
  ICustomerCounting,
  ICustomerIndustryCounting,
  ICustomerRatingCounting,
} from '../types/interfaces/customer';
import { getQueryString } from '@base/services/list';
import { FilterInput } from '@base/types/interfaces/common';
import { keyStringify } from '@base/utils/helpers/list-page-utils';
import { BaseResponse, ResponsePaging } from '@base/types/interfaces/response';
import {
  ANALYTIC_CUSTOMERTRENDCOUNTING,
  ANALYTIC_CUSTOMERINDUSTRYCOUNTING,
} from '@dashboard/dashboard/services/graphqls/customer';
import {
  IDateCountingResponse,
  IDateCountingResult,
  IDateCountingsResult,
  ITrendCountingResponse,
} from '@dashboard/dashboard/types/interfaces';
import { getDateCountingQueryString } from '@dashboard/dashboard/services/graphqls';

export const useGetCustomerTrendCounting = (filter: FilterInput) => {
  const menu = `analytic_customerTrendCounting`;

  const usePostResult = usePost<ITrendCountingResponse<ICustomerCounting>>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_CUSTOMERTRENDCOUNTING,
    filter,
    {
      keepPreviousData: true
    }
  );

  return usePostResult;
};

export const useGetCustomerPeriodCounting = (filter: FilterInput) => {
  const menu = `analytic_customerPeriodCounting`;

  const usePostResult = usePost<IDateCountingResponse<IDateCountingResult<ICustomerCounting>>>(
    [menu, keyStringify(filter, '')],
    getDateCountingQueryString(
      menu,
      `
      counting {
        total
        account
        contact       
      }
    `,
    ),
    filter,
    {
      keepPreviousData: true
    }
  );

  return usePostResult;
};

export const useGetCustomerRatingCounting = (filter: FilterInput) => {
  const menu = `analytic_customerRatingCounting`;

  const usePostResult = usePost<
    IDateCountingResponse<IDateCountingsResult<ICustomerRatingCounting>>
  >(
    [menu, keyStringify(filter, '')],
    getDateCountingQueryString(
      menu,
      `
      countings {
        rating {
          id
          keyName
          languageKey
        }
        counting{
          total
        }
      }
    `,
    ),
    filter,
    {
      keepPreviousData: true
    }
  );

  return usePostResult;
};

export const useGetCustomerIndustryCounting = (filter: FilterInput) => {
  const menu = `analytic_customerIndustryCounting`;

  const usePostResult = usePost<BaseResponse<ICustomerIndustryCounting>>(
    [menu, ''],
    ANALYTIC_CUSTOMERINDUSTRYCOUNTING,
    filter,
    {
      keepPreviousData: true
    }
  );

  return usePostResult;
};

export const useGetLastUpdatedCustomers = (filter: FilterInput) => {
  const menu = `analytic_lastUpdatedCustomers`;

  const usePostResult = usePost<ResponsePaging<ICustomer> | any>(
    [menu, keyStringify(filter, '')],
    getQueryString(
      menu,
      `
      id
      code
      name
      updatedAt`,
    ),
    filter,
    {
      keepPreviousData: true
    }
  );

  return usePostResult;
};
