import React from 'react';
import '@toast-ui/editor/dist/toastui-editor.css';
interface IEditorViewProps {
  value: any;
}
const EditorView: React.FC<IEditorViewProps> = (props: IEditorViewProps) => {
  const { value } = props;
  return (
    <>
      <div
        className="wd-100p toastui-editor-contents"
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </>
  );
};

export default EditorView;
