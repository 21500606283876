import React from 'react';

interface IDesktopLayout1ListProps {
  toolbar: React.ReactNode;
  header: React.ReactNode;
  body: React.ReactNode
}

const DesktopLayout1List: React.FC<IDesktopLayout1ListProps> = ({
  toolbar,
  header,
  body,
}: IDesktopLayout1ListProps) => {
  return (
    <>
      {toolbar}
      {header}
      {body}
    </>
  );
};

export default DesktopLayout1List;
