import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { MENU_DESK, MENU_DESK_TICKET } from '@base/config/menus';
import { SiteParam } from '@site-page/types/site';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { useTrackingTicketView } from '@site-page/services/service';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { useTrackingPageLayoutByMenu } from '@site-page/hooks/useTrackingPagelayout';
import { publicViewDataByMenuAtom } from '@site-page/recoil/view';
import { default as viewConfig } from '@site-page/config/desk/view-field';
import Loading from '@base/components/loading';

//import AllTickets from './AllTickets';
import CustomerTickets from '@site-page/containers/desk/view/ticket';
import Details from '@site-page/containers/desk/view/details';
import Summary from './Summary';
import Timeline from './timeline';
import Toolbar from './Toolbar';

const TABS = [
  {
    value: 1,
    label: 'Details',
  },
  {
    value: 2,
    label: 'Timeline',
  },
  {
    value: 3,
    label: 'All Tickets',
  },
];
interface DeskViewProps {
  siteParams: SiteParam;
}

const DeskView: React.FC<DeskViewProps> = (props: DeskViewProps) => {
  const { siteParams } = props;
  const { tk } = siteParams;
  // get customer and ticket id
  //const cusId = token.C || ''; //?? '24a1007d-3b21-4009-acd5-60fc5de2bf42';
  //const docId = id || token.D; //'908617ba-b373-4588-b0e0-401618e88962';
  const [tab, setTab] = useState(TABS[0]);
  const [title, setTitle] = useState('...');
  const [docId, setDocId] = useState('');
  const [isOpenWrite, setIsOpenWrite] = useState<boolean>(false); //for write page
  const setViewData = useSetRecoilState(publicViewDataByMenuAtom(MENU_DESK_TICKET)); //view data

  //init
  useEffect(() => {
    const { id, token } = siteParams;
    setDocId(id || token.D);
  }, [siteParams]);

  /** Get data */
  const { data: layoutView, isLoading: isLayoutLoading } = useTrackingPageLayoutByMenu(
    MENU_DESK_TICKET,
    'view',
    tk,
  );
  const viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  const {
    isLoading,
    data: ticketData,
    isFetching: isDataLoading,
    refetch,
  } = useTrackingTicketView<any>(viewSchema, { id: docId, token: tk });

  //get detail data
  useEffect(() => {
    if (!isLayoutLoading && !isLoading && ticketData) {
      const layoutData = mergeLayoutData(layoutView, ticketData, viewConfig);
      let newLayoutData: any = { ...layoutData };
      newLayoutData.menuSourceId = docId;
      newLayoutData.menuSource = MENU_DESK_TICKET;
      newLayoutData.menu = MENU_DESK_TICKET;
      setViewData(newLayoutData);
      setTitle(ticketData?.subject ?? '');
    }
  }, [ticketData, isLoading, isLayoutLoading]);

  return (
    <>
      <div className="d-flex flex-column ht-100p">
        <div className="d-flex flex-wrap align-items-center pd-x-20 pd-y-15 bd-b">
          <h1 className="mg-0 tx-24">{title}</h1>
          <Toolbar
            ticketId={docId}
            token={tk}
            onOpenWrite={() => {
              setTab(TABS[2]); //all tickets
              setIsOpenWrite(true);
            }}
            onRefeshView={refetch}
          />
        </div>
        <div className="flex-grow-1 pd-20 scroll-box">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              {(isLayoutLoading || isDataLoading) && <Loading />}
              <Summary id={docId} category={MENU_DESK_TICKET} />
            </div>
            <div className="col-md-8 col-lg-9">
              <ul className="nav nav-tabs flex-grow-1">
                {TABS.map((item, index) => (
                  <li key={index} className="nav-item">
                    <button
                      type="button"
                      className={`nav-link ${item.value == tab.value ? 'active' : ''}`}
                      onClick={(e) => {
                        setTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
              {tab.value == 1 && (
                <Details token={tk} menuSource={MENU_DESK_TICKET} menuSourceId={docId} />
              )}
              {tab.value == 2 && <Timeline menuSource={MENU_DESK} menuSourceId={docId} />}
              {tab.value == 3 && !isDataLoading && (
                <CustomerTickets
                  token={tk}
                  customer={ticketData?.customer}
                  onDocChange={setDocId}
                  isOpenWrite={isOpenWrite}
                  onClose={() => setIsOpenWrite(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeskView;
