import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import Loading from '@base/components/loading';

const UserPage = React.lazy(() => import('../user'));
const AdminPage = React.lazy(() => import('../admin'));

const FirstPage = () => {
  return (
    <>
      <Helmet>
        <title>Hanbiro CRM - Dashboard</title>
      </Helmet>
      <Routes>
        <Route index element={<Navigate replace to="/analytic/dashboard/user"/>}/>
        <Route path="/user" element={
          <React.Suspense fallback={<Loading/>}>
            <UserPage/>
          </React.Suspense>
        }/>
        <Route path="/admin" element={
          <React.Suspense fallback={<Loading/>}>
            <AdminPage/>
          </React.Suspense>
        }/>
      </Routes>
    </>
  );
};

export default FirstPage;
