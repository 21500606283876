import React, {useEffect, useRef, useState} from 'react';
import {Button} from "@base/components/form";
import {sectionOptions} from "@dashboard/main/configs/options";
import styled from "@emotion/styled";
import {getDateRangeStringFromKey} from "@base/utils/helpers";
import {analyticGeneralValue} from "@dashboard/main/recoil/general-query";
import {getChartConfigsBy, getSectionConfigsBy} from "@dashboard/main/utils";
import {ChartWrapper} from "@dashboard/main/components/chart-wrapper";
import {
  DisplayLandscape,
  DisplayNormal,
  DisplayPortrait,
  SectionEmpty
} from "@dashboard/report/components/display-mode";
import {isArray} from "lodash";
import {EDisplayGridType, EDisplayModeType} from "@dashboard/report/types/enum";

const Wrapper = styled.div`
  .report-page-content{
    //pointer-events: none;
  }
`

interface ReportingContentViewProps {
  value: any[];
  dateRange?: string;
}
const ReportingContentView: React.FC<ReportingContentViewProps> = (props: ReportingContentViewProps) => {
  const { value, dateRange = '' } = props;

  if (!value || !value?.length){
    return (
      <Wrapper className="row row-xs wd-100p">
        <div className="col-12">
          <div className="card">
            <div className="pd-10 bg-light report-page-content">
              <SectionEmpty/>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  const [pages, setPages] = useState<any[]>(value ?? []);
  const [pageActive, setPageActive] = useState(value?.[0] ?? {});

  const {
    sections = [],
    displayMode,
    displayGrid,
  } = pageActive;

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(pages)) {
      setPages(value);
    }
  }, [value])

  const defaultFilterQuery = dateRange ? getDateRangeStringFromKey(dateRange) : null;

  const {userRole} = analyticGeneralValue();
  const allChartConfigs = getChartConfigsBy('all', userRole, {
    onlyChartConfig: true
  }) ?? {};
  const sectionConfigs = getSectionConfigsBy(userRole) ?? {};

  const {sectionKeys, chartKeys} = sections.reduce((f: any, s: any) => {
    if(s?.section){
      f.sectionKeys = [...f.sectionKeys, s.section];
    }
    if(s?.charts && isArray<string>(s?.charts)){
      f.chartKeys = [...f.chartKeys, ...s.charts];
    }
    return f;
  }, {sectionKeys: [], chartKeys: []});

  const isLandscape = displayMode === EDisplayModeType.DISPLAY_MODE_LANDSCAPE;
  const isPortrait = displayMode === EDisplayModeType.DISPLAY_MODE_PORTRAIT;

  const displayProps = {
    sectionKeys,
    chartKeys,
    sectionConfigs,
    isDisplayGrid: displayGrid === EDisplayGridType.DISPLAY_GRID_SHOW
  };

  const ref: any = useRef<any>(null);
  const [parentProps, setParentProps] = useState({width: 0});
  useEffect(() => {
    if(ref?.current){
      setParentProps({...parentProps, width: $(ref?.current)?.width() ?? 0});
    }
  }, [ref?.current]);

  return (
    <Wrapper className="row row-xs wd-100p">
      <div className="col-12">
        <div className="card">
          <div className="card-header pd-0">
            <ul className="nav nav-line mg-t-10 pd-x-15 bd-0">
              {pages.map((p, i) => (
                <li className="nav-item d-flex" key={i}>
                  <Button
                    color=""
                    key={i}
                    className={`nav-link ${pageActive.id == p.id ? 'active' : ''}`}
                    onClick={() => setPageActive(p)}
                    name={p.name}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-light report-page-content" ref={ref}>
            {
              isPortrait && <DisplayPortrait {...displayProps}/>
            }
            {
              isLandscape && <DisplayLandscape {...{...displayProps, parentProps}}/>
            }

            {/*{
              sections?.map((s: any, i: number) => {
                const {section, charts = []} = s;
                return (
                  <div className="card" key={i}>
                    <div className="card-header tx-semibold">
                      {sectionOptions?.[section] ?? '-No Name-'}
                    </div>
                    <div className="pd-10 bg-light">
                      <div className="row row-xs">
                        {
                          charts?.map((c: string, ii: number) => {
                            const {
                              wrapperProps,
                              component: Component,
                              componentProps: iComponentProps
                            } = allChartConfigs?.[c] ?? {};

                            const componentProps = {
                              ...iComponentProps,
                              query: defaultFilterQuery
                            };

                            return (
                              <ChartWrapper {...wrapperProps} key={ii}>
                                <Component {...componentProps}/>
                              </ChartWrapper>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                );
              }) || <SectionEmpty/>
            }*/}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ReportingContentView;
