import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import SelectDropdown from '@base/components/form/select-dropdown';
import SelectDropdownWithDates from '@base/components/form/select-dropdown-dates';
import SelectDropdownFilters from '@base/components/form/select-dropdown-filters';
import ListHeaderFilterSplitMode from '@dashboard/report/components/list/list-header/list-header-filter-splitmode';
import ListHeaderSort from '@base/components/list/list-header/list-header-sort';
import {
  groupOptions,
  dateOptions,
  filterOptions
} from '@dashboard/report/configs/header';

interface IListHeaderFilters {
  category: string;
  filterData: { [key: string]: any };
  onFilterDataChanged: (value: any) => void;
  isSplitMode?: boolean;
  sortColumns: any[];
  sortData: any;
  onSortDataChanged: (sortData: any) => void;
}

const ListHeaderFilterNormalMode = (props: any) => {
  const {
    filterData,
    onFilterDataChanged,
  } = props;

  //selected dates
  let selectedDates: any = null;
  dateOptions.map((opt: any) => {
    if (filterData[opt.value]) {
      if (filterData[opt.value]?.criteria?.length > 0) {
        if(!selectedDates){
          selectedDates = {};
        }
        selectedDates[opt.value] = [
          filterData[opt.value]?.criteria[0].value,
          filterData[opt.value]?.criteria[1].value,
        ];
      }
    }
  })

  //selected filters by fields
  let selectedFilters: any = {};
  filterOptions.map((_option: any) => {
    if (filterData[_option.value]) {
      if (filterData[_option.value]?.criteria?.length > 0) {
        const tmpValues: any = [];
        filterData[_option.value].criteria.map((_ele: any) => {
          tmpValues.push(_ele.value);
        })
        selectedFilters[_option.value] = tmpValues;
      } else {
        if (filterData[_option.value].value) {
          selectedFilters[_option.value] = filterData[_option.value].value;
        }
      }
    }
  });

  return (
    <>
      <SelectDropdown
        options={groupOptions}
        selected={filterData?.groupBy?.value || groupOptions[0].value}
        placeholder="Group By"
        onChange={(data: any) => onFilterDataChanged(data.value, 'groupBy')}
      />
      <SelectDropdownWithDates
        placeholder="Select date"
        options={dateOptions}
        selected={selectedDates}
        onChange={(data: any) => {
          onFilterDataChanged(data, 'date');
        }}
      />
      <SelectDropdownFilters
        placeholder="Filters"
        options={filterOptions}
        selected={selectedFilters}
        onChange={(data: any) => onFilterDataChanged(data, 'filter')}
      />
    </>
  );
};

const ListHeaderFilters: React.FC<IListHeaderFilters> = (props) => {
  const {
    isSplitMode,
    sortColumns,
    sortData,
    onSortDataChanged,
    filterData, //filter on Recoil
    onFilterDataChanged,
  } = props;

  const [date, setDate] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [groupBy, setGroupBy] = useState<any>({});

  useEffect(() => {
    onFilterDataChanged({ ...date, ...filter, ...groupBy });
  }, [date, filter, groupBy]);

  //all filter props
  const filterProps = {
    filterData,
    onFilterDataChanged: (data: any, type: string) => {
      if (type === 'date') {
        for (const k in data) {
          data[k][0] = dayjs(data[k][0]).toISOString();
          data[k][1] = dayjs(data[k][1]).toISOString();
        }
        setDate(data);
      } else if (type === 'filter') {
        setFilter(data);
      } else {
        setGroupBy(data ? { groupBy: data } : {});
      }
    },
    groupOptions,
    filterOptions,
    dateOptions,
  };

  const sortProps = { onSortDataChanged, sortData, sortColumns };

  return (
    <>
      {isSplitMode ? (
        <ListHeaderFilterSplitMode
          {...filterProps}
          sort={sortColumns && !!sortColumns.length && <ListHeaderSort {...sortProps} isCollapse />}
        />
      ) : (
        <>
          <ListHeaderFilterNormalMode {...filterProps} />
          {sortColumns && !!sortColumns.length && <ListHeaderSort {...sortProps} />}
        </>
      )}
    </>
  );
};

export default ListHeaderFilters;
