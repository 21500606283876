import ReactApexChart from "react-apexcharts";
import React from "react";
import {ALL_COLORS} from "@dashboard/dashboard/config/common/colors";
import {merge} from "lodash";

interface IBarSimpleProps {
  series: number[];
  categories: string[];
  [x: string]: any;
}

const BarSimple = (props: IBarSimpleProps) => {
  const {series, categories, options, ...restProps} = props;

  let baseOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        distributed: false,
        horizontal: false,
        columnWidth: '5%',
      },
    },
    dataLabels: {
      enabled: false
    },
    colors: ALL_COLORS,
    xaxis: {
      categories
    }
  };

  if (!!options){
    baseOptions = merge(baseOptions, options)
  }

  return (
    <ReactApexChart
      type="bar"
      height={'98%'}
      width={'100%'}
      series={[
        {
          name: "Total",
          data: series
        },
      ]}
      options={baseOptions}
      {...restProps}
    />
  );
};

export default BarSimple;