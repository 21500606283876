import usePost from '@base/hooks/usePost';
import { FilterInput } from '@base/types/interfaces/common';
import { keyStringify } from '@base/utils/helpers/list-page-utils';
import {
  IKbDateCounting,
  IKbRepCounting,
  ITicketAvgCustomerSatisfactionResponse,
  ITicketBaseCountingResponse,
  ITicketByDateCountingResponse,
  ITicketCategoryCounting,
  ITicketPriorityCountingResult,
  ITicketTrendCountingResponse,
  TicketDateCounting,
} from '@dashboard/dashboard/types/interfaces/desk';
import { BaseResponse } from '@base/types/interfaces/response';
import { IDateCountingResponse } from '@dashboard/dashboard/types/interfaces';
import { getQueryString } from '@dashboard/dashboard/services/graphqls';
import {
  ANALYTIC_KBDATECOUNTING,
  ANALYTIC_TICKETAVGCUSTOMERSATISFACTION,
  ANALYTIC_TICKETBASECOUNTING,
  ANALYTIC_TICKETBYDATECOUNTING,
  ANALYTIC_TICKETCATEGORY,
  ANALYTIC_TICKETPERFORMANCE,
  ANALYTIC_TICKETPRIORITY,
  ANALYTIC_TICKETTRENDCOUNTING,
} from '@dashboard/dashboard/services/graphqls/desk';

export const useGetTicketTrendCounting = (filter: FilterInput) => {
  const menu = `analytic_ticketTrendCounting`;

  const usePostResult = usePost<ITicketTrendCountingResponse>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_TICKETTRENDCOUNTING,
    filter,
  );

  return usePostResult;
};

export const useGetTicketBaseCounting = (filter: FilterInput) => {
  const menu = `analytic_ticketBaseCounting`;

  const usePostResult = usePost<ITicketBaseCountingResponse>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_TICKETBASECOUNTING,
    filter,
  );

  return usePostResult;
};

export const useGetTicketByDateCounting = (filter: FilterInput) => {
  const menu = `analytic_ticketByDateCounting`;

  const usePostResult = usePost<ITicketByDateCountingResponse<TicketDateCounting>>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_TICKETBYDATECOUNTING,
    filter,
  );

  return usePostResult;
};

export const useGetTicketAvgCustomerSatisfaction = (filter: FilterInput) => {
  const menu = `analytic_ticketAvgCustomerSatisfaction`;

  const usePostResult = usePost<ITicketAvgCustomerSatisfactionResponse>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_TICKETAVGCUSTOMERSATISFACTION,
    filter,
  );

  return usePostResult;
};

export const useGetTicketPerformance = (filter: FilterInput) => {
  const menu = `analytic_ticketPerformance`;

  const usePostResult = usePost<ITicketBaseCountingResponse>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_TICKETPERFORMANCE,
    filter,
  );

  return usePostResult;
};

export const useGetTicketPriority = (filter: FilterInput) => {
  const menu = `analytic_ticketPriority`;

  const usePostResult = usePost<BaseResponse<ITicketPriorityCountingResult>>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_TICKETPRIORITY,
    filter,
  );

  return usePostResult;
};

export const useGetTicketCategory = (filter: FilterInput) => {
  const menu = `analytic_ticketCategory`;

  const usePostResult = usePost<BaseResponse<ITicketCategoryCounting>>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_TICKETCATEGORY,
    filter,
  );

  return usePostResult;
};

export const useGetKbDateCounting = (filter: FilterInput) => {
  const menu = `analytic_kbDateCounting`;

  const usePostResult = usePost<IDateCountingResponse<IKbDateCounting>>(
    [menu, keyStringify(filter, '')],
    ANALYTIC_KBDATECOUNTING,
    filter,
  );

  return usePostResult;
};

export const useGetKbRepCounting = (filter: FilterInput, fieldSchema: string = '') => {
  const menu = `analytic_kbRepCounting`;

  if (fieldSchema == '') {
    fieldSchema = `
      published
      viewed
      inserted
    `;
  }

  const usePostResult = usePost<BaseResponse<IKbRepCounting>>(
    [menu, keyStringify(filter, ''), fieldSchema],
    getQueryString(
      menu,
      `
      results {
        user {
          id
          name
        }
        counting {
          ${fieldSchema}
        }
      }
    `,
    ),
    filter,
  );

  return usePostResult;
};
