import {EDisplayGridType, EDisplayModeType} from "@dashboard/report/types/enum";
import {keyValueToArray} from "@dashboard/main/utils";
import {
  displayModeOptions,
  sectionOptions as sourceSectionOptions
} from "@dashboard/main/configs/options";
import React, {useEffect, useState} from "react";
import {keys} from "lodash";
import {RadioGroup, Select} from "@base/components/form";
import {analyticGeneralValue} from "@dashboard/main/recoil/general-query";
import {getSectionConfigsBy} from "@dashboard/main/utils";

interface ReportContentSettingProps {
  data?: {
    displayMode: EDisplayModeType;
    displayGrid: EDisplayGridType;
    selectedSections: any[];
    selectedCharts: any[];
  }
  onChange?: (v: any) => void;
}

const ReportContentSetting: React.FC<ReportContentSettingProps> = (props: ReportContentSettingProps) => {
  const {data, onChange} = props;

  const {userRole} = analyticGeneralValue();
  const sectionConfigs = getSectionConfigsBy(userRole) ?? {};

  const {
    displayMode: iDisplayMode,
    displayGrid: iDisplayGrid,
    selectedSections: iSelectedSections,
    selectedCharts: iSelectedCharts
  } = data ?? {};

  const sectionOptions: any[] = keyValueToArray(sourceSectionOptions);
  const [chartOptions, setChartOptions] = useState<any[]>([]);

  const [selectedSections, setSelectedSections] = useState<any[]>(iSelectedSections ?? []);
  const [selectedCharts, setSelectedCharts] = useState<any[]>(iSelectedCharts ?? []);
  const [displayMode, setDisplayMode] = useState<string>(iDisplayMode ?? EDisplayModeType.DISPLAY_MODE_PORTRAIT);
  const [displayGrid, setDisplayGrid] = useState<string>(iDisplayGrid ?? EDisplayGridType.DISPLAY_GRID_SHOW);

  useEffect(() => {
    const {
      displayMode: iDisplayMode,
      displayGrid: iDisplayGrid,
      selectedSections: iSelectedSections,
      selectedCharts: iSelectedCharts
    } = data ?? {};

    if (!!iDisplayMode && iDisplayMode != displayMode) {
      setDisplayMode(iDisplayMode);
    }
    if (!!iDisplayGrid && iDisplayGrid != displayGrid) {
      setDisplayGrid(iDisplayGrid);
    }
    if (!!iSelectedSections && JSON.stringify(iSelectedSections) != JSON.stringify(selectedSections)) {
      setSelectedSections(iSelectedSections);
    }
    if (!!iSelectedCharts && JSON.stringify(iSelectedCharts) != JSON.stringify(selectedCharts)) {
      setSelectedCharts(iSelectedCharts);
    }
  }, [data]);

  useEffect(() => {
    const sectionKeys = selectedSections.map((v: any) => v.value);
    const newChartOptions = keys(sectionConfigs).reduce((f: any[], k, i) => {
      if (sectionKeys.includes(k) && sectionConfigs?.[k]?.chartConfigs) {
        const chartOptionsBySection = sectionConfigs?.[k]?.chartConfigs;
        f = [...f, ...(keys(chartOptionsBySection).map((k, ii) => ({
          value: k,
          label: chartOptionsBySection[k]?.componentProps?.title
        })))];
      }
      return f;
    }, []);
    setChartOptions(newChartOptions);
  }, [selectedSections]);

  useEffect(() => {
    onChange && onChange({
      displayMode,
      displayGrid,
      selectedSections,
      selectedCharts
    });
  }, [displayMode, displayGrid, selectedSections, selectedCharts]);

  return (
    <div className="card card-body ht-100p bg-light">
      <div className="form-group">
        <label className="form-item-title">Display Mode</label>
        <RadioGroup
          options={keyValueToArray(displayModeOptions)}
          value={displayMode}
          onChange={(v: any) => setDisplayMode(v.value)}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">Display Grid</label>
        <RadioGroup
          options={[
            {value: EDisplayGridType.DISPLAY_GRID_SHOW, label: 'Show'},
            {value: EDisplayGridType.DISPLAY_GRID_NEVER, label: 'Never'}
          ]}
          value={displayGrid}
          onChange={(v: any) => setDisplayGrid(v.value)}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">Section</label>
        <Select
          menuPlacement="top"
          isMulti
          options={sectionOptions}
          value={selectedSections}
          onChange={(v: any[]) => setSelectedSections(v)}
        />
      </div>
      <div className="form-group">
        <label className="form-item-title">Chart</label>
        <Select
          menuPlacement="top"
          isMulti
          options={chartOptions}
          value={selectedCharts}
          onChange={(v: any) => setSelectedCharts(v)}
        />
      </div>
    </div>
  );
}

export default ReportContentSetting;