import {keys} from "lodash";
import chartConfigs from "@dashboard/main/configs/charts";
import classNames from "classnames";
import {ChartWrapper} from "@dashboard/main/components/chart-wrapper";
import {VirtualChart} from "@dashboard/main/components/virtual-chart";
import React from "react";
import SectionEmpty from "../section-empty";

const DisplayNormal = (props: any) => {
  const {
    data = {},
    sectionConfigs = {}
  } = props;

  const {
    selectedSections: iSelectedSections = [],
    selectedCharts: iSelectedCharts = []
  } = data ?? {};

  const iSelectedSectionsLength: number = iSelectedSections?.length ?? 0;

  return (
    <div className="">
      {!iSelectedSectionsLength && <SectionEmpty/>}
      {
        !!iSelectedSectionsLength && iSelectedSections.map((s: any, i: number) => {
          const chartKeys: string[] = iSelectedCharts.map((v: any) => v.value);
          const chartOptionsBySection: any = sectionConfigs?.[s.value]?.chartConfigs ?? {}
          const chartOptions: any = keys(chartOptionsBySection).reduce((f: any[], k) => {
            if(chartKeys.includes(k) && chartOptionsBySection[k]){
              f = [...f, {
                ...(chartConfigs?.[k] ?? {}),
                value: k,
                label: chartOptionsBySection?.[k]?.componentProps?.title
              }];
            }
            return f;
          }, []);

          return (
            <div className={classNames('card', {'mg-t-10': i > 0})} key={i}>
              <div className="card-header tx-semibold">{s.label}</div>
              <div className="pd-10 bg-light">
                <div className="row row-xs">
                  {
                    !chartOptions?.length && <SectionEmpty/>
                  }
                  {
                    !!chartOptions?.length && chartOptions.map((c: any, ii: number) => {
                      const {wrapperProps} = c ?? {};

                      return (
                        <ChartWrapper {...wrapperProps} key={ii}>
                          <VirtualChart title={c.label}/>
                        </ChartWrapper>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default DisplayNormal;