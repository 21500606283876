import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import { useGetTypeCounting } from '@dashboard/dashboard/services/activity';
import { ALL_COLORS } from '@dashboard/dashboard/config/common/colors';
import BarMarker from '@dashboard/dashboard/components/chart/bar-marker';
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const ActivityGroupDuration = (props: ChartComponentProps) => {
  const {me = false, filters, ...restProps} = props;

  const {data} = useChartQuery(restProps, () => {
    return useGetTypeCounting(filters ?? {});
  });

  const { results = [], period } = data ?? {};

  let oData: any = {
    categories: [],
    duration: {
      name: me ? 'My Group Duration' : 'Group Duration',
      data: [],
    },
    avgDuration: {
      name: 'Avg. Duration',
      data: [],
    },
  };

  if (!!results) {
    oData = results.reduce((f: any, v: any) => {
      const { duration = 0, avgDuration = 0 } = v?.counting ?? {};
      f['categories'].push(v?.date?.name ?? '-');
      f['duration']['data'].push(duration);
      f['avgDuration']['data'].push(avgDuration);
      return f;
    }, oData);
  }

  const chartData: any[] = oData.categories.map((v: string, i: number) => {
    return {
      x: v,
      y: oData.duration.data?.[i] ?? 0,
      goals: [
        {
          name: oData.avgDuration.name,
          value: oData.avgDuration.data?.[i] ?? 0,
          strokeWidth: 6,
          strokeHeight: 5,
          strokeColor: ALL_COLORS[1],
        },
      ],
    };
  });

  return (
    <BarMarker
      series={[
        {
          name: oData.duration.name,
          data: chartData,
        },
      ]}
      categories={[oData.duration.name, oData.avgDuration.name]}
    />
  );
};

export default withChartContainer(ActivityGroupDuration);
