import { MENU_ANALYTIC } from '@base/config/menus';
import { useRecoilValue } from 'recoil';
import { FilterInput } from '@base/types/interfaces/common';
import { buildListSchema } from '@base/utils/helpers/schema';
import { getQueryString } from '@base/services/list';
import usePosts from '@base/hooks/usePosts';
import {generateFilterQuery, keyStringify} from '@base/utils/helpers/list-page-utils';
import { reportListFilterState } from '@dashboard/report/recoil/atom';
import { default as configFields } from '@dashboard/report/configs/view-field';
import { IReport } from '@dashboard/report/types/interfaces';
import {REPORT_REPORTING_CONTENT} from '@dashboard/report/configs/key-names';

export const useReports = (category: string, fields: any[]) => {
  const menu = `${MENU_ANALYTIC}_reports`;

  const { filter } = useRecoilValue(reportListFilterState);

  let filtersQuery: FilterInput = {
    sort: filter?.sort,
    paging: filter?.paging,
  };

  let strQuery = '';
  strQuery += ' ' + generateFilterQuery(filter.query);
  filtersQuery.query = strQuery;

  let listQuerySchema = '';
  if (fields && fields.length > 0) {
    listQuerySchema = buildListSchema({ fields, configFields, ignore: [REPORT_REPORTING_CONTENT] });
  }
  const queryString = getQueryString(menu, listQuerySchema);

  let params = {
    filter: filtersQuery,
  };

  const fieldQueryKey = fields.map((_ele: any) => _ele.keyName).join(',');

  const usePostResult = usePosts<IReport[]>(
    [menu, keyStringify(filtersQuery, ''), fieldQueryKey],
    queryString,
    params,
    {
      //keepPreviousData: true,
      enabled: fields.length > 0,
    },
  );

  return usePostResult;
};
