import * as commonConfig from '@base/config/view-field';
import {IFieldConfig} from '@base/types/interfaces/common';
import * as commonComponents from "@base/config/view-field/components";
import * as keyNames from '@dashboard/report/configs/key-names';
import * as components from './components';
import {KEY_NAME_CREATED_BY} from "@base/config/key-names";
import {cloneDeep, isArray, isBoolean, isString, keys, pick} from "lodash";
import {EDisplayGridType} from "@dashboard/report/types/enum";
import {dateRangeOptions} from "@base/config/options";
import {EDateRangeType} from "@base/types/enums";

const viewFieldsConfig: IFieldConfig = {
  ...commonConfig?.default,
  [keyNames.REPORT_NAME]: {
    component: commonComponents.TextView,
    schema: 'name',
    getRecoilStateValue: (value: any) => {
      return value || '';
    }
  },
  [KEY_NAME_CREATED_BY]: {
    component: components.Owner,
    showFullRow: false,
    schema: `createdBy {
      id
      name
      fullName
    }`,
    getValueView: (value: any) => {
      return value ?? {};
    },
  },
  [keyNames.REPORT_ACTIVE]: {
    component: commonComponents.Switch,
    showFullRow: false,
    schema: `active`,
    getValueView: (value: any) => {
      return !!value ?? false;
    },
  },
  [keyNames.REPORT_RECIPIENT]: {
    component: components.Recipient,
    showFullRow: true,
    schema: `recipients {
      id
      name
    }`,
    getValueView: (value: any) => {
      return value ?? [];
    },
    getValueEdit: (value: any) => {
      return !!value ? value.map((v: any) => ({label: v.name, value: v.id})) : [];
    },
    getMutationValue: (value: any[]) => {
      return {
        [keyNames.REPORT_RECIPIENT]: value?.map((u: any) => ({
          user: {
            id: u.id ? u.id : '',
            name: u.name ? u.name : '',
          },
          group: {
            id: u.properties?.crmGroups?.length > 0 ? u.properties?.crmGroups[0]?.id : '',
            name: u.properties?.crmGroups?.length > 0 ? u.properties?.crmGroups[0]?.name : '',
          },
        })) ?? null
      };
    }
  },
  [keyNames.REPORT_SUBJECT]: {
    component: commonComponents.TextView,
    showFullRow: true,
    schema: `subject`,
  },
  [keyNames.REPORT_CONTENT]: {
    component: commonComponents.TextAreaView,
    showFullRow: true,
    schema: `content`,
  },
  [keyNames.REPORT_ASSIGNMENT_GROUP]: {
    component: components.AssignmentGroup,
    showFullRow: true,
    schema: `
      assignmentGroupType
      assignmentGroups{
        id
        name
      }
    `,
    getValue: (data: any) => {
      return pick(data, ['assignmentGroupType', 'assignmentGroups']);
    },
    getMutationValue: (value: any) => {
      return {
        assignmentGroupType: value?.assignmentGroupType ?? '',
        assignmentGroups: value?.assignmentGroups?.map((v: any) => ({id: v.id, name: v.name})) ?? null
      };
    }
  },
  [keyNames.REPORT_REPORTING_CYCLE]: {
    component: components.ReportingCycle,
    showFullRow: false,
    schema: `reportingCycle {
      frequency
      dayAt
      monthAt
      timeAt
    }`,
  },
  [keyNames.REPORT_DATE_RANGE]: {
    component: commonComponents.Selectbox,
    componentProps: {
      fieldValue: 'value',
      fieldLabel: 'label',
      options: keys(dateRangeOptions).map((k) => {
        return {
          label: dateRangeOptions[k],
          value: k
        };
      }),
      isSearchable: false,
      single: true,
    },
    showFullRow: false,
    schema: `dateRange`,
    getValueView: (value: any) => {
      const key = isString(value) ? value : (value?.value ?? '')
      return !!key ? dateRangeOptions[key] : '';
    },
    getValueEdit: (value: any) => {
      const key = isString(value) ? value : (value?.value ?? '')
      return !!key ? {
        label: dateRangeOptions[key],
        value: key
      } : {};
    },
    getMutationValue: (value: any) => {
      return {
        [keyNames.REPORT_DATE_RANGE]: value?.value || EDateRangeType.DATE_RANGE_THIS_MONTH
      };
    }
  },
  [keyNames.REPORT_REPORTING_CONTENT]: {
    showFullRow: true,
    schema: `
      pages {
        id
        name
        displayMode
        displayGrid
        sections {
          section
          charts
        }
      }
    `,
    component: components.ReportingContent,
    getPropsEdit: (viewData) => {
      return {
        showEdit: true,
        navLimit: 4
      };
    },
    getValue: (data: any) => {
      const pages = !!data?.pages ? cloneDeep(data?.pages) : [];
      if(!!pages?.length){
        return pages.map((p: any) => {
          if(p?.hasOwnProperty('displayGrid') && isBoolean(p.displayGrid)){
            p.displayGrid = p.displayGrid ? EDisplayGridType.DISPLAY_GRID_SHOW : EDisplayGridType.DISPLAY_GRID_NEVER;
          }
          return p;
        });
      }
      return pages;
    },
    getMutationValue: (value: any) => {
      const pages = !!value && isArray(value) ? cloneDeep(value).map((p) => {
        if(p?.hasOwnProperty('displayGrid') && isString(p.displayGrid)){
          p.displayGrid = p.displayGrid === EDisplayGridType.DISPLAY_GRID_SHOW;
        }
        if(!!p?.isNewPage){
          delete p.id;
          delete p.isNewPage;
        }
        return p;
      }) : null;

      return {
        pages
      };
    }
  },
};
export default viewFieldsConfig;
