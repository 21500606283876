import React from "react";
import ReactApexChart from "react-apexcharts";
import {ALL_COLORS} from "@dashboard/dashboard/config/common/colors";
import {merge} from "lodash";

interface IBarHorizontalProps {
  categories: string[];
  series: any[];
  [x: string]: any;
}

const BarHorizontal: React.FC<IBarHorizontalProps> = (props: IBarHorizontalProps) => {
  const {categories, series, options, ...restProps} = props;

  let baseOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '10',
      }
    },
    colors: ALL_COLORS,
    xaxis: {
      categories
    }
  };

  if (!!options){
    baseOptions = merge(baseOptions, options)
  }

  return <ReactApexChart
    type="bar"
    height={'98%'}
    width={'98%'}
    series={[
      {
        name: "Total",
        data: series
      }
    ]}
    options={baseOptions}
    {...restProps}
  />
};

export default BarHorizontal;