import { useGetKbDateCounting } from '@dashboard/dashboard/services/desk';
import Bar from '@dashboard/dashboard/components/chart/bar';
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";

const KBOverview = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetKbDateCounting(props?.filters ?? {});
  });

  const { results = [] } = data ?? {};

  let oData: any = {
    categories: [],
    published: {
      name: 'Published',
      data: [],
    },
    inserted: {
      name: 'Inserted',
      data: [],
    },
    viewed: {
      name: 'Viewed',
      data: [],
    },
  };
  if (!!results && !!results?.length) {
    oData = results.reduce((f: any, v: any) => {
      const { published = 0, viewed = 0, inserted = 0 } = v?.counting ?? {};
      f['categories'].push(v?.date?.name ?? '-');
      f['published']['data'].push(published);
      f['viewed']['data'].push(viewed);
      f['inserted']['data'].push(inserted);
      return f;
    }, oData);
  }

  return <Bar
    series={[oData.published, oData.viewed, oData.inserted]}
    categories={oData.categories}
  />;
};

export default withChartContainer(KBOverview);
