import { useLocation } from 'react-router-dom';
import LS from '@base/utils/storages/ls';
import QueryString from 'query-string';
import usePost from '@base/hooks/usePost';
import { SitePage, ITracking } from '@site-page/types/site';
import { CREATE_TRACKING, GET_SITE_PAGE } from '@site-page/services/graphql';

const useTrack = (section: string): [SitePage | undefined, boolean] => {
  const location = useLocation();
  const ls = new LS();
  const query = QueryString.parse(location.search as string);
  // console.log(query);
  if (!query.tk) {
    return [undefined, false];
  }
  let uniqueVisit = true;
  if (query.token == ls.get(`vora.net:${section}:${query.ukey}`)) {
    uniqueVisit = false;
  } else {
    ls.set(`vora.net:${section}:${query.ukey}`, query.token as string);
  }

  const { data: page, isLoading } = usePost<SitePage>(['tracking_site'], GET_SITE_PAGE, {
    id: query.utm_id as string,
    token: query.tk as string,
  });

  const { data: tracking } = usePost(['tracking_createTracking'], CREATE_TRACKING, {
    tracking: {
      cta: query.utm_id as string,
      source: query.utm_source as string,
      medium: query.utm_medium as string,
      campaign: query.utm_campaign as string,
      term: query.utm_term as string,
      congent: query.utm_content as string,
      token: query.tk as string, // send it through header
      unique: uniqueVisit,
    } as ITracking,
  });

  return [page, isLoading];
};

export default useTrack;
