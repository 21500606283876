import { Base64 } from 'js-base64';
import UrlParams from '@base/utils/helpers/urlParams';
import { ESiteType, ETrackingType } from '@site-page/types/enum';
import { SiteParam, Token } from '@site-page/types/site';
//utm_id=xxxxxxx&utm_source=marketo&utm_medium=email&utm_campaign=idea_2022_2_newsletter&utm_content=newsletter&tk=RD0xJlA9MiZTPTMmQz00JlQ9NQ
// - url : site/page url
// - utm_source : facebook, naver
// - utm_medium : email, sms, blog, newsletter
// - utm_campaign : sign_up, summer_sale, free_trial
// - utm_term(keyword) : social_media, product_name
// - utm_content : video_ad, text_ad, banner
// - token : Identity(acccount/contact id) or email // D=%s&P=%s&S=%s&C=%s&T=%s // D: docId

export function tokenExtract(tk: string): Token {
  const tokenStr = Base64.decode(tk);
  // do param
  const tokenParam: Token = {
    D: '',
    P: '',
    S: '',
    C: '',
    T: '',
  };
  const arrTKParams = tokenStr.split('&');
  if (arrTKParams.length > 0) {
    arrTKParams.map((item: string) => {
      const [key, val] = item.split('=');
      tokenParam[key] = val;
    });
  }

  return tokenParam;
}
export function getSiteType(tokenParam: Token): ESiteType {
  let result = ESiteType.NONE;
  if (tokenParam) {
    // @TODO: Need to get Site Type
    result = ESiteType.DESK;
  }
  return result;
}
export function getTrackingType(tokenParam: Token): ETrackingType {
  let result = ETrackingType.NONE;
  if (tokenParam) {
    // @TODO: Need to get Site Type
    result = ETrackingType.SITE;
  }
  return result;
}
export function extractUrlParams(): SiteParam {
  const queryParam = location.search.replace('?', '');
  const urlParams = new UrlParams(queryParam);
  // set param
  const id = urlParams.get('utm_id');
  const source = urlParams.get('utm_source');
  const tk = urlParams.get('tk');
  const tokenParams = tokenExtract(tk);
  let params: SiteParam = {
    id: id ?? '',
    source: source ?? '',
    token: tokenParams,
    tk: tk ?? '',
    siteType: getSiteType(tokenParams),
    trackingType: getTrackingType(tokenParams),
  };
  return params;
}
