import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';

interface ITagsViewProps {
  value: any[];
  menuSourceId: string;
  useDeleteTag: () => void;
}
const TagsView: React.FC<ITagsViewProps> = (props: ITagsViewProps) => {
  const { value, useDeleteTag, menuSourceId } = props;
  const [tags, setTags] = useState<any[]>([]);

  const callbackDeleteTag = (tagId: any) => {
    let nTags = tags?.filter((tag: any) => tag.id != tagId);
    setTags(nTags);
  };

  const mDeleteTag: any = useDeleteTag ? useDeleteTag() : null;
  const handleDeleteTag = (id: string, tagId: string) => {
    if (mDeleteTag) {
      mDeleteTag.mutate(
        { id, tagId },
        {
          onSuccess: (res: any) => {
            callbackDeleteTag(tagId);
          },
        },
      );
    }
  };
  useEffect(() => {
    if (value) {
      setTags(value);
    }
  }, [value]);
  return (
    <>
      {tags && tags.length
        ? tags.map((item, index) => {
          return (
            <div className="tag-item" key={index}>
              {item?.name ?? ''}
              <button
                type="button"
                className="btn-tag-remove"
                onClick={(ev: any) => {
                  handleDeleteTag(menuSourceId, item.id);
                  ev?.preventDefault && ev.preventDefault();
                  ev?.stopPropagation && ev.stopPropagation();
                }}
              >
                <X />
                <span className="sr-only">삭제</span>
              </button>
            </div>
          );
        })
        : ''}
    </>
  );
};

export default TagsView;
