import React, {useEffect, useState} from "react";
import {EAssignmentGroupType} from "@dashboard/report/types/enum";
import {RadioGroup} from "@base/components/form";
import UserGroupSelect from "@base/containers/user-group-select";
import {assignmentGroupTypeOptions} from "@dashboard/report/configs/options";

interface IAssignmentGroupProps {
  value: any;
  onChange?: (v: any) => void;
}

const AssignmentGroup: React.FC<IAssignmentGroupProps> = (props: IAssignmentGroupProps) => {
  const {onChange, value = {}} = props;

  const {
    assignmentGroupType: iAssignmentGroupType = EAssignmentGroupType.ASSIGNMENT_GROUP_TYPE_ALL,
    assignmentGroups: iAssignmentGroups = []
  } = value;

  const [assignmentGroupType, setAssignmentGroupType] = useState<string>(iAssignmentGroupType)
  const [assignmentGroups, setAssignmentGroups] = useState<any[]>(iAssignmentGroups)

  useEffect(() => {
    let oValue: any = {
      assignmentGroupType
    };
    if(assignmentGroupType === EAssignmentGroupType.ASSIGNMENT_GROUP_TYPE_SPECIFIC){
      oValue = {...oValue, assignmentGroups};
    }
    onChange && onChange(oValue);
  }, [assignmentGroupType, assignmentGroups])

  const radioProps = {
    options: assignmentGroupTypeOptions,
    fieldValue: 'value',
    fieldLabel: 'label',
    defaultValue: EAssignmentGroupType.ASSIGNMENT_GROUP_TYPE_ALL
  };
  const userGroupSelectProps = {
    value: assignmentGroups
  };

  return (
    <>
      <div className="tx-orange mg-b-5">
        The charts will be generated based on the selected group.
      </div>
      <div className="d-flex align-items-center flex-wrap">
        <RadioGroup {...radioProps} value={assignmentGroupType} onChange={(v: any) => setAssignmentGroupType(v.value)}/>
        {assignmentGroupType === EAssignmentGroupType.ASSIGNMENT_GROUP_TYPE_SPECIFIC &&
            <UserGroupSelect {...userGroupSelectProps} onChange={(v: any) => setAssignmentGroups(v)}/>}
      </div>
    </>
  );
}

export default AssignmentGroup;