import React from 'react';
import useTrack from '@site-page/hooks/useTrack';
import RawHTML from '@base/components/raw-html';
import { SitePage } from '@site-page/types/site';
import usePost from '@base/hooks/usePost';
import Loading from '@base/components/loading';
import NotFound from '@base/components/errors/notFound';

// http://localhost:3893/view?utm_id=xxxxxxx&utm_source=marketo&utm_medium=email&utm_campaign=idea_2022_2_newsletter&utm_content=newsletter&tk=RD0xJlA9MiZTPTMmQz00JlQ9NQ
const ViewContainer = () => {
  // console.log('siteView container');
  const [page, isLoading] = useTrack('site');
  if (!page) {
    return <NotFound />;
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="text-center">
          <RawHTML>{page?.content}</RawHTML>
        </div>
      )}
    </>
  );
};

export default ViewContainer;
