import {forEach} from "lodash";
import classNames from "classnames";
import {ChartWrapper} from "@dashboard/main/components/chart-wrapper";
import {VirtualChart} from "@dashboard/main/components/virtual-chart";
import React from "react";
import SectionEmpty from "../section-empty";
import {DisplayModeProps} from "@dashboard/report/types/interfaces/components";

const DisplayPortrait = (props: DisplayModeProps) => {
  const {
    sectionKeys = [],
    chartKeys = [],
    sectionConfigs = {},
    isDisplayGrid = false,
    isVirtualChart = false,
  } = props;

  let newSectionConfigs: any = [];
  if(!!sectionKeys?.length){
    forEach(sectionKeys, (sk) => {
      let {chartConfigs = {}, ...section} = sectionConfigs?.[sk] ?? {};
      const selectedChartConfigs: any = chartKeys.reduce((f: any, k) => {
        if (!!chartConfigs?.[k]) {
          f = [...f, chartConfigs[k]];
        }
        return f;
      }, []);
      newSectionConfigs = [...newSectionConfigs, {
        ...section,
        chartConfigs: selectedChartConfigs
      }];
    });
  }

  const howManySections: number = newSectionConfigs?.length ?? 0;
  if (!howManySections){
    return <SectionEmpty isWriteMode/>;
  }

  return (
    <div className="">
      {
        newSectionConfigs.map((s: any, i: number) => {
          const {
            chartConfigs: newChartConfigs = [],
            componentProps: sectionProps = {}
          } = s ?? {};

          const howManyCharts = newChartConfigs?.length ?? 0;

          return (
            <div className={classNames('card mg-10')} key={i}>
              <div className="card-header tx-semibold">{sectionProps?.title}</div>
              <div className="bg-light ht-100p">
                <div className="row mg-0">
                  {
                    !howManyCharts && <SectionEmpty isWriteMode/>
                  }
                  {
                    !!howManyCharts && newChartConfigs.map((c: any, ii: number) => {
                      const {
                        component,
                        componentProps,
                        wrapperProps,
                      } = c ?? {};

                      const Component = isVirtualChart ? VirtualChart : component;

                      const {className, ...wrapperRestProps} = wrapperProps;

                      return (
                        <ChartWrapper className={classNames('pd-5', {
                          'col-4 ht-500': isDisplayGrid,
                          'ht-400': isVirtualChart,
                          [className]: !isDisplayGrid
                        })} {...wrapperRestProps} key={ii}>
                          <div className="bg-white wd-100p ht-100p overflow-auto">
                            <Component {...componentProps}/>
                          </div>
                        </ChartWrapper>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default DisplayPortrait;