import {EChartType, EPermit, ESectionType} from "@dashboard/main/types/enum";

const chartConfigs: any = {
  [EChartType.CHART_CUSTOMER_TREND]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_CUSTOMER,
    title: {
      [EPermit.PERMIT_USER]: 'My Customer Trends',
      [EPermit.PERMIT_ADMIN]: 'Customer Trends'
    },
    wrapperProps: {
      className: "col-md-6 col-xl-2 col-lg-3 order-md-1 order-xl-0"
    }
  },
  [EChartType.CHART_CUSTOMER_PERIOD]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_CUSTOMER,
    title: 'Customer By Period',
    wrapperProps: {
      className: "col-md-6 col-xl-8 col-lg-6 order-md-1 order-xl-0",
    }
  },
  [EChartType.CHART_CUSTOMER_RATING]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_CUSTOMER,
    title: 'Customer Rating',
    wrapperProps: {
      className: "col-md-6 col-xl-6 order-md-1 order-xl-0",
    }
  },
  [EChartType.CHART_CUSTOMER_LAST_UPDATED]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_CUSTOMER,
    title: 'Customer Last Updated',
    wrapperProps: {
      className: "col-md-6 col-xl-2 col-lg-3 order-md-1 order-xl-0",
    }
  },
  [EChartType.CHART_CUSTOMER_INDUSTRY]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_CUSTOMER,
    title: 'Customer Industry',
    wrapperProps: {
      className: "col-md-6 col-xl-4 order-md-1 order-xl-0",
    }
  },

  [EChartType.CHART_ACTIVITY_TREND]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: {
      [EPermit.PERMIT_USER]: 'My Activity Trends',
      [EPermit.PERMIT_ADMIN]: 'Activity Trends'
    },
    wrapperProps: {
      className: "col-lg-12"
    },
  },
  [EChartType.CHART_ACTIVITY_TODO]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'My Todo',
    wrapperProps: {
      className: "col-lg-12"
    },
  },
  [EChartType.CHART_ACTIVITY_REP_PERFORMANCE]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: {
      [EPermit.PERMIT_USER]: 'My Group\'s Reps Performance',
      [EPermit.PERMIT_ADMIN]: 'Rep. Performance'
    },
    wrapperProps: {
      className: "col-lg-12"
    },
  },
  [EChartType.CHART_ACTIVITY_TYPE]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'My Activities',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_GROUP_TOTAL]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'My Group Total Activities',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_DURATION]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'My Duration',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_GROUP_DURATION]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'My Group Duration',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_GROUP_PERFORMANCE]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Group Performance',
    wrapperProps: {
      className: "col-lg-12"
    },
  },
  [EChartType.CHART_ACTIVITY_TOTAL_BY_GROUP]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Total Activities By Group',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_TOTAL_BY_REP]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Total Activities By Rep',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_CALL]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Top Performer by Call',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_TASK]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Top Performer by Task',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_EMAIL]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Top Performer by Email',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_SMS]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Top Performer by Sms',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_PURPOSE]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Activities By Purpose',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_BY_TYPE]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Activities By Type',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_OVERDUE_REP_CHART]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Overdue Activities By Rep',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_ACTIVITY_BY_PRIORITY_CHART]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_ACTIVITY,
    title: 'Activities By Priority',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },

  [EChartType.CHART_TICKET_TREND]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_DESK,
    title: {
      [EPermit.PERMIT_USER]: 'My Ticket Trends',
      [EPermit.PERMIT_ADMIN]: 'Ticket Trends',
    },
    wrapperProps: {
      className: "col-lg-12"
    },
  },
  [EChartType.CHART_TICKET_CUSTOMER_SATISFACTION]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Customer Satisfaction',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Performer by Resolve Time Efficiency',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_OVERVIEW]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_DESK,
    title: {
      [EPermit.PERMIT_USER]: 'My Tickets Overview',
      [EPermit.PERMIT_ADMIN]: 'Tickets Overview',
    },
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_PERFORMER_BY_RESOLVE_TIME]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Performer by Resolution Time',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_DESK,
    title: {
      [EPermit.PERMIT_USER]: 'My Group Performance',
      [EPermit.PERMIT_ADMIN]: 'Group Performance',
    },
    wrapperProps: {
      className: "col-12"
    },
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_ASSIGNED_TICKET]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Group Performer by Assigned Tickets',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVE_TIME_EFFICIENCY]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Group Performance by Resolve Time Efficiency',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVED_TICKET]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Group Performer by Resolved Tickets',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_UNRESOLVED_TICKET]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Group Performance by Unresolved Tickets',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLUTION_TIME]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Group Performance by Resolution Time',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_CUSTOMER_SATISFACTION]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Group Performance by Customer Satisfaction',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_PRIORITY]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Tickets by Priority',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_ISSUE]: {
    permit: EPermit.PERMIT_USER,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'My Top 10 Issues',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_KB_REP_PUBLISHED_ARTICLE]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_DESK,
    title: {
      [EPermit.PERMIT_USER]: 'My Group Performer by Published Articles',
      [EPermit.PERMIT_ADMIN]: 'Reps by Published Articles',
    },
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_KB_REP_INSERTED_ARTICLE]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_DESK,
    title: {
      [EPermit.PERMIT_USER]: 'My Group Performer by Inserted Articles',
      [EPermit.PERMIT_ADMIN]: 'Reps by Inserted Articles',
    },
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_BY_CATEGORY]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Tickets By Category',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMER]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Ticket Avg. Support Performance',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },

  [EChartType.CHART_TICKET_REP_PERFORMER]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Rep Performance',
    wrapperProps: {
      className: "col-lg-12"
    },
  },
  [EChartType.CHART_TICKET_AVG_SUPPORT_PERFORMER]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Ticket Avg. Support Performance',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    }
  },
  [EChartType.CHART_TICKET_AVG_CUSTOMER_SATISFACTION]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Avg. Customer Satisfaction',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_AVG_RESOLUTION_TIME]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Top Performer by Avg.Resolution Time',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_SATISFACTION_SCORE]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Top Performer By Satisfaction Score',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_AVG_SATISFACTION_SCORE]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Top Group by Avg. Satisfaction Score',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_RESOLVED_TICKET]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Top Performer by Resolved Tickets',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Top Performer by Resolve Time Efficiency',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_UNRESOLVED_TICKET]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Top Performer by Unresolved Tickets',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_REP_BY_AVG_RESOLUTION_TIME]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Reps by Avg. Resolution Time',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_REP_BY_RESOLVED_TICKET]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Reps by Resolved Tickets',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_REP_BY_RESOLVE_TIME_EFFICIENCY]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Reps by Resolve Time Efficiency',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_REP_BY_UNRESOLVED_TICKET]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Reps by Unresolved Tickets',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Top Group by Avg. Resolution Time',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME_EFFICIENCY]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Top Group by Avg. Resolve Time Efficiency',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLVED_TICKET]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Top Group by Resolved Tickets',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_UNRESOLVED_TICKET]: {
    permit: EPermit.PERMIT_ADMIN,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Top Group by Unresolved Tickets',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_KB_OVERVIEW]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'KB Overview',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
  [EChartType.CHART_KB_REP_VIEWED_ARTICLE]: {
    permit: EPermit.PERMIT_ALL,
    belongsTo: ESectionType.SECTION_DESK,
    title: 'Reps by Viewed Articles',
    wrapperProps: {
      className: "col-lg-6 col-md-12"
    },
  },
};

export default chartConfigs;