import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import Button from '@base/components/form/button';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import * as keyNames from '@desk/ticket/config/key-names';
import { finalizeParams } from './payload';
import InputCodeGenerator from '@base/containers/code-generator';
import { TRACKING_TICKET_CREATE } from '@site-page/services/graphql';
import { Input, TextArea } from '@base/components/form';
import ProductCategory from '@desk/ticket/containers/product-category';
import UploadFile from '@base/components/form/upload/file';
import { useUploadMutation } from '@base/hooks/useFileUploadMutation';
import { publicViewDataByMenuAtom } from '@site-page/recoil/view';
import { useRecoilValue } from 'recoil';
import { MENU_DESK_TICKET } from '@base/config/menus';
import { getFieldLayoutDataByKeyName } from '@base/utils/helpers/page-layout-utils';

interface WriteFormProps {
  fullScreen: boolean;
  token: string;
  onClose: () => void;
  onReload?: () => void;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm = (props: WriteFormProps) => {
  const { fullScreen, token, onClose, onReload } = props;
  //const { t } = useTranslation();
  //state
  //const [isReset, setIsReset] = useState(false);
  const [curFileIndex, setCurFileIndex] = useState<number>(-1);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState<number>(-1);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const viewData = useRecoilValue(publicViewDataByMenuAtom(MENU_DESK_TICKET)); //view data
  const customerField = getFieldLayoutDataByKeyName(viewData, keyNames.KEY_TICKET_CUSTOMER);
  //// console.log('customerField', customerField);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      [keyNames.KEY_TICKET_CODE]: '',
      [keyNames.KEY_TICKET_SUBJECT]: '',
      [keyNames.KEY_TICKET_CONTENT]: '',
      [keyNames.KEY_TICKET_CATEGORY]: null,
      [keyNames.KEY_TICKET_ATTACHMENT]: [],
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    TRACKING_TICKET_CREATE,
    'tracking_createTicket',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Created ticket successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during creating: ' + JSON.parse(error).message);
      },
    },
  );

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationAdd.isSuccess) {
      // if (isReset) {
      //   reset();
      // }
      // refecth data
      onReload && onReload();
      onClose && onClose();
    }
  }, [mutationAdd.isSuccess]);

  // S3 upload
  const mS3Upload: any = useUploadMutation<BaseMutationResponse>(
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //toast.success('Uploaded successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.error('There is error during uploading: ' + JSON.parse(error).message);
      },
    },
    // (pEvent: ProgressEvent, partsNumber: number, partIndex: number, uploadId?: string) =>
    //   uploadProgressHandler(pEvent, partsNumber, partIndex, uploadId),
  );

  //upload success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mUpload);
    if (mS3Upload.isSuccess) {
      const newUploadedFiles = [...uploadedFiles];
      const selectedFiles: any = getValues()[keyNames.KEY_TICKET_ATTACHMENT];
      const newUpload = {
        objectId: mS3Upload.data.id, //upload id
        objectUrl: mS3Upload.data.url, //download url
        name: selectedFiles[curFileIndex].name,
        size: selectedFiles[curFileIndex].size,
        contentType: selectedFiles[curFileIndex].type,
      };
      newUploadedFiles.push(newUpload);
      setUploadedFiles(newUploadedFiles);
      //next file uploading
      setLastUploadedFileIndex(curFileIndex);
    }
  }, [mS3Upload.isSuccess]);

  /** =============================== UPLOAD HANDLER =========================== */
  //upload current file
  useEffect(() => {
    //// console.log('curFileIndex', curFileIndex);
    const selectedFiles = getValues()[keyNames.KEY_TICKET_ATTACHMENT];
    if (curFileIndex !== -1) {
      mS3Upload.mutate(selectedFiles[curFileIndex]);
    }
  }, [curFileIndex]);

  //next file - last file
  useEffect(() => {
    if (lastUploadedFileIndex === -1) {
      return;
    }
    const formData = getValues();
    const selectedFiles = getValues()[keyNames.KEY_TICKET_ATTACHMENT];
    const isLastFile = lastUploadedFileIndex === selectedFiles.length - 1;
    if (isLastFile) {
      setCurFileIndex(-1);
      //start save to db
      const params: any = {
        ...finalizeParams(formData, customerField.data),
        attachedFiles: uploadedFiles,
      };
      mutationAdd.mutate({ ticket: params, token });
    } else {
      //next uploaded
      const nextFileIndex = curFileIndex + 1;
      setCurFileIndex(nextFileIndex);
    }
  }, [lastUploadedFileIndex]);

  /** =============================== END UPLOAD HANDLER =========================== */

  //submit form
  const onSubmit = (formData: any) => {
    //upload files
    const uploadFiles = formData[keyNames.KEY_TICKET_ATTACHMENT];
    if (uploadFiles.length > 0) {
      if (curFileIndex === -1) {
        setCurFileIndex(lastUploadedFileIndex === -1 ? 0 : lastUploadedFileIndex + 1);
      }
    } else {
      const params = finalizeParams(formData, customerField.data);
      mutationAdd.mutate({ ticket: params, token });
    }
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    // console.log('error', errors, e);
  };

  //new form fields
  const renderForm = () => {
    let formHeight = 'calc(100vh - 250px)';
    if (fullScreen) {
      formHeight = 'calc(100vh - 170px)';
    }
    return (
      <div
        className="pd-15 form-row scroll-box"
        style={{ maxHeight: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <div className="form-group col-12">
          <label className="form-item-title wd-150">
            Subject<span className="tx-danger">*</span>
          </label>
          <div className="flex-fill">
            <Controller
              name={keyNames.KEY_TICKET_SUBJECT}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }: any) => (
                <Input value={value} onChange={onChange} />
              )}
            />
          </div>
        </div>
        <div className="form-group col-12">
          <label className="form-item-title wd-150">
            Product<span className="tx-danger">*</span>
          </label>
          <div className="flex-fill">
            <Controller
              name={keyNames.KEY_TICKET_CATEGORY}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }: any) => (
                <ProductCategory
                  hideProductLabel={true}
                  isPublic={true}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="form-group col-12">
          <label className="form-item-title wd-150">
            Content<span className="tx-danger">*</span>
          </label>
          <div className="flex-fill">
            <Controller
              name={keyNames.KEY_TICKET_CONTENT}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }: any) => (
                <TextArea value={value} onChange={onChange} />
              )}
            />
          </div>
        </div>
        <div className="form-group col-12">
          <label className="form-item-title wd-150">Attachment</label>
          <div className="flex-fill">
            <Controller
              name={keyNames.KEY_TICKET_ATTACHMENT}
              control={control}
              render={({ field: { value, onChange } }: any) => (
                <UploadFile canDrop={true} multiple={true} value={value} onChange={onChange} />
              )}
            />
          </div>
        </div>
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                //setIsReset(false);
                handleSubmit((data) => onSubmit(data), onError)();
              }}
              disabled={mutationAdd.isLoading || !isValid}
              loading={mutationAdd.isLoading}
              name="Create"
            />
            {/* <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  setIsReset(true);
                  handleSubmit((data) => onSubmit(data), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                name="Save and Create New"
              />
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  //// console.log('form values', getValues()); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>is required field</span>
          </span>
        </div>
      </div>
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        {/* hidden code generator */}
        <div className="d-none">
          <Controller
            name={keyNames.KEY_TICKET_CODE}
            control={control}
            render={({ field: { value, onChange } }: any) => (
              <InputCodeGenerator
                isPublic={true}
                token={token}
                menu="ticket"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        {renderForm()}
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(WriteForm);
