import React from 'react';
import displayLayoutConfig from '@site-page/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { useRecoilValue } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms';
import ViewContainer from '@site-page/containers/view';
import { extractUrlParams } from '@site-page/utils/Params';
import { ESiteType, ETrackingType } from '@site-page/types/enum';
import DeskView from '@site-page/containers/desk/view';

const ViewPage = () => {
  const deviceState = useRecoilValue(deviceAtom);
  const siteParams = extractUrlParams();
  const pageProps = {
    header: <></>,
    body: (
      <>
        {siteParams.trackingType === ETrackingType.SITE &&
          siteParams.siteType === ESiteType.DESK && <DeskView siteParams={siteParams} />}
        {siteParams.trackingType !== ETrackingType.SITE && <ViewContainer />}
      </>
    ),
  };

  return (
    <BaseLayoutDisplay
      displayConfig={displayLayoutConfig}
      device={deviceState.device}
      layout={deviceState.layout}
      form="view"
      {...pageProps}
    />
  );
};

export default ViewPage;
