import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Loading from '@base/components/loading';

const DashboardPage = React.lazy(() => import('@dashboard/dashboard/pages/main'));
const ReportPage = React.lazy(() => import('@dashboard/report/pages/main'));

const FirstPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<Navigate replace to="/analytic/dashboard"/>}/>
        <Route path="/dashboard/*" element={
          <React.Suspense fallback={<Loading/>}>
            <DashboardPage/>
          </React.Suspense>
        }/>
        <Route path="/report/*" element={
          <React.Suspense fallback={<Loading/>}>
            <ReportPage/>
          </React.Suspense>
        }/>
      </Routes>
    </>
  );
};

export default FirstPage;
