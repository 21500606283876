import {BarChart} from "react-feather";
import React from "react";

interface SectionEmptyProps {
  isWriteMode?: boolean;
}

const SectionEmpty = (props: SectionEmptyProps) => {
  const { isWriteMode = false } = props;

  const message = isWriteMode ? 'Select chart(s) to create your report...' : 'There is nothing to display...';

  return (
    <div className="ht-100p d-flex flex-column align-items-center justify-content-center mg-x-auto">
      <div className="mg-b-10 pd-10 bd bd-dashed rounded tx-gray-400">
        <BarChart/>
      </div>
      {message}
    </div>
  )
};

export default SectionEmpty