import React from 'react';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import ListGridItemBase, {
  defaultColumnRender,
  ListGridItemHeader,
  ListGridItemBody,
} from '@base/components/list/list-grid/list-grid-item';
import {MENU_ANALYTIC} from '@base/config/menus';
import { default as viewConfig } from '@dashboard/report/configs/view-field';
import {REPORT_NAME} from "@dashboard/report/configs/key-names";

export interface IListGridItem {
  primaryKey: string | number;
  data: any;
  isSplitMode: boolean;
  onCheckedRow: (checkedValue: string | number) => void;
  selectedIds: string[];
  columns: {
    name: string;
    title: string;
  }[];
  columnRender?: (col: string) => any;
}

const ListGridItem: React.FC<IListGridItem> = (props: any) => {
  const {
    category,
    primaryKey,
    data,
    isSplitMode,
    onCheckedRow,
    selectedIds,
    columns,
    columnRender,
  } = props;

  const { t, i18n } = useTranslation();

  const getFirstCharacter = (label: string): string => {
    return label?.charAt?.(0) || '';
  };

  const url = `/${MENU_ANALYTIC}/${category}/${data.id}`;

  return (
    <ListGridItemBase
      primaryKey={primaryKey || 'id'}
      data={data}
      isSplitMode={isSplitMode}
      onCheckedRow={onCheckedRow}
      selectedIds={selectedIds}
    >
      <ListGridItemHeader
        id={data.id}
        title={data[REPORT_NAME]}
        titleLink={url}
        keyName={REPORT_NAME}
        menuSource={MENU_ANALYTIC}
        viewConfig={viewConfig}
      />
      <ListGridItemBody isSplitMode={isSplitMode}>
        <div className="grid-list-item-detail-body">
          {columns &&
            columns.map((col: any, i: number) => {
              const columnComponent =
                isFunction(columnRender) && columnRender(col.name)
                  ? columnRender(col.name)(col.name, data)
                  : defaultColumnRender(col.name, data);
              return (
                <div className="grid-list-item-col" key={i}>
                  <span className="grid-list-item-col-title">{t(col.title) + ':'}</span>
                  <span className="grid-list-item-col-content">{columnComponent}</span>
                </div>
              );
            })}
        </div>
      </ListGridItemBody>
    </ListGridItemBase>
  );
};

ListGridItem.defaultProps = {
  primaryKey: 'id',
};

export default ListGridItem;
