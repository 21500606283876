import * as components from './components';

export default {
  desktop: {
    layout1: {
      view: components.DesktopLayout1View,
    },
  },
  tablet: {
    layout1: {},
  },
  mobile: {
    layout1: {},
  },
};
