import React, { useEffect, useState } from 'react';
import { Button, SpanLang } from '@base/components/form';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { TRACKING_TICKET_CANCEL, TRACKING_TICKET_CLOSE } from '@site-page/services/graphql';
import { toast } from 'react-toastify';
import SurveyForm from '../survey-form';

interface ToolbarProps {
  ticketId: string;
  token: string;
  onOpenWrite?: () => void;
  onRefeshView?: () => void;
}

const Toolbar = (props: ToolbarProps) => {
  const { ticketId, token, onOpenWrite, onRefeshView } = props;
  //state
  const [isOpenSurvey, setIsOpenSurvey] = useState(false);

  //mutation
  const mClose: any = useMutationPost<BaseMutationResponse>(
    TRACKING_TICKET_CLOSE,
    'tracking_closeTicket',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Created ticket successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during creating: ' + JSON.parse(error).message);
      },
    },
  );

  const mCancel: any = useMutationPost<BaseMutationResponse>(
    TRACKING_TICKET_CANCEL,
    'tracking_cancleTicket',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Created ticket successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during creating: ' + JSON.parse(error).message);
      },
    },
  );

  //check success
  useEffect(() => {
    if (mClose.isSuccess) {
      // refecth data
      onRefeshView && onRefeshView();
    }
  }, [mClose.isSuccess]);

  useEffect(() => {
    if (mCancel.isSuccess) {
      // refecth data
      onRefeshView && onRefeshView();
    }
  }, [mCancel.isSuccess]);

  //close ticket
  const handleCloseTicket = () => {
    const params = {
      ids: [ticketId],
      token,
    };
    mClose.mutate(params);
  };

  //cancel ticket
  const handleCancelTicket = () => {
    const params = {
      ids: [ticketId],
      token,
    };
    mCancel.mutate(params);
  };

  return (
    <>
      <div className="d-flex align-items-center mg-l-auto">
        <Button
          color=""
          name="Survey"
          className="bg-orange tx-white rounded-30"
          onClick={() => setIsOpenSurvey(true)}
        />
        <Button
          color="info"
          name=" Close ticket"
          className="mg-l-5 rounded-30"
          loading={mClose.isLoading}
          onClick={handleCloseTicket}
        />
        <Button
          color="primary"
          name="New"
          icon="Plus"
          className="mg-l-5 rounded-30"
          onClick={onOpenWrite}
        />
        <div className="dropdown">
          <Button
            name="More"
            className="mg-l-5 rounded-30"
            icon="ChevronDown"
            isIconsEnd
            data-toggle="dropdown"
            loading={mCancel.isLoading}
          />
          <div className="dropdown-menu dropdown-menu-right">
            <Button
              className="dropdown-item"
              icon="X"
              iconClass="dropdown-item-icon"
              name="Cancel Ticket"
              onClick={handleCancelTicket}
            />
            <Button
              className="dropdown-item"
              icon="Printer"
              iconClass="dropdown-item-icon"
              name="Print"
            />
          </div>
        </div>
      </div>
      {isOpenSurvey && (
        <SurveyForm
          className="mn-wd-900"
          token={token}
          title={<SpanLang keyLang={'Do survey now'} />}
          showCanvas={true}
          isOpen={isOpenSurvey}
          onClose={() => setIsOpenSurvey(false)}
        />
      )}
    </>
  );
};

export default Toolbar;
