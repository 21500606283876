
export const routes: any[] = [];
export const publicRoutes: any[] = [];

/// separate private and public
// privateRoute.ts
// publicRoute.ts
const requireAppRoutes = require.context('.', true, /route.ts$/);

// requireAppRoutes.keys().forEach((path: any) => {
//   routes.push(requireAppRoutes(path).default);
// });

const publicAppRoutes = require.context('.', true, /publicRoute.ts$/);
publicAppRoutes.keys().forEach((path: any) => {
  publicRoutes.push(publicAppRoutes(path).default);
});