import React from "react";
import classNames from "classnames";
import {RefreshCw} from "react-feather";
import FilterQuery from "@dashboard/dashboard/components/filter-query";

export interface ToolbarProps {
  onRefetch: () => void;
}

const Toolbar = (props: ToolbarProps) => {
  const {onRefetch} = props ?? {};

  return <div className="d-flex tx-18">
    {
      onRefetch && <a className="link-03 lh-0 cursor-pointer" onClick={() => onRefetch()}><RefreshCw size={14}/></a>
    }
  </div>
}

interface ChartContainerProps {
  title: string;
  [x: string]: any;
}

const ChartContainer = (props: ChartContainerProps) => {
  const {
    title = 'No Title...',
    toolbarProps,
    bodyContentProps = {
      isScroll: false
    },
    children,
    filterProps
  } = props;

  const {
    isShow: allowFilterShow = true,
    ...restFilterProps
  } = {isDefaultThisMonth: true, ...filterProps};

  return (
    <div className="chart-container card ht-100p">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h6 className={classNames("mg-b-0", {"wd-60p text-overflow": toolbarProps})}>{title}</h6>
        {toolbarProps && <Toolbar {...toolbarProps} />}
      </div>
      <div className="card-body pd-0 d-flex flex-column">
        <div className="card-body-header">
          {allowFilterShow && <FilterQuery {...restFilterProps} />}
        </div>
        <div className={classNames("card-body-content flex-grow-1", {"overflow-y-auto": bodyContentProps?.isScroll})}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ChartContainer;