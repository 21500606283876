import React from 'react';
import { useGetView } from '@base/services/view';
import viewFieldsConfig from '@dashboard/report/configs/view-field';
import { REPORT_REPORTING_CONTENT } from '@dashboard/report/configs/key-names';
import ReportingContentView from '@dashboard/report/containers/reporting-content-view';
import Loading from '@base/components/loading';
import { MENU_CATEGORY_REPORT } from '@dashboard/main/configs/general';

interface ReportingContentPreviewProps {
  value: {
    id: string;
    [x: string]: any;
  };
}

const ReportingContentPreview: React.FC<ReportingContentPreviewProps> = (props: ReportingContentPreviewProps) => {
  const {value: {id}} = props;

  const schemaStr = viewFieldsConfig[REPORT_REPORTING_CONTENT]?.schema ?? '';

  const {
    data,
    isFetching
  } = useGetView<any>({menu: MENU_CATEGORY_REPORT, schemas: `
    dateRange
    ${schemaStr}
  `, params: {id, preview: true}});

  const reportingContentViewProps = {
    dateRange: data?.dateRange ?? '',
    value: data?.pages ?? []
  };

  return <>
    {isFetching && <Loading/>}
    {!isFetching && <ReportingContentView {...reportingContentViewProps}/>}
  </>;
}

export default ReportingContentPreview;
