import { useGetActivityPerformance } from '@dashboard/dashboard/services/activity';
import BarHorizontal from '@dashboard/dashboard/components/chart/bar-horizontal';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const ActivityTopPerformerByEmail = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetActivityPerformance(props?.filters ?? {});
  });

  const { results = [] } = data ?? {};

  let chartData: any = { l: [], s: [] };
  if (!!results) {
    chartData = results.reduce((f: any, v: any) => {
      f['l'].push(v?.user?.name ?? '-');
      f['s'].push(v?.counting?.email ?? 0);
      return f;
    }, chartData);
  }

  return <BarHorizontal categories={chartData?.l ?? []} series={chartData?.s ?? []} />;
};

export default withChartContainer(ActivityTopPerformerByEmail);
