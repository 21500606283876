import React, {useEffect, useRef, useState} from "react";
import {analyticGeneralValue} from "@dashboard/main/recoil/general-query";
import {getSectionConfigsBy} from "@dashboard/main/utils";
import {EDisplayGridType, EDisplayModeType} from "@dashboard/report/types/enum";
import styled from "@emotion/styled";
import {
  DisplayNormal,
  DisplayLandscape,
  DisplayPortrait
} from "@dashboard/report/components/display-mode";
import {pluck} from "@base/utils/helpers/array-utils";

interface ReportContentPageProps {
  data?: any;
}

const ReportContentPageContainer = styled.div``;

const ReportContentPage: React.FC<ReportContentPageProps> = (props: ReportContentPageProps) => {
  const {data} = props;

  const {userRole} = analyticGeneralValue();
  const sectionConfigs = getSectionConfigsBy(userRole) ?? {};

  const {
    displayMode,
    displayGrid,
    selectedSections,
    selectedCharts,
  } = data ?? {};

  const isLandscape = displayMode === EDisplayModeType.DISPLAY_MODE_LANDSCAPE;
  const isPortrait = displayMode === EDisplayModeType.DISPLAY_MODE_PORTRAIT;

  const ref: any = useRef<any>(null);
  const [parentProps, setParentProps] = useState({width: 0});
  useEffect(() => {
    if(ref?.current){
      setParentProps({...parentProps, width: $(ref?.current)?.width() ?? 0});
    }
  }, [ref?.current]);

  const isDisplayGrid = displayGrid === EDisplayGridType.DISPLAY_GRID_SHOW;

  const sectionKeys = pluck<any, string>(selectedSections, 'value');
  const chartKeys = pluck<any, string>(selectedCharts, 'value');

  const displayProps = {
    sectionKeys,
    chartKeys,
    sectionConfigs,
    isDisplayGrid,
    isVirtualChart: true
  }

  return (
    <ReportContentPageContainer ref={ref}>
      {/*<DisplayNormal {...displayProps}/>*/}
      {
        isPortrait && <DisplayPortrait {...displayProps}/>
      }
      {
        isLandscape && <DisplayLandscape {...{...displayProps, parentProps}}/>
      }
    </ReportContentPageContainer>
  );
};

export default ReportContentPage;