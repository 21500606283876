import React, { useEffect, useState } from 'react';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { deviceAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { useGetView } from '@base/services/view';
import { SpanLang } from '@base/components/form';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@dashboard/report/configs/display-layout';
import { useTranslation } from 'react-i18next';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { MENU_ANALYTIC } from '@base/config/menus';
import { useNavigate, useParams } from 'react-router-dom';
import Title from '@base/containers/view-field/title';
import { default as viewConfig } from '@dashboard/report/configs/view-field';
import { reportListFilterState, reportWriteState } from '@dashboard/report/recoil/atom';
import WritePage from '@dashboard/report/pages/write';
import { REPORT_NAME } from '@dashboard/report/configs/key-names';
import ViewCenter from '@dashboard/report/layouts/desktop/layout1/view/view-center';
import { allMenus, CATEGORY_REPORT, MENU_CATEGORY_REPORT } from '@dashboard/main/configs/general';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { ANALYTIC_COPYREPORT, ANALYTIC_DELETEREPORT } from '@dashboard/report/services/graphqls';

interface ViewPageProps {
  isSplitMode?: boolean;
}

const ViewPage: React.FC<ViewPageProps> = (props: ViewPageProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSplitMode } = props;
  const params = useParams();

  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const listFilter = useRecoilValue(reportListFilterState);
  const { activeId } = listFilter;
  let viewId = activeId != params.id ? params.id ?? '' : activeId ?? '';
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_CATEGORY_REPORT));
  const [writeOption, setWriteOption] = useRecoilState(reportWriteState);
  const keyTitle = `crm_new_${MENU_CATEGORY_REPORT}`;

  const mDelete: any = useMutationPost<BaseMutationResponse>(
    ANALYTIC_DELETEREPORT,
    'analytic_deleteReport',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        navigate(`/${MENU_ANALYTIC}/${CATEGORY_REPORT}`);
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Deleted failed: ' + JSON.parse(error).message);
      },
    },
  );

  const mCopy: any = useMutationPost<BaseMutationResponse>(
    ANALYTIC_COPYREPORT,
    'analytic_copyReport',
    {
      onSuccess: (res: any) => {
        toast.success('Data was copied...');
      },
    },
  );

  const { data: layoutView, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    MENU_CATEGORY_REPORT,
    'view',
  );
  let viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });

  const { data, isFetching: isDataLoading } = useGetView<any>({
    menu: MENU_CATEGORY_REPORT,
    schemas: viewSchema,
    params: { id: viewId },
  });

  useEffect(() => {
    if (!isLayoutLoading && !isDataLoading && data) {
      const layoutData = mergeLayoutData(layoutView, data, viewConfig);
      let newLayoutData: any = { ...layoutData };
      newLayoutData.menuSourceId = viewId;
      newLayoutData.menuSource = MENU_CATEGORY_REPORT;
      setViewData(newLayoutData);
    }
  }, [isLayoutLoading, isDataLoading, data]);

  const handleOnClone = () => {
    mCopy.mutate({ id: viewId });
  };

  const renderView = () => {
    const basicFields = viewData?.layout?.data?.[0]?.children || [];
    const nameField = basicFields?.find((_field: any) => _field?.keyName === REPORT_NAME);
    const pageTitle = nameField?.data || '';

    let moreActions: any[] = [];
    const buttonActionProps = {
      onDelete: () => mDelete.mutate({ ids: [viewId] }),
      isDeleting: mDelete.isLoading,
      onClone: handleOnClone,
      moreActions: moreActions,
      onNew: (category: string | undefined) => {
        setWriteOption((filter) => ({
          ...filter,
          isOpenWrite: true,
          writeType: '',
          writeMenu: 'view',
        }));
      },
    };

    let pageProps: IDestopLayout1ViewProps = {
      componentLeft: undefined,
      componentRight: undefined,
      isLoading: isDataLoading,
      headerProps: {
        splitViewMode: isSplitMode,
        menu: 'report',
        menus: allMenus,
        ...buttonActionProps,
        componentLeft: (
          <Title
            value={pageTitle}
            userPermission={{ isEdit: true }}
            keyName={REPORT_NAME}
            menuSourceId={viewId}
            menuSource={MENU_CATEGORY_REPORT}
            viewConfig={viewConfig}
          />
        ),
      },
      componentCenter: <ViewCenter category={MENU_CATEGORY_REPORT} id={viewId} />,
    };

    return (
      <>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="view"
          {...pageProps}
        />
        {writeOption.isOpenWrite && writeOption.writeMenu === 'view' && (
          <WritePage
            title={<SpanLang keyLang={keyTitle} />}
            size="lg"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            onReload={() => {}}
            menuApi={MENU_CATEGORY_REPORT}
            onGoView={(id: string) => {
              navigate(`/${MENU_ANALYTIC}/${CATEGORY_REPORT}/${id}`);
            }}
          />
        )}
      </>
    );
  };

  return <>{renderView()}</>;
};

export default ViewPage;
