import React from "react";
import CountBlock from "@dashboard/dashboard/components/count-block";
import {useGetActivityTrend} from "@dashboard/dashboard/services/activity";
import BlockBody from "@dashboard/dashboard/components/block-body";
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";

const CustomerSatisfactionAvgCustomerSatisfaction = (props: ChartComponentProps) => {
  const {data, isLoading, isFetching} = useGetActivityTrend(props.filters);

  const {firstCounting = {}, lastCounting = {}} = data ?? {};

  const labelOfOldValue = 'Last';

  return (
    <BlockBody className="d-flex bd-t overflow-x-auto" isLoading={isLoading} isFetching={isFetching}>
      <CountBlock
        className='bd-r'
        title='Highly Satisfied'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.total ?? 0}
        oldValue={lastCounting?.total ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Satisfied'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.overDue ?? 0}
        oldValue={lastCounting?.overDue ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Neutral'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.dueToday ?? 0}
        oldValue={lastCounting?.dueToday ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Dissatisfied'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.email ?? 0}
        oldValue={lastCounting?.email ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Highly Dissatisfied'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.call ?? 0}
        oldValue={lastCounting?.call ?? 0}
      />
    </BlockBody>
  );
}

export default withChartContainer(CustomerSatisfactionAvgCustomerSatisfaction);