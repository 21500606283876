import DateRangePicKer from "@base/components/date-range-picker";
import {Select} from "@base/components/form";
import {useEffect, useMemo, useRef, useState} from "react";
import dayjs from "dayjs";
import {dateRangeIncludeCustomOptions} from "@base/config/options";
import {keyValueToArray} from "@dashboard/main/utils";
import {EDateRangeType} from "@base/types/enums";
import {getDateRangeStringFromKey} from "@base/utils/helpers";

var quarterOfYear = require('dayjs/plugin/quarterOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)

interface IDateRangeFilterProps {
  onChange?: (startTime: string, endTime: string) => void;
  noWrap?: boolean;
  noPeriod?: boolean;
  defaultDateRangeOptionKey?: string;
  selectProps?: any;
}

const DateRangeFilter: React.FC<IDateRangeFilterProps> = (props: IDateRangeFilterProps) => {
  const {
    onChange,
    noWrap = false,
    noPeriod = false,
    defaultDateRangeOptionKey = EDateRangeType.DATE_RANGE_THIS_MONTH,
    selectProps = {}
  } = props;

  const dateRangeOptions = keyValueToArray(dateRangeIncludeCustomOptions);

  const selectOptions = selectProps?.options ?? dateRangeOptions.filter((v) => {
    return !(noPeriod && v.value === EDateRangeType.DATE_RANGE_CUSTOM);
  });

  const [range, setRange] = useState<any>({})

  const rangeRef = useRef<any>(null);

  useEffect(() => {
    if(!rangeRef.current){
      const initRange = getDateRangeStringFromKey(defaultDateRangeOptionKey);
      setRange(initRange);
      rangeRef.current = initRange
    }
  }, [])

  const defaultSelectedValue = selectOptions.filter((v: any) => v.value === defaultDateRangeOptionKey)

  const [selected, setSelected] = useState<any>(defaultSelectedValue[0])

  const handleDateRangeOnChange = (range: any) => {
    if (!!onChange && !!range) {
      const {startDate, endDate} = range;
      onChange && onChange(
        dayjs(startDate).utc().startOf('day').toISOString(),
        dayjs(endDate).utc().endOf('day').toISOString()
      )
    }
  }

  const handleOptionChange = (opt: any) => {
    if (opt?.value && opt?.value !== EDateRangeType.DATE_RANGE_CUSTOM) {
      const {startDate, endDate} = getDateRangeStringFromKey(opt.value, {asDayJs: true});

      setRange({
        startDate,
        endDate
      });

      onChange && onChange(
        startDate.toISOString(),
        endDate.toISOString()
      )
    }
    setSelected(opt)
  }

  const Control = useMemo(() => {
    return () => {
      return (
        <>
          {
            !noPeriod && <DateRangePicKer
                  className="wd-400 ht-40"
                  value={range}
                  onChange={handleDateRangeOnChange}
                  disabled={selected?.value !== EDateRangeType.DATE_RANGE_CUSTOM}
                  willConvertLocalTime={false}
                  isUtc={true}
              />
          }
          <Select
            className="mg-l-5 wd-250-f"
            ctrlStyles={{height: '40px'}}
            options={selectOptions}
            value={selected}
            onChange={handleOptionChange}
          />
        </>
      );
    }
  }, [range, selected]);

  return (
    noWrap ? <Control/> : <div className="d-flex pd-10 w-50">
      <Control/>
    </div>
  );
};

export default DateRangeFilter;

export const DateRangeFilter2 = (props: any) => {
  const {onChange, ...restProps} = props;

  return <DateRangeFilter {...restProps} onChange={(s, e) => onChange && onChange({s, e})}/>
};