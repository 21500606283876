import {EChartType} from "@dashboard/main/types/enum";
import {chartComponents} from "@dashboard/dashboard/config/chart";

export const userConfigs = {
  [EChartType.CHART_CUSTOMER_SATISFACTION_AVG_CUSTOMER_SATISFACTION]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_SATISFACTION_AVG_CUSTOMER_SATISFACTION],
    key: EChartType.CHART_CUSTOMER_SATISFACTION_AVG_CUSTOMER_SATISFACTION,
    componentProps: {
      title: "Avg. Customer Satisfaction",
      me: true,
      filterProps: {
        isDateNoPeriod: true,
        isDefaultThisMonth: true
      }
    },
    wrapperProps: {
      gridCol: 12,
      className: "col-lg-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_1]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_1],
    key: EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_1,
    componentProps: {
      title: "Question 1",
      me: true
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-lg-6 col-12 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_2]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_2],
    key: EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_2,
    componentProps: {
      title: "Question 2",
      me: true
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-lg-6 col-12 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  }
};

export const adminConfigs = {
  [EChartType.CHART_CUSTOMER_SATISFACTION_AVG_CUSTOMER_SATISFACTION]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_SATISFACTION_AVG_CUSTOMER_SATISFACTION],
    key: EChartType.CHART_CUSTOMER_SATISFACTION_AVG_CUSTOMER_SATISFACTION,
    componentProps: {
      title: "Avg. Customer Satisfaction",
      isDateNoPeriod: true,
      isDefaultThisMonth: true
    },
    wrapperProps: {
      gridCol: 12,
      className: "col-12"
    }
  },
  [EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_1]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_1],
    key: EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_1,
    componentProps: {
      title: "Question 1",
      filterProps: {
        isDateGroup: true
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-lg-6 col-12 ht-500",
    }
  },
  [EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_2]: {
    ...chartComponents[EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_2],
    key: EChartType.CHART_CUSTOMER_SATISFACTION_QUESTION_2,
    componentProps: {
      title: "Question 2",
      filterProps: {
        isDateGroup: true
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-lg-6 col-12 ht-500",
    }
  }
};