import React from 'react';
import {Avatar} from "@base/components";

interface IViewProps {
  value: any;
}
const View: React.FC<IViewProps> = (props: IViewProps) => {
  const { value: values = [] } = props;
  return <div className="d-flex flex-wrap align-items-center">
    {
      values && values?.map((v: any, i: number) => {
        const name = v?.name ?? v?.fullName ?? '-No Name-';
        return (
          <div className="media align-items-center pd-x-10 pd-y-5 bg-white bd rounded-30 mg-r-10" key={i}>
            <div className="avatar avatar-xs">
              <Avatar
                key={v?.id ?? ''}
                url={v?.url ?? ''}
                alt={name}
                height={26}
                width={26}
              />
            </div>
            <div className="media-body mg-l-10">
              <span className="user-name">{name}</span>
            </div>
          </div>
        );
      })
    }
  </div>;
};

export default View;
