import React, {useEffect} from 'react';
import BarSimple from '@dashboard/dashboard/components/chart/bar-simple';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useGetKbRepCounting} from "@dashboard/dashboard/services/desk";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const CustomerSatisfactionQuestion2 = (props: ChartComponentProps) => {
  const { filters = {}, setToolbarProps, ...remainProps } = props;

  const {data, refetch} = useChartQuery(remainProps, () => {
    return useGetKbRepCounting(filters, 'viewed')
  });

  useEffect(() => {
    setToolbarProps && setToolbarProps({
      onRefetch: refetch
    });
  }, []);

  const { results } = data ?? {};

  let chartData: any = { l: [
      ['John', 'Doe'],
      ['Joe', 'Smith'],
      ['Jake', 'Williams'],
      'Amber',
      ['Peter', 'Brown'],
      ['Mary', 'Evans'],
      ['David', 'Wilson'],
      ['Lily', 'Roberts']
    ], s: [21, 22, 10, 28, 16, 21, 13, 30] };

  if (!!results && !!results?.length) {
    chartData = results?.reduce((f: any, v: any) => {
      f['l'].push(v?.user?.name ?? '--No Name--');
      f['s'].push(v?.counting?.viewed ?? 0);
      return f;
    }, chartData);
  }

  return <BarSimple series={chartData.s} categories={chartData.l} />;
}

export default withChartContainer(CustomerSatisfactionQuestion2);
