import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
//import { useRecoilState } from 'recoil';
//import { deviceAtom } from '@base/recoil/atoms';
import { DESC } from '@base/config/constant';
import { useTrackingPageLayoutByMenu } from '@site-page/hooks/useTrackingPagelayout';
import { default as configFields } from '@site-page/config/desk/view-field';
import { buildListSchema } from '@base/utils/helpers/schema';
import { useTrackingTickets } from '@site-page/services/service';
import Loading from '@base/components/loading';
import { ListContainer } from '@base/components/list';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';
import { DATE_BY_OPTIONS, SEARCH_FIELD_OPTIONS } from '@site-page/config/desk/contants';
import WriteForm from '@site-page/containers/desk/write-ticket-form';
import Body from './body';
import Header from './header';

interface TicketsProps {
  className?: string;
  token?: string;
  customer?: any;
  isOpenWrite?: boolean;
  onClose?: () => void;
  onDocChange?: (id: string) => void;
}

const CustomerTickets = (props: TicketsProps) => {
  const { className, token = '', customer, isOpenWrite, onClose, onDocChange } = props;
  //state
  const layoutMenu = `desk_ticket`; //for list
  //const [{ isDesktop, device, layout }] = useRecoilState(deviceAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [viewingFields, setViewingFields] = useState<any>([]);
  const [listFilter, setListFilter] = useState<any>({
    paging: {
      page: 1,
      size: 15,
      totalPage: 1,
    },
    sort: {
      field: 'createdAt',
      orderBy: DESC,
    },
    query: `customer=\"${customer?.id || ''}\"`,
    keyword: '',
    headerFilters: {
      groupBy: 'all',
    },
  });

  /*===================================== HOOK ===================================== */
  //get pagelayout
  let { data: listLayoutData, isLoading: isLayoutLoading } = useTrackingPageLayoutByMenu(
    layoutMenu,
    'list',
    token,
  );
  //// console.log('listLayoutData', listLayoutData);
  let listQuerySchema =
    listLayoutData?.data && viewingFields.length > 0
      ? buildListSchema({ fields: viewingFields, configFields }) + ' product { id, name }'
      : '';
  let filtersQuery = {
    sort: listFilter.sort,
    paging: { page: listFilter.paging.page, size: listFilter.paging.size },
    query:
      `customer=\"${customer?.id || ''}\"` +
      ' ' +
      ParseFiltersToQuery(listFilter, DATE_BY_OPTIONS, SEARCH_FIELD_OPTIONS),
  };
  //// console.log('filtersQuery', filtersQuery);
  const {
    data: postResult,
    isFetching,
    refetch,
  } = useTrackingTickets(filtersQuery, listQuerySchema, token);
  //// console.log('postResult', postResult);

  //set viewing columns
  useEffect(() => {
    if (listLayoutData?.data) {
      const newItems: any[] = [];
      listLayoutData?.data.map((_ele: any) => {
        if (_ele.isDefault) {
          newItems.push({ ..._ele, isViewing: _ele.isDefault });
        }
      });
      setViewingFields(newItems);
    }
  }, [listLayoutData]);

  //body
  const BodyMemo = useMemo(() => {
    return (
      <Body
        category="ticket"
        token={token}
        fields={viewingFields || []}
        itemsList={postResult?.data || []}
        onIdChange={onDocChange}
        filter={listFilter}
        setFilter={setListFilter}
      />
    );
  }, [postResult, viewingFields, listFilter]);

  return (
    <div className={classNames('pos-relative', className)}>
      <div className="tab-content bg-white bd bd-gray-200 bd-t-0">
        <ListContainer>
          {isFetching && <Loading />}
          <Header
            filterData={listFilter.headerFilters}
            onFilterChange={(newHFilters: any) => {
              let newFilter = {
                ...listFilter,
                headerFilters: newHFilters,
              };
              setListFilter(newFilter);
            }}
            onRefresh={() => {
              let newFilter = {
                ...listFilter,
                headerFilters: { groupBy: 'all' },
              };
              setListFilter(newFilter);
              //get list
              refetch();
            }}
            onSearch={(searchText: string) => {
              let newFilter = {
                ...listFilter,
                keyword: searchText,
              };
              setListFilter(newFilter);
            }}
          />
          {BodyMemo}
        </ListContainer>
      </div>
      {/* hook form - write new write */}
      {isOpenWrite && (
        <WriteForm
          title={'New Ticket'}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={isOpenWrite}
          onClose={onClose}
          //customer={customer}
          token={token}
          onReload={refetch}
        />
      )}
    </div>
  );
};

export default CustomerTickets;
