import React, { lazy, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import HanGroupButton from '@base/components/han-group-button';
import _, { cloneDeep, isDate, isObject } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import VIEW_FIELD_API_CONFIG from '@base/config/view-fied-api';
import { useRecoilState } from 'recoil';
import { listDataByMenuAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { getUpdateCommonViewField } from '@base/services/graphql/pagelayout';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { Button } from '@base/components/form';
import { ViewContainer, ViewEditContainer } from './style';

const View = lazy(() => import('@dashboard/report/containers/reporting-content-view'));
const Edit = lazy(() => import('@dashboard/report/containers/reporting-content'));

interface ReportingContentProps {
  value: any;
  onSave: (params: any) => void;
  keyName: string;
  menuSourceId: string;
  menuSource: string;

  [x: string]: any;
}

const ReportingContent: React.FC<ReportingContentProps> = (props: ReportingContentProps) => {
  const {
    onSave,
    value,
    menuSourceId,
    menuSource,
    keyName,
    config,
    isHorizontal = false,
    onClose,
    onDelete,
    onRefetch,
    ...remainProps
  } = props;

  const viewFieldAPI = VIEW_FIELD_API_CONFIG[menuSource];

  const [isEdit, setIsEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [changedValue, setChangedValue] = useState(value);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      [keyName]: config?.getValueEdit ? config.getValueEdit(value) : value,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(menuSource));
  const [listData, setListData] = useRecoilState(listDataByMenuAtom(menuSource));

  const queryClient = useQueryClient();
  const mutationKey = viewFieldAPI?.mutationKey;
  const variableKey = viewFieldAPI?.variableKey;
  const mutationString = getUpdateCommonViewField(mutationKey, variableKey, config?.sectionId);
  const mUpdate: any = useMutationPost<BaseMutationResponse>(mutationString, mutationKey, {
    onMutate: () => {
      setIsSaving(true);
    },
    onSuccess: (data: any) => {
      const currentValue = getValues(keyName);
      const changedFormValue = config?.getChangedValue
        ? config.getChangedValue(currentValue)
        : currentValue;
      setChangedValue(changedFormValue);
      setValue(
        keyName,
        config?.getValueEdit ? config.getValueEdit(changedFormValue) : changedFormValue,
      );
      if (config?.getRecoilStateValue) {
        const newStateValue = config.getRecoilStateValue(currentValue);
        if (viewData?.layout) {
          const newViewData = cloneDeep(viewData);
          const keyIndex = newViewData.layout.data[0].children.findIndex(
            (_ele: any) => _ele.keyName === keyName,
          );
          if (keyIndex > -1) {
            newViewData.layout.data[0].children[keyIndex].data = newStateValue;
            setViewData(newViewData);
          }
        }
        if (listData) {
          const newListData = _.cloneDeep(listData);
          const itemIndex = newListData.findIndex((_item: any) => _item.id === menuSourceId);
          if (itemIndex > -1) {
            newListData[itemIndex][keyName] = newStateValue;
            setListData(newListData);
          }
        }
      }
      setIsSaving(false);
      if (config?.refetchQueryKey) {
        const refetchQuery = config.refetchQueryKey;
        setTimeout(() => {
          if (refetchQuery.length > 0) {
            for (const queryKey of refetchQuery) {
              queryClient.invalidateQueries(queryKey as any);
            }
          } else {
            queryClient.invalidateQueries(config.refetchQueryKey);
          }
        }, 500);
      }
      toast.success('Updated successfully!');
      onSave && onSave(currentValue);
      handleOnClose();
    },
    onError: (error: any) => {
      // console.log('Updated failed error: ', error);
      toast.error('Updated failed');
      setIsSaving(false);
      handleOnClose(true);
    },
  });

  //check success
  useEffect(() => {
    if (mUpdate.isSuccess) {
      onRefetch && onRefetch();
    }
  }, [mUpdate.isSuccess]);

  const onSubmit = (formData: any) => {
    const currentValue = getValues(keyName);
    const oldValue = config?.getValueEdit ? config.getValueEdit(changedValue) : changedValue;
    if (JSON.stringify(currentValue) == JSON.stringify(oldValue)) {
      handleOnClose();
      return;
    }
    let mutationValue = config?.getMutationValue
      ? config.getMutationValue(currentValue, viewData)
      : { [keyName]: currentValue };
    let updateData: any = {};
    let extraMutationParams = config?.getExtraMutationParam
      ? config.getExtraMutationParam(config.viewData)
      : {};
    if (isObject(mutationValue) && !isDate(mutationValue)) {
      updateData[variableKey] = {
        ...mutationValue,
        ...extraMutationParams,
      };
    } else {
      updateData[variableKey] = {
        [keyName]: mutationValue,
        ...extraMutationParams,
      };
    }
    if (config?.sectionId) {
      updateData[variableKey].id = config.sectionId;
      updateData.id = menuSourceId;
    } else {
      updateData[variableKey].id = menuSourceId;
    }
    if (config?.onSave) {
      config.onSave(updateData?.[variableKey]);
    } else {
      mUpdate.mutate(updateData);
    }
  };

  const handleOnClose = (flag = false) => {
    setIsEdit(!isEdit);
    if (flag) {
      setValue(keyName, config?.getValueEdit ? config?.getValueEdit(value) : value);
    }
    onClose && onClose();
  };

  useEffect(() => {
    if (value && changedValue != value) {
      setChangedValue(value);
      const newValue = config?.getValueEdit ? config?.getValueEdit(value) : value;
      setValue(keyName, newValue);
    }
  }, [value]);

  return (
    <div>
      {isEdit ? (
        <ViewEditContainer>
          <form
            key={keyName}
            onSubmit={handleSubmit(onSubmit)}
            className={classNames('form wd-100p component-edit align-items-center', {
              'd-flex': isHorizontal,
            })}
          >
            <Controller
              name={keyName}
              control={control}
              rules={{
                validate: config?.validate,
              }}
              render={({ field: { value, onChange } }: any) => {
                let additionalProps = config?.getPropsEdit ? config?.getPropsEdit(viewData) : {};
                return (
                  <React.Suspense fallback={<></>}>
                    <Edit
                      componentProps={{ ...config?.componentProps, ...additionalProps }}
                      value={value}
                      onChange={onChange}
                    />
                  </React.Suspense>
                );
              }}
            />
            {Object.keys(errors).length > 0 && errors?.[keyName] && (
              <p className="tx-danger tx-12">{errors?.[keyName]?.message}</p>
            )}
            <HanGroupButton
              className={classNames({
                'mg-l-5': isHorizontal,
                'mg-t-5': !isHorizontal,
              })}
              onClose={() => handleOnClose && handleOnClose(true)}
              onSave={() => {
                handleSubmit((data) =>
                  setTimeout(function () {
                    onSubmit(data);
                  }, 50),
                )();
              }}
              onDelete={onDelete}
              isValid={!isValid}
              isSaving={isSaving}
            />
          </form>
        </ViewEditContainer>
      ) : (
        <ViewContainer>
          <Button
            color="link"
            icon="Edit2"
            iconClass="mg-r-5"
            name="Edit"
            className="mg-l-auto btn-edit"
            onClick={() => setIsEdit(true)}
          />
          <View value={config?.getValueView ? config?.getValueView(changedValue) : changedValue} />
        </ViewContainer>
      )}
    </div>
  );
};

export default ReportingContent;
