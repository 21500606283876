import React, {useEffect, useState} from 'react';
import { TableNothing } from '@base/components/list/list-loading';
import ListReactTable from '@base/components/list/list-react-table';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';
import {useGetActivityPerformance} from '@dashboard/dashboard/services/activity';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const ActivityRepPerformance = (props: ChartComponentProps) => {
  const { filters, ...restProps } = props;

  const [listFilter, setListFilter] = useState<any>({
    filter: {
      ...(filters?.filter ?? {}),
    }
  });

  useEffect(() => {
    setListFilter({
      filter: {
        ...listFilter.filter,
        ...(filters?.filter ?? {}),
      }
    });
  }, [filters]);

  const {data, isLoading, isFetching} = useChartQuery(restProps, () => {
    return useGetActivityPerformance(listFilter ?? {});
  });

  const setSort = (sort: any) => {
    let newFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        sort: sort,
      },
    };
    setListFilter(newFilter);
  };

  const fields = [
    {
      keyName: 'repName',
      languageKey: 'Rep. Name',
      canSort: true,
    },
    {
      keyName: 'total',
      languageKey: 'Total',
    },
    {
      keyName: 'email',
      languageKey: 'Email',
    },
    {
      keyName: 'call',
      languageKey: 'Call',
    },
    {
      keyName: 'task',
      languageKey: 'Task',
    },
    {
      keyName: 'sms',
      languageKey: 'Sms',
    },
    {
      keyName: 'avgDuration',
      languageKey: 'Duration',
    }
  ];

  const getMapColumns = () => {
    return {
      repName(col: string, data: any) {
        return data[col] || '';
      },
      total(col: string, data: any) {
        return data[col] || 0;
      },
      email(col: string, data: any) {
        return data[col] || 0;
      },
      call(col: string, data: any) {
        return data[col] || 0;
      },
      task(col: string, data: any) {
        return data[col] || 0;
      },
      sms(col: string, data: any) {
        return data[col] || 0;
      },
      avgDuration(col: string, data: any) {
        return data[col] || 0;
      }
    };
  };

  const { results = [] } = data ?? {};

  const tableProps = {
    nodata: <TableNothing />,
    data:
      results?.map((v: any) => {
        return {
          ...(v?.counting ?? {}),
          repName: v?.user?.name ?? '',
        };
      }) ?? [],
    loading: isLoading || isFetching,
    columns: makeTableColumns(fields, getMapColumns(), {}, []),
    initialState: {
      pageSize: 0,
      pageIndex: 1,
      selectedIds: [],
    },
    onCheckedRow: () => null,
    onSortBy: (clName: any, isSortedDesc: boolean) => {
      let orderBy = isSortedDesc ? 1 : 2;
      setSort({ field: clName, orderBy: orderBy });
    },
    isCheckboxTable: false,
    className: 'rounded-0 bd-l-0 bd-r-0 bd-b-0',
  };

  return (
    <div className="list-wrap">
      <div className="list-body pd-0-f">
        <ListReactTable {...tableProps} />
      </div>
    </div>
  );
};

export default withChartContainer(ActivityRepPerformance);
