import classNames from "classnames";
import {BarChart2} from "react-feather";
import React from "react";

export const VirtualChart = (props: any) => {
  const {title, className = ''} = props;
  return (
    <div className={classNames("card d-flex flex-column justify-content-center han-tooltip--top", className)} data-han-tooltip={title}>
      <div className="card-header d-flex align-items-center justify-content-between">
        <h6 className="text-overflow mg-b-0">{title}</h6>
      </div>
      <div className="card-body d-flex justify-content-center align-items-center">
        <BarChart2 size={50} className="tx-gray-500"/>
      </div>
    </div>
  );
}