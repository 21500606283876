import './i18n-init';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery';

import QueryErrorBoundary from '@base/components/errors/ErrorBoundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';

import App from './app';

// Globally
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      refetchOnWindowFocus: false,
      retry: 0,
      refetchOnMount: true,
      refetchOnReconnect: true,
      useErrorBoundary: true,
    },
    mutations: {
      useErrorBoundary: true,
    }
  },
  // queryCache: new QueryCache({
  //   onError: (error: any, query) => {
  //     // console.log('AAAAAA', error, query);
  //     let errMsg =
  //       typeof error === 'string'
  //         ? error
  //         : error.length > 0 && error[0].message
  //         ? error[0].message
  //         : 'Something went wrong!';
  //     toast.error(errMsg);
  //   },
  // }),
  // mutationCache: new MutationCache({
  //   onError: (error: any) => {
  //     // console.log(error);
  //     let errMsg =
  //       typeof error === 'string'
  //         ? error
  //         : error.length > 0 && error[0].message
  //         ? error[0].message
  //         : 'Something went wrong!';
  //     toast.error(errMsg);
  //   },
  // }),
});

//react18
const rootElement = document.getElementById('crm-root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);
root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <Helmet>
      <title>Hanbiro CRM</title>
    </Helmet>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <QueryErrorBoundary>
        <App />
        </QueryErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </RecoilRoot>
  </HelmetProvider>,
  // </React.StrictMode>
);

//react17
// ReactDOM.render(
//   <React.StrictMode>
//     <HelmetProvider>
//       <Helmet>
//         <title>Hanbiro CRM</title>
//       </Helmet>
//       <RecoilRoot>
//         <QueryClientProvider client={queryClient}>
//           <App />
//           <ReactQueryDevtools initialIsOpen={false} />
//         </QueryClientProvider>
//       </RecoilRoot>
//     </HelmetProvider>
//   </React.StrictMode>,
//   document.getElementById('crm-root'),
// );
