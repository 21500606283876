import React from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Edit2 } from 'react-feather';
import { Timeline } from '@base/types/interfaces/timeline';

interface ItemProps {
  data: Timeline;
}

const Item = ({ data }: ItemProps) => {
  const createTpl = _.template('<%= to%> Created.');
  const updateTpl = _.template('<%= field%> was updated from <%= from%> to <%= to%>.');
  const deleteTpl = _.template('<%= to%> deleted');

  return (
    <>
      <div className="new-timeline-item">
        <div className="new-timeline-icon">
          <Edit2 />
        </div>
        <div className="new-timeline-body">
          <div className="card pd-15">
            <div className="new-timeline-body-header">
              <span>
                {data.content?.map((item, index) => {
                  const [from, to] = item.value.split('|->|');
                  const [fromId, fromName] = from.split('|<->|');
                  const [toId, toName] = to.split('|<->|');
                  const tplData = {
                    field: item.field,
                    from: fromName ? fromName : from,
                    to: toName ? toName : to,
                  };
                  let returnTpl;
                  if (data.action == 'created') {
                    returnTpl = createTpl(tplData);
                  } else if (data.action == 'updated') {
                    returnTpl = updateTpl(tplData);
                  } else {
                    returnTpl = deleteTpl(tplData);
                  }
                  return (
                    <div className="p-1" key={`content-${index}`}>
                      {/* {item.field} was updated <b>from</b> {from} <b>to</b> {to}. */}
                      {returnTpl}
                    </div>
                  );
                })}
              </span>
              <span className="mg-l-auto tx-color-03">
                By {data.createdBy?.name}, {dayjs(data.createdAt).format('HH:mm')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
