import React, {useEffect, useState} from "react";
import {TableNothing} from "@base/components/list/list-loading";
import ListReactTable from "@base/components/list/list-react-table";
import {makeTableColumns} from "@base/components/utils/helpers/react-table";
import Loading from "@base/components/loading";
import {useGetTicketPerformance} from "@dashboard/dashboard/services/desk";
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const TicketRepPerformance = (props: ChartComponentProps) => {
  const {filters, ...restProps} = props;

  const [listFilter, setListFilter] = useState<any>(filters);

  useEffect(() => {
    setListFilter({
      filter: {
        ...listFilter.filter,
        ...(filters?.filter ?? {}),
      }
    });
  }, [filters]);

  const {data, isLoading, isFetching} = useChartQuery(restProps, () => {
    return useGetTicketPerformance(filters ?? {});
  });

  const setSort = (sort: any) => {
    let newFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        sort: sort,
      },
    };
    setListFilter(newFilter);
  };

  const fields = [
    {
      keyName: 'repName',
      languageKey: "Rep. Name",
      canSort: true,
    },
    {
      keyName: 'assigned',
      languageKey: "Assigned",
    },
    {
      keyName: 'resolved',
      languageKey: "Resolved",
    },
    {
      keyName: 'unresolved',
      languageKey: "Unresolved",
    },
    {
      keyName: 'avg1stResponseTimeString',
      languageKey: "Avg. 1st Response",
    },
    {
      keyName: 'avgResolutionTimeString',
      languageKey: "Avg. Resolution Time",
    },
    {
      keyName: 'avgResolveTimeEfficiencyString',
      languageKey: "Avg. Resolve Time Efficiency",
    },
  ];

  const getMapColumns = () => {
    return {
      repName(col: string, data: any) {
        return data[col] || '';
      },
      assigned(col: string, data: any) {
        return data[col] || 0;
      },
      resolved(col: string, data: any) {
        return data[col] || 0;
      },
      unresolved(col: string, data: any) {
        return data[col] || 0;
      },
      avg1stResponseTimeString(col: string, data: any) {
        return data[col] || 0;
      },
      avgResolutionTimeString(col: string, data: any) {
        return data[col] || 0;
      },
      avgResolveTimeEfficiencyString(col: string, data: any) {
        return data[col] || 0;
      },
    };
  };

  const {results = []} = data ?? {};

  const tableProps = {
    nodata: <TableNothing/>,
    data: results?.map((v: any) => {
      return {
        ...(v?.counting ?? {}),
        repName: v?.user?.name ?? ''
      };
    }) ?? [],
    loading: isFetching,
    columns: makeTableColumns(fields, getMapColumns(), {}, []),
    initialState: {
      pageSize: 0,
      pageIndex: 1,
      selectedIds: [],
    },
    onCheckedRow: () => null,
    onSortBy: (clName: any, isSortedDesc: boolean) => {
      let orderBy = isSortedDesc ? 1 : 2;
      setSort({field: clName, orderBy: orderBy});
    },
    isCheckboxTable: false,
    className: 'rounded-0 bd-l-0 bd-r-0 bd-b-0'
  }

  const isDataLoading = isLoading || isFetching;

  return (
    <div className="list-wrap">
      <div className="list-body pd-0-f">
        {isDataLoading && <Loading/>}
        {!isLoading && <ListReactTable {...tableProps} />}
      </div>
    </div>
  );
}

export default withChartContainer(TicketRepPerformance);