import {useGetTicketByDateCounting} from '@dashboard/dashboard/services/desk';
import Bar from '@dashboard/dashboard/components/chart/bar';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const TicketOverview = (props: ChartComponentProps) => {
  const {me, filters, ...restProps} = props;

  const {data} = useChartQuery(restProps, () => {
    return useGetTicketByDateCounting(filters ?? {});
  });

  const { results = [], period } = data ?? {};

  let oData: any = {};
  if (!!results) {
    oData = results.reduce(
      (f: any, v: any) => {
        const { resolved = 0, unresolved = 0, resolvedWithinSla = 0 } = v?.counting ?? {};
        f['categories'].push(v?.date?.name ?? '-');
        f['resolved']['data'].push(resolved);
        f['unresolved']['data'].push(unresolved);
        f['resolvedWithinSla']['data'].push(resolvedWithinSla);
        return f;
      },
      {
        categories: [],
        resolved: {
          name: 'Resolved',
          data: [],
        },
        unresolved: {
          name: 'Unresolved',
          data: [],
        },
        resolvedWithinSla: {
          name: 'Resolved within SLA',
          data: [],
        },
      },
    );
  }

  return (
    <Bar
      series={[oData.resolved, oData.unresolved, oData.resolvedWithinSla]}
      categories={oData?.categories ?? []}
    />
  );
};

export default withChartContainer(TicketOverview);
