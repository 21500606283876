import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Button } from '@base/components/form';
import { useTrackingTicketComments } from '@site-page/services/service';
import Loading from '@base/components/loading';
import { Avatar, SpanLang } from '@base/components';
import { convertDateTimeServerToClient, getFileIcon, humanFileSize } from '@base/utils/helpers';
import { SvgIcons } from '@base/assets/icons/svg-icons';
import { Download } from 'react-feather';
import WriteFeedbackForm from '@site-page/containers/desk/write-feedback-form';
import { useRecoilValue } from 'recoil';
import { publicViewDataByMenuAtom } from '@site-page/recoil/view';
import { getFieldLayoutDataByKeyName } from '@base/utils/helpers/page-layout-utils';
import { MENU_DESK_TICKET } from '@base/config/menus';
import { KEY_TICKET_SUBJECT } from '@desk/ticket/config/key-names';

interface FeedbackProps {
  token: string;
  menuSourceId: string;
  menuSource: string;
}

const Feedback = (props: FeedbackProps) => {
  const { token, menuSource, menuSourceId } = props;
  //state
  const [curPaging, setCurPaging] = useState({ page: 1, size: 10, totalPage: 1 });
  const [items, setItems] = useState<any>([]);
  const [isOpenFb, setIsOpenFb] = useState(false);
  const [parentFb, setParentFb] = useState<any>(null);

  //ticket subject
  const viewData = useRecoilValue(publicViewDataByMenuAtom(MENU_DESK_TICKET));
  const subjectField = getFieldLayoutDataByKeyName(viewData, KEY_TICKET_SUBJECT);
  //// console.log('subjectField', subjectField);

  //get list
  const {
    data: postsData,
    isFetching,
    refetch,
  } = useTrackingTicketComments({
    ticketId: menuSourceId,
    paging: curPaging,
    token,
  });
  //// console.log('postsData', postsData);

  //update paging
  useEffect(() => {
    if (postsData?.data) {
      if (postsData?.paging?.currentPage !== curPaging.page) {
        setItems((curItems: any) => [...curItems, ...postsData.data]);
      } else {
        setItems(postsData.data);
      }
    }
    if (postsData?.paging) {
      if (
        postsData.paging.currentPage !== curPaging.page ||
        postsData.paging.totalPage !== curPaging.totalPage
      ) {
        const newPaging = {
          ...curPaging,
          page: postsData.paging.currentPage,
          totalPage: postsData.paging.totalPage,
        };
        setCurPaging(newPaging);
      }
    }
  }, [postsData]);

  //more data
  const handleLoadMore = () => {
    if (curPaging.page < curPaging.totalPage) {
      setCurPaging((curValue: any) => ({ ...curValue, page: curValue.page + 1 }));
    }
  };

  //render file type icon
  const renderFileIcon = (filename: string) => {
    let icon = getFileIcon(filename);
    return <SvgIcons type={icon} />;
  };

  //render an attachment
  const renderAttachment = (file: any) => {
    return (
      <div className="d-flex align-items-center mg-b-5">
        <div className="media align-items-center">
          <span className="file-type-icon">{renderFileIcon(file?.name)}</span>
          <div className="media-body mg-l-10 text-truncate">
            {file.name}
            <small className="d-block text-muted text-truncate">
              ({humanFileSize(file.size || 1)}){' '}
              {convertDateTimeServerToClient({ date: file.createdAt?.toString() })}
            </small>
          </div>
        </div>
        <div className="flex-shrink-0">
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Download"
            //onClick={() => handleDownloadFile(file)}
          >
            <Download />
            {/* {mDownload.isLoading && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            )} */}
          </button>
          {/* <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Delete"
            onClick={() => handleDeleteFile(item)}
          >
            {!mDeleteUpload.isLoading && !mDeleteAttachment.isLoading && <X className="tx-danger" />}
            {(mDeleteUpload.isLoading || mDeleteAttachment.isLoading) && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            )}
            <span className="sr-only">
              <SpanLang keyLang="common_btn_download" />
            </span>
          </button> */}
        </div>
      </div>
    );
  };

  //render list
  const ItemsRender = useMemo(() => {
    return (
      <>
        {items.map((_item: any, index: number) => (
          <div key={index} className="card mg-t-10">
            <div className="card-header d-flex align-items-center">
              <Avatar url="" alt={_item.createdBy.name} height={36} width={36} />
              <div className="mg-l-10">
                {_item.createdBy.name}
                <div className="tx-color-03 tx-12">
                  {convertDateTimeServerToClient({
                    date: _item.createdAt,
                    formatOutput: 'YYYY-MM-DD HH:mm:ss',
                  })}
                </div>
              </div>
              <Button
                color="primary"
                size="sm"
                outline
                name="Feedback"
                className="mg-l-auto rounded-30"
                onClick={() => {
                  setParentFb(_item);
                  setIsOpenFb(true);
                }}
              />
            </div>
            <div className="card-body">
              <div
                //id={'commentContent' + index}
                //className="collapse fade show pd-t-10"
                dangerouslySetInnerHTML={{ __html: _item.comment.content }}
              />
              {_item?.comment?.attachedFiles?.map((_file: any, fIndex: number) => (
                <Fragment key={fIndex}>
                  {_file && renderAttachment({ ..._file, createdAt: _item.createdAt })}
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      </>
    );
  }, [items, parentFb, isOpenFb]);

  return (
    <div className="pos-relative">
      {isFetching && <Loading />}
      {ItemsRender}
      {curPaging.page < curPaging.totalPage && (
        <Button
          outline
          color="light"
          className="w-100 bd-0 mg-t-10"
          onClick={handleLoadMore}
          loading={isFetching}
        >
          Load More...
        </Button>
      )}
      {isOpenFb && (
        <WriteFeedbackForm
          className="mn-wd-900"
          ticketId={menuSourceId}
          ticketName={subjectField?.data || ''}
          parentId={parentFb?.id}
          title={<SpanLang keyLang={'New Feedback'} />}
          showCanvas={true}
          isOpen={isOpenFb}
          onClose={() => setIsOpenFb(false)}
          onReload={refetch}
        />
      )}
    </div>
  );
};

export default Feedback;
