import React from 'react';
import { KEY_TICKET_CONTENT } from '@site-page/config/desk/key-names';
import { useRecoilValue } from 'recoil';
import { getFieldLayoutDataByKeyName } from '@base/utils/helpers/page-layout-utils';
import { MENU_DESK, MENU_DESK_TICKET } from '@base/config/menus';
import { ViewFields } from '@base/components/hook-form/view';
import { publicViewDataByMenuAtom } from '@site-page/recoil/view';
import Feedback from './feedback';

interface DetailsProps {
  token: string,
  menuSourceId: string;
  menuSource: string;
}

const Details: React.FC<DetailsProps> = (props: DetailsProps) => {
  const { token, menuSource, menuSourceId } = props;
  const viewData = useRecoilValue(publicViewDataByMenuAtom(MENU_DESK_TICKET));
  const contentField = getFieldLayoutDataByKeyName(viewData, KEY_TICKET_CONTENT);

  return (
    <>
      <div className="tab-content bg-white bd bd-gray-200 bd-t-0 pd-20">
        {contentField && (
          <ViewFields
            fields={[{ ...contentField, config: { ...contentField.config, notShowTitle: true } }]}
            menu={MENU_DESK}
            menuSource={menuSource}
            menuSourceId={menuSourceId}
          />
        )}
      </div>

      <Feedback
        token={token}
        menuSource={menuSource}
        menuSourceId={menuSourceId}
      />
    </>
  );
};

export default Details;
