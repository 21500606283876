import React from 'react';
import { useRecoilState } from 'recoil';
import { getMoreActions } from '@base/utils/helpers/list-page-utils';
import ListHeader from '@dashboard/report/components/list/list-header/new-index';
import ListHeaderFilters from '@dashboard/report/components/list/list-header/list-header-filters';
import {
  dateOptions,
  filterOptions,
  searchFields
} from '@dashboard/report/configs/header';
import { reportListFilterState } from '@dashboard/report/recoil/atom';
import { t } from 'i18next';
import {
  KEY_NAME_CREATED_AT,
  KEY_NAME_UPDATED_AT
} from "@base/config/key-names";
import {isArray} from "lodash";

const PageHeader: React.FC<any> = (props) => {
  const { isSplitMode, columnKeys, fields, onChecked, refetch, category } = props;
  const [listFilter, setListFilter] = useRecoilState(reportListFilterState);
  let { filter, listType } = listFilter;

  const sortColumns = [
    KEY_NAME_CREATED_AT,
    KEY_NAME_UPDATED_AT
  ];

  const sort = filter.sort;
  const setSort = (v: any) => {
    let newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        sort: v
      },
    };
    setListFilter(newListFilter);
  };

  const getSortColumns = () => {
    const layoutSortColumns = fields?.reduce((options: any, col: any) => {
      if (col.sortable || sortColumns.includes(col.keyName)) {
        options.push({
          label: t(col.title),
          value: col.keyName,
        });
      }
      return options;
    }, []);

    return layoutSortColumns;
  };

  const handleSearchSubmit = (searchData: any) => {
    let newQuery: any = { ...filter.query };
    newQuery.name = { value: searchData?.keyword || '', operator: ':' };
    let newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        query: newQuery
      }
    };
    setListFilter(newListFilter);
  };

  //filter change
  const handleFilterSubmit = (filterData: any) => {
    let newQuery: any = { ...filter.query };

    if (filterData?.groupBy) {
      switch (filterData.groupBy) {
        default:
          newQuery.groupBy = {
            value: filterData.groupBy,
            operator: '='
          };
      }
    }

    dateOptions.map((_option: any) => {
      if (filterData[_option.value] && filterData[_option.value]?.length > 0) {
        newQuery[_option.value] = {
          condition: 'AND',
          criteria: [
            { value: filterData[_option.value][0], operator: '>=' },
            { value: filterData[_option.value][1], operator: '<=' },
          ]
        };
      } else {
        newQuery[_option.value] = {}; //all
      }
    });

    filterOptions.map((_option: any) => {
      if (filterData[_option.value]) {
        if (isArray(filterData[_option.value])) {
          const tmpValues: any[] = [];
          filterData[_option.value].map((_ele: any) => {
            tmpValues.push({ value: _ele, operator: '=' });
          })
          newQuery[_option.value] = { condition: 'OR', criteria: tmpValues };
        } else {
          newQuery[_option.value] = { value: filterData[_option.value], operator: '=' };
        }
      } else {
        newQuery[_option.value] = { value: '', operator: '=' };
      }
    });

    //set global filter
    let newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        query: newQuery
      },
    };

    setListFilter(newListFilter);
  };

  //render
  return (
    <ListHeader
      listType={listType}
      isSplitMode={isSplitMode}
      searchFields={searchFields.reduce((final: any[], field: any) => {
        if (columnKeys.indexOf(field.name) !== -1) {
          final.push(field);
        }
        return final;
      }, [])}
      searchValue={listFilter?.filter?.query?.name?.value || ''}
      onSearchSubmit={handleSearchSubmit}
      columns={fields}
      category={category ?? ''}
      filterComponent={
        <ListHeaderFilters
          category={category ?? ''}
          isSplitMode={isSplitMode}
          filterData={filter?.query}
          onFilterDataChanged={handleFilterSubmit}
          sortData={sort}
          onSortDataChanged={setSort}
          sortColumns={getSortColumns()}
        />
      }
      onSelectedAll={() => onChecked('all')}
      onDeselectedAll={() => onChecked('notAll')}
      onRefresh={refetch}
      actionsProps={getMoreActions()}
    />
  );
};

export default PageHeader;
