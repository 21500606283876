import {MENU_ANALYTIC} from "@base/config/menus";

export const allMenus = [
  {
    value: 'dashboard',
    label: 'Dashboard',
    path: '/analytic/dashboard',
  },
  {
    value: 'report',
    label: 'Report',
    path: '/analytic/report',
  }
];

export const allCategories = allMenus.reduce((f: any, v) => {
  f = {...f, [v.value]: v.label};
  return f;
}, []);

export const CATEGORY_DASHBOARD = 'dashboard';
export const CATEGORY_REPORT = 'report';

export const MENU_CATEGORY_DASHBOARD = `${MENU_ANALYTIC}_${CATEGORY_DASHBOARD}`;
export const MENU_CATEGORY_REPORT = `${MENU_ANALYTIC}_${CATEGORY_REPORT}`;