import classNames from "classnames";
import {Edit, Plus, X} from "react-feather";
import React from "react";
import styled from "@emotion/styled";

const NavLine = styled.ul`
  .nav-link {
    > span:first-child {
      max-width: 70px;
    }
  }

  li.more-nav-item {
    div.dropdown-menu {
      min-width: auto !important;
      width: 150px;
    }
  }
`;

interface ReportContentHeaderProps {
  pageActive: any;
  pages: any[];
  setPageActive: (pageData: any) => void;
  setPageNameEditing: (editingData: any) => void;
  handleRemovePage: (e: any, i: number) => void;
  navLimit?: number;
}

const ReportContentHeader: React.FC<ReportContentHeaderProps> = (props: ReportContentHeaderProps) => {
  const {
    pageActive,
    pages,
    setPageActive,
    setPageNameEditing,
    handleRemovePage,
    navLimit = 2
  } = props;

  const pageLength = pages?.length ?? 0;

  const mainPages = pages.filter((_, i) => i < navLimit);
  const morePages = pages.filter((_, i) => i >= navLimit);
  const activeIndex = pages.findIndex((v) => v.id === pageActive.id);
  const isMoreActivated = activeIndex >= navLimit;

  return (
    <NavLine className="nav nav-line mg-t-10 pd-x-15 bd-0">
      {mainPages.map((p: any, i: number) => {
        const isActive = pageActive.id === p.id;
        return (
          <li className="nav-item" key={i} onClick={() => !isActive && setPageActive(p)}>
            <div className={classNames('nav-link d-flex align-items-center', {'active': isActive})}>
              <span className="cursor-pointer text-overflow">{p.name}</span>
              <div className="d-flex align-items-center tx-18 mg-l-10">
                <a href="#" className="tx-success lh-0" onClick={() => setPageNameEditing({i, name: p.name})}>
                  <Edit size={14}/>
                </a>
                {
                  pages.length > 1 && (
                    <a href="#" className="tx-danger lh-0 mg-l-5" onClick={(e: any) => handleRemovePage(e, i)}>
                      <X size={14}/>
                    </a>
                  )
                }
              </div>
            </div>
          </li>
        );
      })}
      {
        pageLength > navLimit && (
          <li className="nav-item dropdown pd-0-f more-nav-item">
            <button type="button" className={classNames('btn nav-link', {'active': isMoreActivated})} data-toggle="dropdown">
              <Plus className="mg-r-5"/>
              {
                isMoreActivated ? <span>{pageActive.name}</span> : <span>More</span>
              }
            </button>
            <div className="dropdown-menu dropdown-menu-right bg-white">
              {morePages.map((p: any, i: number) => {
                const isActive = pageActive.id === p.id;
                return (
                  (
                    <div className={classNames('dropdown-item d-flex', {'active': isActive})} key={i} onClick={() => setPageActive(p)}>
                      <span className="cursor-pointer text-overflow">{p.name}</span>
                      <div className="d-flex align-items-center tx-18 mg-l-auto">
                        <a href="#" className="tx-success lh-0" onClick={() => setPageNameEditing({i: (i + navLimit), name: p.name})}>
                          <Edit size={14}/>
                        </a>
                        {
                          pages.length > 1 && (
                            <a href="#" className="tx-danger lh-0 mg-l-5" onClick={(e: any) => handleRemovePage(e, i)}>
                              <X size={14}/>
                            </a>
                          )
                        }
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </li>
        )
      }
    </NavLine>
  );
}

export default ReportContentHeader;