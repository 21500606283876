import { atom } from 'recoil';
import { DESC } from '@base/config/constant';
import { ListType } from '@base/types/enums';

export const reportListState = atom({
  key: 'REPORT_LIST_STATE',
  default: [],
});

export const reportListFilterState = atom({
  key: 'REPORT_LIST_FILTER',
  default: {
    isSplitMode: false,
    activeMenu: 'report',
    activeId: '',
    activeTab: '',
    listType: ListType.LIST,
    settingColumns: [],
    filter: {
      query: {} as any,
      paging: {
        page: 1,
        size: 15,
      },
      sort: {
        field: 'createdAt',
        orderBy: DESC,
      },
    } as any,
  },
});

export const reportWriteState = atom({
  key: 'REPORT_WRITE_STATE',
  default: {
    writeType: 'report',
    writeMenu: 'list',
    isOpenWrite: false,
  },
});
