import React from "react";
import CountBlock from "@dashboard/dashboard/components/count-block";
import {useGetActivityTrend} from "@dashboard/dashboard/services/activity";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";
import BlockBody from "@dashboard/dashboard/components/block-body";
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";

const ActivityTrend = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetActivityTrend(props?.filters ?? {});
  });

  const {firstCounting = {}, lastCounting = {}} = data ?? {};

  const labelOfOldValue = 'Last';

  return (
    <BlockBody className="d-flex bd-t overflow-x-auto">
      <CountBlock
        className='bd-r'
        title='Total'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.total ?? 0}
        oldValue={lastCounting?.total ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Overdue'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.overDue ?? 0}
        oldValue={lastCounting?.overDue ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Due Today'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.dueToday ?? 0}
        oldValue={lastCounting?.dueToday ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Email'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.email ?? 0}
        oldValue={lastCounting?.email ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Call'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.call ?? 0}
        oldValue={lastCounting?.call ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Task'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.task ?? 0}
        oldValue={lastCounting?.task ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Sms'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.sms ?? 0}
        oldValue={lastCounting?.sms ?? 0}
      />
      <CountBlock
        className='bd-r'
        title='Avg. Duration'
        timeLabel={labelOfOldValue}
        newValue={firstCounting?.avgDurationString ?? 0}
        oldValue={lastCounting?.avgDurationString ?? 0}
      />
    </BlockBody>
  );
}

export default withChartContainer(ActivityTrend);