import {ESectionType} from "@dashboard/main/types/enum";
import {
  adminConfigs as customerAdminConfigs,
  userConfigs as customerUserConfigs
} from "@dashboard/main/configs/charts/customer";
import {
  adminConfigs as activityAdminConfigs,
  userConfigs as activityUserConfigs
} from "@dashboard/main/configs/charts/activity";
import {
  adminConfigs as deskAdminConfigs,
  userConfigs as deskUserConfigs
} from "@dashboard/main/configs/charts/desk";
import {
  adminConfigs as customerSatisfactionAdminConfigs,
  userConfigs as customerSatisfactionUserConfigs
} from "@dashboard/main/configs/charts/customer-satisfaction";
import {sectionOptions} from "@dashboard/main/configs/options";

export const sectionUserConfigs: any = {
  [ESectionType.SECTION_CUSTOMER]: {
    componentProps: {
      title: sectionOptions[ESectionType.SECTION_CUSTOMER]
    },
    chartConfigs: customerUserConfigs
  },
  [ESectionType.SECTION_ACTIVITY]: {
    componentProps: {
      title: sectionOptions[ESectionType.SECTION_ACTIVITY],
    },
    chartConfigs: activityUserConfigs
  },
  [ESectionType.SECTION_DESK]: {
    componentProps: {
      title: sectionOptions[ESectionType.SECTION_DESK],
    },
    chartConfigs: deskUserConfigs
  },
  [ESectionType.SECTION_CUSTOMER_SATISFACTION]: {
    componentProps: {
      title: sectionOptions[ESectionType.SECTION_CUSTOMER_SATISFACTION],
    },
    chartConfigs: customerSatisfactionUserConfigs
  }
};

export const sectionsAdminConfigs: any = {
  [ESectionType.SECTION_CUSTOMER]: {
    key: ESectionType.SECTION_CUSTOMER,
    componentProps: {
      title: sectionOptions[ESectionType.SECTION_CUSTOMER]
    },
    chartConfigs: customerAdminConfigs
  },
  [ESectionType.SECTION_ACTIVITY]: {
    componentProps: {
      title: sectionOptions[ESectionType.SECTION_ACTIVITY],
    },
    chartConfigs: activityAdminConfigs
  },
  [ESectionType.SECTION_DESK]: {
    componentProps: {
      title: sectionOptions[ESectionType.SECTION_DESK],
    },
    chartConfigs: deskAdminConfigs
  },
  [ESectionType.SECTION_CUSTOMER_SATISFACTION]: {
    componentProps: {
      title: sectionOptions[ESectionType.SECTION_CUSTOMER_SATISFACTION],
    },
    chartConfigs: customerSatisfactionAdminConfigs
  }
};