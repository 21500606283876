import {gql} from "graphql-request";

export const ANALYTIC_CREATEREPORT = gql`
    mutation q($report: Report) {
        analytic_createReport(report: $report) {
            id
        }
    }
`;

export const ANALYTIC_DELETEREPORT = gql`
    mutation q($ids: [String!]!) {
        analytic_deleteReport(ids: $ids) {
            ids
        }
    }
`;

export const ANALYTIC_COPYREPORT = gql`
    mutation q($id: String!) {
        analytic_copyReport(id: $id) {
            id
        }
    }
`;