import {gql} from "graphql-request";

export const getQueryString = (menu: string, fieldSchema: string) => {
    return gql`
        query q($filter: SearchFilter) {
            ${menu}(filter: $filter) {
                ${fieldSchema}
            }
        }
    `;
};

export const getDateCountingQueryString = (menu: string, fieldSchema: string) => {
    return gql`
        query q($filter: SearchFilter) {
            ${menu}(filter: $filter) {
                period {
                    startTime
                    endTime
                }
                results {
                    date {
                        key
                        name
                        period {
                            startTime
                            endTime
                        }
                    }
                    ${fieldSchema}
                }
            }
        }
    `;
};