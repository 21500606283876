import {useGetTicketBaseCounting} from '@dashboard/dashboard/services/desk';
import BarSimple from '@dashboard/dashboard/components/chart/bar-simple';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const TicketRepByAvgResolutionTime = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetTicketBaseCounting(props?.filters ?? {});
  });

  const { results = [] } = data ?? {};

  let chartData: any = {
    l: [],
    s: [],
  };
  if (!!results && !!results?.length) {
    chartData = results.reduce((f: any, v: any) => {
      f['l'].push(v?.user?.name ?? '--No Category Name--');
      f['s'].push(v?.value ?? 0);
      return f;
    }, chartData);
  }

  return <BarSimple series={chartData?.s ?? []} categories={chartData?.l ?? []} />;
};

export default withChartContainer(TicketRepByAvgResolutionTime);
