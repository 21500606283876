import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as keyNames from '@site-page/config/desk/key-names';
import { ViewFields } from '@base/components/hook-form/view';
import { publicViewDataByMenuAtom } from '@site-page/recoil/view';

interface SummaryProps {
  id: string;
  category: string;
  onRefetch?: () => void;
}

const Summary: React.FC<SummaryProps> = (props: SummaryProps) => {
  const { category, id, onRefetch } = props;
  const viewData = useRecoilValue(publicViewDataByMenuAtom(category)); //'ticket'
  const [basicFields, setBasicField] = useState<any[]>([]);

  //hide fields
  const hiddenFields: string[] = [
    keyNames.KEY_TICKET_SUBJECT,
    keyNames.KEY_TICKET_PRODUCT,
    keyNames.KEY_TICKET_CUSTOMER,
    keyNames.KEY_TICKET_CONTACT,
    keyNames.KEY_TICKET_CONTENT,
    keyNames.KEY_TICKET_ASSIGN_GROUP,
    keyNames.KEY_TICKET_ASSIGN_USER,
    keyNames.KEY_TICKET_CC_USERS,
    keyNames.KEY_TICKET_CHANNEL,
    keyNames.KEY_TICKET_PROCESS,
    keyNames.KEY_TICKET_CREATED_AT,
    keyNames.KEY_TICKET_UPDATED_AT,
    keyNames.KEY_TICKET_CREATED_BY,
    keyNames.KEY_TICKET_UPDATED_BY,
  ];

  const allowRefetchFields: string[] = [];

  //init fields
  useEffect(() => {
    let newBasicFields = viewData?.layout?.data?.[0]?.children ?? [];
    newBasicFields = newBasicFields.filter((_field: any) => !hiddenFields.includes(_field.keyName));
    setBasicField(newBasicFields);
  }, [viewData]);

  return (
    <>
      <h2 className="mg-b-20 tx-20">Summary</h2>
      <ViewFields
        fields={basicFields}
        ignoreFields={[]}
        menu={viewData?.menu ?? ''}
        menuSource={viewData?.menuSource ?? ''}
        menuSourceId={viewData?.menuSourceId ?? ''}
        refetchFields={allowRefetchFields}
        onRefetch={onRefetch}
      />
    </>
  );
};

export default Summary;
