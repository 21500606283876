import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ViewPage from '@site-page/pages/view';
import { usePublicLanguageByMenu } from '@base/services/i18n';
import { extractUrlParams } from '@site-page/utils/Params';

const FirstPage = () => {
  const siteParams = extractUrlParams();
  // load menu language
  usePublicLanguageByMenu(['common', 'pagelayout', 'generalsetting'], siteParams?.tk || '');

  return (
    <>
      <Helmet>
        <title>Hanbiro CRM - Site</title>
      </Helmet>
      <Routes>
        <Route path="view/*" element={<ViewPage />} />
      </Routes>
    </>
  );
};

export default FirstPage;
