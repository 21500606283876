import React, {useEffect, useState} from "react";
import {
  filterComponents,
  filterDate,
  filterDateGroup,
  filterDateUser,
  filterGroup,
  filterUser
} from "@dashboard/dashboard/config/common/filters";
import {keys, values, merge, cloneDeep} from "lodash";
import {EDateRangeType} from "@base/types/enums";

interface IFilterQueryBaseProps extends IFilterQueryProps {
  requiredFilters?: string[];
  customConfigs?: any;
  onChange?: (queryStr: string) => void;

  [x: string]: any;
}

const FilterQueryBase: React.FC<IFilterQueryBaseProps> = (props: IFilterQueryBaseProps) => {
  const {
    onChange,
    options,
    requiredFilters = [filterDate],
    customConfigs = {},
    onlyDateRangeFilter = false,
    dateRangeProps = {},
    ...restProps
  } = props;

  const [data, setData] = useState<any>({});

  useEffect(() => {
    const filterValues = values(data);
    if(filterValues?.length){
      onChange && onChange(filterValues.join(' '));
    }
  }, [data])

  const requiredComponents = requiredFilters.reduce((f: any, k: string) => {
    if (!!filterComponents[k]) {
      f[k] = cloneDeep(filterComponents[k]);
      if (customConfigs?.[k]) {
        f[k] = merge(f[k], customConfigs[k]);
      }
    }
    return f;
  }, {});

  return (
    <div className="d-flex pd-10 w-50" {...restProps}>
      {
        keys(requiredComponents).map((k) => {
          const {
            keyName,
            component: Component,
            componentProps = {},
            getValue
          } = requiredComponents[k];

          const optsGetValue = {keyName};

          return <Component key={k} {...componentProps} onChange={(value: any) => {
            const customValue = (getValue && getValue(value, optsGetValue)) ?? value
            setData({...data, [k]: customValue})
          }}/>;
        })
      }
    </div>
  );
};

interface IFilterQueryProps {
  isDateUser?: boolean
  isDateGroup?: boolean
  isDateNoPeriod?: boolean
  isDefaultThisMonth?: boolean
  onChange?: (value: string) => void;
  dateQueryKey?: string;
  assignUserQueryKey?: string;
  assignGroupQueryKey?: string;
  [x: string]: any;
}

const FilterQuery = (props: IFilterQueryProps) => {
  const {
    isDateUser = false,
    isDateGroup = false,
    isDateNoPeriod = false,
    isDefaultThisMonth = false,
    onChange,
    dateQueryKey,
    assignUserQueryKey,
    assignGroupQueryKey,
    ...restProps
  } = props;

  let filterQueryProps: IFilterQueryBaseProps = {};

  if (isDateUser) {
    filterQueryProps = {...filterQueryProps, requiredFilters: filterDateUser, className: "d-flex pd-10"}
  } else {
    if (isDateGroup) {
      filterQueryProps = {...filterQueryProps, requiredFilters: filterDateGroup, className: "d-flex pd-10"}
    }
  }

  if (!!onChange) {
    filterQueryProps = {...filterQueryProps, onChange}
  }

  let customConfigs = {};
  if (!!dateQueryKey) {
    customConfigs = {...customConfigs, [filterDate]: {keyName: dateQueryKey}}
  }
  if (!!dateQueryKey) {
    customConfigs = {...customConfigs, [filterUser]: {keyName: assignUserQueryKey}}
  }
  if (!!assignGroupQueryKey) {
    customConfigs = {...customConfigs, [filterGroup]: {keyName: assignGroupQueryKey}}
  }
  if (isDateNoPeriod) {
    customConfigs = merge(customConfigs, {[filterDate]: {componentProps: {noPeriod: true}}})
    filterQueryProps = {...filterQueryProps, className: "d-flex pd-10"}
  }
  if (isDefaultThisMonth) {
    customConfigs = merge(customConfigs, {[filterDate]: {componentProps: {defaultSelectValue: EDateRangeType.DATE_RANGE_THIS_MONTH}}})
  }
  if (!!keys(customConfigs)?.length) {
    filterQueryProps = {...filterQueryProps, customConfigs}
  }

  return (
    <FilterQueryBase {...filterQueryProps} {...restProps}/>
  );
}

export default FilterQuery;