import React from 'react';
import PublicCommonViewField from '@base/containers/view-field/common/public';

import View from './view';
import Edit from './edit';

const CategoryProductViewField: React.FC = (props: any) => {
  return <PublicCommonViewField {...props} componentView={View} componentEdit={Edit} />;
};

export default CategoryProductViewField;
