import {Select} from "@base/components/form";
import {repeatUnitOptions, weekDayOptions} from "@dashboard/main/configs/options";
import {ERepeatUnit, EWeekDayEnum} from "@dashboard/main/types/enum";
import {includes, keys, padStart, range} from "lodash";
import TimePicker from "@base/components/time-picker";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";

interface IWeekDayProps {
  value?: number;
  onChange?: (v: any) => void;
}

const WeekDay: React.FC<IWeekDayProps> = (props: IWeekDayProps) => {
  const {onChange, value} = props;

  const [weekDay, setWeekDay] = useState<number>(value ?? EWeekDayEnum.WEEK_DAY_SUNDAY);

  useEffect(() => {
    onChange && onChange({label: weekDayOptions[weekDay], value: weekDay})
  }, [weekDay])

  return (
    <>
      {
        keys(weekDayOptions).map((k, i) => (
          <div className="custom-control custom-radio d-inline-block mg-r-10">
            <input
              type="radio"
              className="custom-control-input"
              id={`weekDayCheck${i}`}
              checked={weekDay.toString() === k}
              onChange={(e) => setWeekDay(parseInt(k))}
            />
            <label className="custom-control-label" htmlFor={`weekDayCheck${i}`}>
              {weekDayOptions[k]}
            </label>
          </div>
        ))
      }
    </>
  );
}

interface IReportingCycleProps {
  value?: any;
  onChange?: (v: any) => void;
}

const ReportingCycle: React.FC<IReportingCycleProps> = (props: IReportingCycleProps) => {
  const {onChange, value} = props;

  const {
    frequency: iFrequency,
    monthAt: iMonthAt,
    dayAt: iDayAt,
    timeAt: iTimeAt
  } = value ?? {}

  const defaultFrequency = !!iFrequency ? {
    value: iFrequency,
    label: repeatUnitOptions[iFrequency]
  } : {
    value: ERepeatUnit.REPEAT_UNIT_MONTHLY,
    label: repeatUnitOptions[ERepeatUnit.REPEAT_UNIT_MONTHLY]
  };

  const [frequency, setFrequency] = useState<any>(defaultFrequency);
  const [monthAt, setMonthAt] = useState<number>(iMonthAt ?? 1);
  const [dayAt, setDayAt] = useState<number>(iDayAt ?? 1);
  const [timeAt, setTimeAt] = useState<string>(iTimeAt ?? dayjs().format("HH:mm"));

  const dayOptions = range(1, 32).map((k, i) => ({label: padStart(k.toString(), 2, '0'), value: k}));
  const monthOptions = range(1, 13).map((k, i) => ({label: padStart(k.toString(), 2, '0'), value: k}));

  useEffect(() => {
    let oValue: any = {
      frequency: frequency.value,
      timeAt
    };
    if (includes([ERepeatUnit.REPEAT_UNIT_WEEKLY, ERepeatUnit.REPEAT_UNIT_MONTHLY], frequency.value)){
      oValue = {...oValue, dayAt}
    } else if (frequency.value === ERepeatUnit.REPEAT_UNIT_YEARLY){
      oValue = {...oValue, monthAt}
    }
    onChange && onChange(oValue);
  }, [frequency, timeAt, dayAt, monthAt])

  return (
    <div className="form-group mg-b-0">
      <div className="tx-orange">
        The report will be generated and sent based on the schedule.
      </div>

      <div className="mg-t-5">
        <div className="form-group row">
          <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Frequency:</label>
          <div className="col-sm-10">
            <Select
              width={150}
              isSearchAble={false}
              options={keys(repeatUnitOptions).map((k) => ({label: repeatUnitOptions[k], value: k}))}
              defaultValue={frequency}
              onChange={(v: any) => setFrequency(v)}
            />
          </div>
        </div>
        <div className="form-group row mg-b-0">
          <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Every:</label>
          <div className="col-sm-10">
            {
              frequency?.value === ERepeatUnit.REPEAT_UNIT_WEEKLY && (
                <div className="wd-100p pd-5">
                  <WeekDay onChange={(v: any) => setDayAt(v?.value)} value={dayAt}/>
                </div>
              )
            }
            <div className="d-flex">
              {
                includes([ERepeatUnit.REPEAT_UNIT_YEARLY, ERepeatUnit.REPEAT_UNIT_MONTHLY], frequency?.value) && (
                  <div className="d-flex align-items-center">
                    {
                      frequency?.value === ERepeatUnit.REPEAT_UNIT_YEARLY && (
                        <div className="cycle-every-month">
                          <div className="d-flex align-items-center">
                            <Select width={150} data-han-tooltip="Month" options={monthOptions}
                                    defaultValue={monthOptions[0]}
                                    onChange={(v: any) => setMonthAt(v?.value)}/>
                            <div className="mg-x-10">/</div>
                          </div>
                          <div className="tx-orange tx-11 pd-3">Month</div>
                        </div>
                      )
                    }
                    <div className="cycle-every-day">
                      <div className="d-flex align-items-center">
                        <Select width={150} options={dayOptions} defaultValue={dayOptions[0]}
                                onChange={(v: any) => setDayAt(v?.value)}/>
                        <div className="mg-x-10">|</div>
                      </div>
                      <div className="tx-orange tx-11 pd-3">Day</div>
                    </div>
                  </div>
                )
              }
              <div className="cycle-every-time wd-100p">
                <TimePicker onChange={(v) => setTimeAt(v)} value={timeAt}/>
                <div className="tx-orange tx-11 pd-3">Time</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportingCycle;