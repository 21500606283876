import dayjs from "dayjs";

const now = dayjs().utc();

export const today = () => {
  const startDate = now.startOf('day');
  const endDate = now.endOf('day');
  return {startDate, endDate};
};

export const yesterday = () => {
  const startDate = now.subtract(1, 'day').startOf('day');
  const endDate = now.subtract(1, 'day').endOf('day');
  return {startDate, endDate};
};

export const thisWeek = () => {
  const startDate = now.startOf('week');
  const endDate = now.endOf('week');
  return {startDate, endDate};
};

export const thisMonth = () => {
  const startDate = now.startOf('month');
  const endDate = now.endOf('month');
  return {startDate, endDate};
};