import { atom } from 'recoil';
import Storage from '@base/utils/storages/ls';
import { IToken } from 'sign-in/types/auth';

const Ls = new Storage();
const token = Ls.get('token');

export const authAtom = atom<IToken | null>({
  key: 'authAtom',
  default: {
    accessToken:
      'eyJhbGciOiJSUzI1NiIsImtpZCI6IjIyOTdhNmMwLTZjNTUtNDA1YS05YmM0LTBiYzIxYTE5NDBlNyIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2lkLmhhYmluLmlvIiwic3ViIjoiNjg1YzVhMzAtY2MxNy00MDQ4LTgxZWMtZTJmZWQ1OWMzN2VkIiwiYXVkIjoiaHR0cHM6Ly9pZC5oYWJpbi5pbyIsImV4cCI6MTY0NTY0MDgwMiwiaWF0IjoxNjQ1NDAwODAyLCJqdGkiOiIyMmQ3YWFlMi0xNzBjLTQxMTQtYmE3Zi1iY2ZjODE2MjZjNTIiLCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJjbGllbnRfaWQiOiIyMjk3OTk3Yy02NzkyLTQwODQtYmJlYi1iOTQxNjE5ZDJmMDAiLCJlbWFpbCI6Imppa2ltZUBnbWFpbC5jb20iLCJwcmltYXJ5X2VtYWlsIjoiamlraW1lQGdtYWlsLmNvbSIsInRlc3QiOnRydWV9.aPED9rjr3R8B8kMoqzD9d_Mwdsp8VmMl8OSS0EFEdnELN1_cqSJK48lfCZrwOzaoMwsVFMBy-IL_lsmscl2AC2qKWIWrlZZ5m6FRORTws-Vw7rdsKtrBiqTW0uZcZthtczvlYVFgKhbr3XPYsMXe9v3Rx5VQEBXvfAQlPhG3BtV_IEJDrtFc8EVcG0rLEiSCUXldD3p5S8AUPI7fQ9p5kPsjkBJQgDUTxKi8Ms6Hkz1jAqeIDZjDDogr_0xUDvFDfYWLk7JOOVd34yKZNnhwa6PBx-VjY9Uhp1JZM1oNX8kIwRSqp1PqvfRlVygn_fjXjBktJvWaFn3a0uWm0G1zqg',
    refreshToken:
      'eyJhbGciOiJSUzI1NiIsImtpZCI6IjIyOTdhNmMwLTZjNTUtNDA1YS05YmM0LTBiYzIxYTE5NDBlNyIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2lkLmhhYmluLmlvIiwic3ViIjoiNjg1YzVhMzAtY2MxNy00MDQ4LTgxZWMtZTJmZWQ1OWMzN2VkIiwiYXVkIjoiaHR0cHM6Ly9pZC5oYWJpbi5pbyIsImV4cCI6MTY0NTY0MDgwMiwiaWF0IjoxNjQ1NDAwODAyLCJqdGkiOiIyMmQ3YWFlMi0xNzBjLTQxMTQtYmE3Zi1iY2ZjODE2MjZjNTIiLCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJjbGllbnRfaWQiOiIyMjk3OTk3Yy02NzkyLTQwODQtYmJlYi1iOTQxNjE5ZDJmMDAiLCJlbWFpbCI6Imppa2ltZUBnbWFpbC5jb20iLCJwcmltYXJ5X2VtYWlsIjoiamlraW1lQGdtYWlsLmNvbSIsInRlc3QiOnRydWV9.aPED9rjr3R8B8kMoqzD9d_Mwdsp8VmMl8OSS0EFEdnELN1_cqSJK48lfCZrwOzaoMwsVFMBy-IL_lsmscl2AC2qKWIWrlZZ5m6FRORTws-Vw7rdsKtrBiqTW0uZcZthtczvlYVFgKhbr3XPYsMXe9v3Rx5VQEBXvfAQlPhG3BtV_IEJDrtFc8EVcG0rLEiSCUXldD3p5S8AUPI7fQ9p5kPsjkBJQgDUTxKi8Ms6Hkz1jAqeIDZjDDogr_0xUDvFDfYWLk7JOOVd34yKZNnhwa6PBx-VjY9Uhp1JZM1oNX8kIwRSqp1PqvfRlVygn_fjXjBktJvWaFn3a0uWm0G1zqg',
    expiredIn: 1000000,
    expiredOut: 10000000,
  }
});
