import classNames from "classnames";
import {ArrowDown, ArrowUp} from "react-feather";
import React from "react";

const CountBlock: React.FC<any> = (props) => {
  const {className, title, newValue = 0, timeLabel, oldValue = 0} = props;
  let {newValueView = 0, oldValueView = 0} = props;

  if (newValueView == 0) {
    newValueView = newValue
  }

  if (oldValueView == 0) {
    oldValueView = oldValue
  }

  const isUp = newValue > oldValue;
  const isDown = newValue < oldValue;

  return (
    <div className={classNames("d-flex flex-grow-1 flex-column justify-content-center pd-30", className)}
         style={{minWidth: "150px"}}>
      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">{title}</h6>
      <div className="d-flex d-lg-block d-xl-flex align-items-end mg-t-5">
        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{newValueView || 0}</h3>
        <p className="tx-11 tx-color-03 mg-b-0">
          {isUp && <span className="tx-medium tx-success"><ArrowUp size={14}/></span>}
          {isDown && <span className="tx-medium tx-danger"><ArrowDown size={14}/></span>}
        </p>
      </div>
      <div className="d-flex d-lg-block d-xl-flex align-items-end mg-t-10">
        <span className="tx-11 tx-uppercase tx-spacing-1 tx-semibold tx-primary mg-b-0">{timeLabel}:</span>
        <span className="tx-12 tx-normal tx-rubik tx-spacing--1 mg-0 mg-l-2">{oldValueView || 0}</span>
      </div>
    </div>
  );
};

export default CountBlock;