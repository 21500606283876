import React from 'react';
import {useRecoilValue} from 'recoil';
import {deviceAtom} from '@base/recoil/atoms/app';
import displayLayoutConfig from '@dashboard/report/configs/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';

interface IWritePageProps {
  title: string | React.ReactElement;
  size: string;
  fullScreen?: boolean;
  setFullScreen: (() => void) | React.Dispatch<React.SetStateAction<boolean>>;
  isOpen?: boolean;
  onClose: () => void;
  onReload: () => void;
  onGoView: (id: string) => void;
  menuApi: string;
}

const WritePage: React.FC<IWritePageProps> = (props: IWritePageProps) => {
  const deviceState = useRecoilValue(deviceAtom);

  return <BaseLayoutDisplay
    displayConfig={displayLayoutConfig}
    device={deviceState.device}
    layout={deviceState.layout}
    form={'write'}
    {...props}
  />;
};

export default WritePage;
