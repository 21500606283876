import '@base/assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Empty } from '@base/components';
import BaseContainer from '@base/containers/base-container';
import useDevice from '@base/hooks/useDevice';
import NoMatch from '@base/layouts/nomatch';
import RootLayout from '@base/layouts/rootlayout';
import GlobalStyle from '@base/themes/globalStyle';
import THEME from '@base/themes/theme';
import { Global, ThemeProvider } from '@emotion/react';
import classNames from 'classnames';
import React, { Suspense, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { publicRoutes, routes } from './routes';
import RequireAuth from './sign-in/containers/require-auth';
import Callback from './sign-in/pages/callback';
import SignIn from './sign-in/pages/main';

// import { Auth, Callback } from '@auth/pages/login';
const App = () => {
  const deviceState = useDevice();
  // const deviceState = useRecoilValue(deviceAtom);
  const { skin: theme, device } = deviceState;

  // apply skin
  useEffect(() => {
    $('body').attr('data-skin', theme);
  }, [theme]);

  const AppMemo = useMemo(() => {
    console.log('...RENDER.APP...', theme, device);
    return (
      <>
        {/* <DebugObserver /> */}
        <ThemeProvider theme={THEME[theme]}>
          <div id="crm-device" className={classNames(device)}>
            <Global styles={GlobalStyle(THEME[theme])} />
            <BaseContainer />
            <ToastContainer autoClose={3000} position={'top-center'} />
            <Router>
              <Routes>
                {publicRoutes.map((_route) => {
                  return _route.map((_item: any, _index: number) => {
                    const Component = _item.component ? _item.component : Empty;
                    return (
                      <Route
                        element={
                          <Suspense fallback={<></>}>
                            <Component />
                          </Suspense>
                        }
                        key={_index}
                        path={_item.path}
                      />
                    );
                  });
                })}
                {/* <Route path="/login/auth" element={<Auth />} /> */}
                {/* <Route path="/login/callback" element={<Callback />} /> */}
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/sign-in/callback" element={<Callback />} />
                <Route path="/" element={<RootLayout />}>
                  <Route index element={<Navigate replace to="/demo-page" />} />
                  {/* {routes.map((_route) => {
                    return _route.map((_item: any, _index: number) => {
                      const Component = _item.component ? _item.component : Empty;
                      return (
                        <Route
                          element={
                            <Suspense fallback={<></>}>
                              <RequireAuth>
                                <Component />
                              </RequireAuth>
                            </Suspense>
                          }
                          key={_index}
                          path={_item.path}
                        />
                      );
                    });
                  })} */}
                  <Route path="*" element={<NoMatch />} />
                </Route>
              </Routes>
            </Router>
          </div>
        </ThemeProvider>
      </>
    );
  }, [theme, device]);

  return AppMemo;
};

export default App;
