import ReactApexChart from "react-apexcharts";
import React from "react";
import {ALL_COLORS} from "@dashboard/dashboard/config/common/colors";

interface IBarProps {
  series: any[];
  categories: string[];
  [x: string]: any;
}

const Bar = (props: IBarProps) => {
  const {series, categories, ...restProps} = props;

  return (
    <ReactApexChart
      type="bar"
      height={'98%'}
      width={'100%'}
      series={series}
      options={{
        chart: {
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories
        },
        colors: ALL_COLORS,
      }}
      {...restProps}
    />
  );
};

export default Bar;