import {useGetTicketByDateCounting} from '@dashboard/dashboard/services/desk';
import { ALL_COLORS } from '@dashboard/dashboard/config/common/colors';
import BarMarker from '@dashboard/dashboard/components/chart/bar-marker';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const TicketPerformerByResolutionTime = (props: ChartComponentProps) => {
  const {me, filters, ...restProps} = props;

  const {data} = useChartQuery(restProps, () => {
    return useGetTicketByDateCounting(filters ?? {});
  });

  const { results = [] } = data ?? {};

  let oData: any = {
    categories: [],
    resolutionTime: {
      name: me ? 'My Resolution Time' : 'Resolution Time',
      data: [],
    },
    avgResolutionTime: {
      name: 'Avg. Resolution Time',
      data: [],
    },
  };

  if (!!results) {
    oData = results.reduce((f: any, v: any) => {
      const { resolutionTime = 0, avgResolutionTime = 0 } = v?.counting ?? {};
      f['categories'].push(v?.date?.name ?? '-');
      f['resolutionTime']['data'].push(resolutionTime);
      f['avgResolutionTime']['data'].push(avgResolutionTime);
      return f;
    }, oData);
  }

  const chartData: any[] = oData.categories.map((v: string, i: number) => {
    return {
      x: v,
      y: oData.resolutionTime.data?.[i] ?? 0,
      goals: [
        {
          name: oData.avgResolutionTime.name,
          value: oData.avgResolutionTime.data?.[i] ?? 0,
          strokeWidth: 6,
          strokeHeight: 5,
          strokeColor: ALL_COLORS[1],
        },
      ],
    };
  });

  return (
    <BarMarker
      series={[
        {
          name: oData.resolutionTime.name,
          data: chartData,
        },
      ]}
      categories={[oData.resolutionTime.name, oData.avgResolutionTime.name]}
    />
  );
};

export default withChartContainer(TicketPerformerByResolutionTime);
