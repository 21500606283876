import React from "react";
import ReactApexChart from "react-apexcharts";
import {ALL_COLORS} from "@dashboard/dashboard/config/common/colors";

interface ITreemapProps {
  series: any[];

  [x: string]: any;
}

const TreeMap: React.FC<ITreemapProps> = (props: ITreemapProps) => {
  const {series, ...restProps} = props;

  const options: any = {
    legend: {
      show: false
    },
    chart: {
      toolbar: {
        show: false,
      },
      distributed: true
    },
    colors: ALL_COLORS,
  }

  return <ReactApexChart
    type="treemap"
    width={'100%'}
    height={400}
    options={options}
    series={series}
    {...restProps}
  />
}

export default TreeMap;