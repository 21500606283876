import React from 'react';
import ListTableColumnSetting from '@base/components/list/list-table-column-setting';
import ListSearch from '@base/components/list/list-search';
import ListHeaderContainer, { IListHeader } from '@base/components/list/list-header';
import { ListType } from '@base/types/enums';
import { useRecoilState } from 'recoil';
import { reportListFilterState } from '@dashboard/report/recoil/atom';
import { MENU_ANALYTIC } from '@base/config/menus';

interface IListHeaderExtend extends IListHeader {
  filterComponent?: React.ReactNode;
  isSplitMode: boolean;
  listType: ListType;
  category?: string;
  columns: any[];
  searchFields: any[];
  searchValue?: string;
  onSearchSubmit?: (fieldsData: any) => void;
}

const ListHeader: React.FC<IListHeaderExtend> = (props) => {
  const {
    listType,
    isSplitMode,
    filterComponent,
    category,
    columns,
    searchFields,
    searchValue,
    onSearchSubmit,
    ...remainProps
  } = props;
  //recoil
  const [listFilter, setListFilter] = useRecoilState(reportListFilterState);

  //column setting change
  const handleColumnSettingChange = (data: any) => {
    //update to recoil
    const newFilter: any = { ...listFilter };
    newFilter.settingColumns = data;
    setListFilter(newFilter);

    //TODO: call api to save ???
  };

  return (
    <ListHeaderContainer
      isSimple={isSplitMode}
      {...remainProps}
      filterComponent={filterComponent}
      searchComponent={
        onSearchSubmit && (
          <React.Suspense fallback={<></>}>
            <ListSearch
              isSimple={isSplitMode}
              placeholder={'Name'}
              //searchFields={searchFields}
              searchValue={searchValue}
              onSubmit={onSearchSubmit}
            />
          </React.Suspense>
        )
      }
      columnSettingComponent={
        !isSplitMode &&
        listType == ListType.LIST && (
          <ListTableColumnSetting
            menuSource={`${MENU_ANALYTIC}_${category}`}
            columns={columns}
            //hideColumns={[]}
            columLimit={10}
            onChange={handleColumnSettingChange}
          />
        )
      }
    />
  );
};

export default ListHeader;
