import React from 'react';
import {Avatar} from "@base/components";

interface IViewProps {
  value: any;
}

const View: React.FC<IViewProps> = (props: IViewProps) => {
  const {value = {}} = props;
  const name = value?.name ?? value?.fullName ?? '-No Name-';

  return <div className="media align-items-center">
    <div className="avatar avatar-xs">
      <Avatar
        key={value?.id ?? ''}
        url={value?.url ?? ''}
        alt={name}
        height={26}
        width={26}
      />
    </div>
    <div className="media-body mg-l-10">
      <span className="user-name">{name}</span>
    </div>
  </div>;
};

export default View;
