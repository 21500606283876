import { useGetTicketPerformance } from '@dashboard/dashboard/services/desk';
import BarSimple from '@dashboard/dashboard/components/chart/bar-simple';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const TicketGroupPerformanceByResolvedTicket = (props: ChartComponentProps) => {
  const {filters, ...restProps} = props;

  const {data} = useChartQuery(restProps, () => {
    return useGetTicketPerformance(filters ?? {});
  });

  const { results = [] } = data ?? {};

  const chartData = results?.reduce(
    (f: any, v: any) => {
      f['l'].push(v?.group?.name ?? '-');
      f['s'].push(v?.counting?.avgResolveTimeEfficiency ?? 0);
      return f;
    },
    { l: [], s: [] },
  ) ?? { l: [], s: [] };

  return <BarSimple categories={chartData?.l ?? []} series={chartData?.s ?? []} />;
};

export default withChartContainer(TicketGroupPerformanceByResolvedTicket);
