import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {useNavigate, useParams} from 'react-router-dom';
import {ListContainer} from '@base/components/list';
import {usePageLayoutByMenu} from '@base/hooks/usePageLayout';
import {deviceAtom} from '@base/recoil/atoms/app';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import {SpanLang} from '@base/components/form';
import {MENU_ANALYTIC} from '@base/config/menus';
import {listDataByMenuAtom} from '@base/recoil/atoms';
import {KEY_NAME_ID} from "@base/config/key-names";
import displayLayoutConfig from '@dashboard/report/configs/display-layout';
import PageHeader from './header';
import PageToolbar from './toolbar';
import PageBody from './body';
import PageQuickAction from './quick-actions';
import WritePage from '@dashboard/report/pages/write';
import {
  reportListFilterState,
  reportWriteState,
} from '@dashboard/report/recoil/atom';
import {useReports} from "@dashboard/report/services/list";
import {CATEGORY_REPORT, MENU_CATEGORY_REPORT} from "@dashboard/main/configs/general";
import SimpleModal from "@dashboard/main/components/simple-modal/simpleModal";
import ReportingContentPreview from "@dashboard/report/containers/reporting-content-preview";

interface ListPageProps {
  isSplitMode: boolean;
  setIsSplitMode: (isSplitMode: boolean) => void;
}

const ListPage: React.FC<ListPageProps> = (props: ListPageProps) => {
  const {isSplitMode, setIsSplitMode} = props;

  const params = useParams();
  let category = CATEGORY_REPORT;

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const {isDesktop, device, layout} = useRecoilValue(deviceAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [writeState, setWriteState] = useRecoilState(reportWriteState); //for write page
  const [listData, setListData] = useRecoilState(listDataByMenuAtom(MENU_CATEGORY_REPORT)); //current list
  const [listFilter, setListFilter] = useRecoilState<any>(reportListFilterState);
  const [previewData, setPreviewData] = useState<any>(null);

  const keyTitle = `crm_new_${category}`;
  const navigate = useNavigate();

  let {data: listLayoutData, isLoading: isLayoutLoading} = usePageLayoutByMenu(
    MENU_CATEGORY_REPORT,
    'list',
  );

  let viewingFields: any = [];
  if (listFilter.activeMenu === category && listFilter.settingColumns) {
    viewingFields = listFilter.settingColumns.filter((_ele: any) => _ele.isViewing);
  }

  const {data, isLoading, refetch}: any = useReports(category, viewingFields);

  useEffect(() => {
    if (listLayoutData?.data) {
      const newItems: any[] = [];
      listLayoutData?.data.map((_ele: any) => {
        newItems.push({..._ele, isViewing: _ele.isDefault});
      });
      const newListFilter = {
        ...listFilter,
        settingColumns: newItems,
        activeMenu: category,
      };
      setListFilter(newListFilter);
    }
  }, [listLayoutData, category]);

  //set data
  useEffect(() => {
    if (data?.data) {
      setListData(data?.data);
    } else {
      setListData([]);
    }
  }, [data?.data]);

  //for react-table v8
  const handleCheckItem = useCallback((checkedValue: any[]) => {
    setSelectedIds(checkedValue);
  }, [selectedIds]);

  //go view for new in case of split
  const handleGoView = (id: string) => {
    navigate(`/${MENU_ANALYTIC}/${category}/${id}`);
  };

  const handleOnPreview = (data: any) => {
    setPreviewData(data);
  }

  const PageToolbarMemo = useMemo(() => {
    return (
      <PageToolbar
        isSplitMode={isSplitMode}
        setIsSplitMode={setIsSplitMode}
        category={category}
        refetch={refetch}
      />
    );
  }, [isSplitMode, category]);

  const PageHeaderMemo = useMemo(() => {
    return (
      <PageHeader
        isSplitMode={isSplitMode}
        columnKeys={[]}
        fields={listFilter.settingColumns || []}
        category={category}
        onChecked={handleCheckItem}
        refetch={refetch}
      />
    );
  }, [isSplitMode, category, listFilter.settingColumns, listData, selectedIds]);

  const PageBodyMemo = useMemo(() => {
    return (
      <PageBody
        isSplitMode={isSplitMode}
        category={category}
        isFetching={false}
        fields={viewingFields}
        itemsList={listData}
        paging={data?.paging}
        selectedIds={selectedIds}
        onChecked={handleCheckItem}
        onPreview={handleOnPreview}
      />
    );
  }, [isSplitMode, category, listData, viewingFields, selectedIds]);

  useEffect(() => {
    if (params?.id === undefined && isSplitMode && listData.length > 0) {
      const [firstOrder] = listData;
      const url = `/${MENU_ANALYTIC}/${category}/${firstOrder[KEY_NAME_ID]}`
      navigate(url);
    }
  }, [category, listData]);

  return (
    <>
      <ListContainer
        isLoading={isLayoutLoading || isLoading}
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={PageToolbarMemo}
          header={PageHeaderMemo}
          body={PageBodyMemo}
        />
      </ListContainer>

      <PageQuickAction
        itemsList={listData}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        onReload={refetch}
      />

      {writeState.isOpenWrite && writeState.writeMenu === 'list' && (
        <WritePage
          title={<SpanLang keyLang={keyTitle}/>}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeState.isOpenWrite}
          onClose={() =>
            setWriteState((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          onReload={refetch}
          onGoView={(id: string) => isSplitMode && handleGoView(id)}
          menuApi={MENU_CATEGORY_REPORT}
        />
      )}

      {
        previewData !== null && (
          <SimpleModal
            title={previewData && previewData?.name ? previewData.name : 'Previewing Reporting Content'}
            fullScreen={true}
            onClose={() => setPreviewData(null)}
            onSuccess={() => handleGoView(previewData.id)}
            allowControls={{
              shrink: false,
              fullscreen: false,
              minimize: false
            }}
          >
            <ReportingContentPreview value={previewData}/>
          </SimpleModal>
        )
      }
    </>
  );
};

export default ListPage;
