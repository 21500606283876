import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Loading from '@base/components/loading';
import {MENU_ANALYTIC} from '@base/config/menus';
import ListToolbarContainer from '@base/containers/list/toolbar';
import ListContainer from '@base/components/list/list-container';
import {allCategories, CATEGORY_DASHBOARD} from "@dashboard/main/configs/general";
import {EPermit, ESectionType} from "@dashboard/main/types/enum";
import Section from "@dashboard/dashboard/components/section";
import {sectionOptions} from "@dashboard/main/configs/options";
import {keys} from "lodash";
import classNames from "classnames";

const UserPage = () => {
  const navigate = useNavigate();
  const userRole = EPermit.PERMIT_USER;

  const [domLoaded, setDomLoaded] = useState<any>({});

  const handleDomLoaded = (k: string, is: boolean) => {
    setDomLoaded({...domLoaded, [k]: is})
  }

  return (
    <>
      <ListContainer>
        <ListToolbarContainer
          categoryOptions={allCategories}
          categorySelected={CATEGORY_DASHBOARD}
          onCategoryChange={(category: string) => navigate(`/${MENU_ANALYTIC}/${category}`)}
          menu={MENU_ANALYTIC}
        />
        <div className="pd-10 scroll-box" style={{ height: 'calc(100vh - 65px)' }}>
          <Section
            title={sectionOptions[ESectionType.SECTION_CUSTOMER]}
            keyName={ESectionType.SECTION_CUSTOMER}
            userRole={userRole}
            onDOMLoaded={(loaded) => handleDomLoaded(ESectionType.SECTION_CUSTOMER, loaded)}
          />
          {
            keys(sectionOptions).map((k: any, i: number) => {
              const title = sectionOptions[k];

              if([ESectionType.SECTION_CUSTOMER].includes(k)){
                return null;
              }

              return (
                <Section
                  key={i}
                  title={title}
                  keyName={k}
                  userRole={userRole}
                  className={classNames({'mg-t-20': i > 0})}
                />
              );
            })
          }
          {!(domLoaded?.[ESectionType.SECTION_CUSTOMER]) && <Loading top={100} />}
        </div>
      </ListContainer>
    </>
  );
};

export default UserPage;
