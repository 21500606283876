import React, {ReactNode} from 'react';
import withMiModal from '@base/hooks/hocs/withMiModal';
import styled from "@emotion/styled";
import Button from "@base/components/form/button";

const Container = styled.div`
  background-color: var(--background-container);
`;

const Content = styled.div`
  height: calc(100vh - 130px);
`;

interface SimpleModalProps {
  children: ReactNode;
  onClose: () => void;
  onSuccess?: () => void;
}

const SimpleModal: React.FC<SimpleModalProps> = (props: SimpleModalProps) => {
  const { children, onClose, onSuccess } = props;

  return (
    <Container className="pd-15">
      <Content className="scroll-box">
        {children}
      </Content>
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="d-flex mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose && onClose()}>
            Close
          </button>
          <Button
            color="success"
            onClick={() => onSuccess && onSuccess()}
            loading={false}
            name="Go to Detail"
          />
        </div>
      </div>
    </Container>
  );
}

export default withMiModal(SimpleModal);