import { DESC } from '@base/config/constant';
import usePublicPost from '@base/hooks/usePublicPost';
import usePublicPosts from '@base/hooks/usePublicPosts';
import { getResponsePageLayout } from '@base/services/pagelayout-service';
import { IPageLayout, IPageLayoutResponse } from '@base/types/interfaces/pagelayout';
import { graphQLPublicApi } from '@base/utils/axios/graphql';
import { keyStringify } from '@base/utils/helpers';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
import {
  getTicketListQuery,
  getTrackingTicketViewQuery,
  GET_TRACKING_MENU_PAGELAYOUT,
  TRACKING_DESK_SURVEYS_GET,
  TRACKING_TICKET_COMMENTS_GET,
} from './graphql';

export const useTrackingTicketView = <T>(schemas = '', params: any = {}) => {
  const query = getTrackingTicketViewQuery(schemas);
  const menuKey = ['tracking_ticket', keyStringify(params, '')];

  const response = usePublicPost<T>(menuKey, query, params, {
    keepPreviousData: false,
    enabled: schemas != '' && params?.id?.length > 0,
  });
  return response;
};

export const getPageLayout = async <T>(menu: string, token: string): Promise<T | undefined> => {
  const res = await graphQLPublicApi<T>('tracking_menuPagelayout', GET_TRACKING_MENU_PAGELAYOUT, {
    menu,
    tk: token,
  });
  //// console.log('api res', res);
  return res;
};

//USE ASYNC
export const getPageLayoutForMenu = async (
  menu: string,
  token: string,
  device?: string,
): Promise<IPageLayoutResponse> => {
  //call api to get data
  const layoutData = await getPageLayout<IPageLayout>(menu, token);
  //// console.log('async layoutData', layoutData);

  return getResponsePageLayout(menu, layoutData);
};

export const useTrackingTickets = (filter: any, querySchema: string, token: string) => {
  let queryKey = ['tracking_tickets', keyStringify(filter, '')];
  let params = {
    filter: filter,
    token,
  };
  const response = usePublicPosts<ITicket[]>(queryKey, getTicketListQuery(querySchema), params, {
    enabled: querySchema != '' && token !== '',
  });
  return response;
};

/** ticket comment */
export const useTrackingTicketComments = ({ ticketId, paging, token }: any) => {
  const graphQLKey = 'tracking_ticketComments';

  //build filter
  let filtersQuery: any = {
    paging: {
      page: paging.page || 1,
      size: paging.size || 10,
    },
    sort: { field: 'createdAt', orderBy: DESC },
  };
  //build query
  filtersQuery.query = `ticket=${ticketId}`;
  //get params
  let params = {
    filter: filtersQuery,
    token,
  };

  const usePostResult = usePublicPosts<any[]>(
    [graphQLKey, keyStringify(filtersQuery, '')],
    TRACKING_TICKET_COMMENTS_GET,
    params,
    {
      enabled: ticketId.length > 0 && token.length > 0,
    },
  );

  return usePostResult;
};

export const useTrackingSurveys = (token: string) => {
  let queryKey = ['tracking_deskSiteSurveys', token];
  const response = usePublicPosts<any[]>(
    queryKey,
    TRACKING_DESK_SURVEYS_GET,
    {},
    {
      enabled: token !== '',
    },
  );
  return response;
};
