import React from 'react';
import TuiEditor from '@base/components/form/tui-editor';

interface IEditorEditProps {
  value: any;
  onChange: (params: any) => {};
}
const EditorEdit: React.FC<IEditorEditProps> = (props: IEditorEditProps) => {
  const { value, onChange } = props;

  return (
    <>
      <TuiEditor value={value} onChange={onChange} />
    </>
  );
};

export default EditorEdit;
