import React  from 'react';
import {useGetActivityPerformance} from '@dashboard/dashboard/services/activity';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import BarSimple from '@dashboard/dashboard/components/chart/bar-simple';
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const ActivityGroupTotal = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetActivityPerformance(props?.filters ?? {});
  });

  const { results = [] } = data ?? {};

  let oData: any = {
    categories: [],
    total: [],
  };

  if (!!results) {
    oData = results.reduce((f: any, v: any) => {
      const { total = 0 } = v?.counting ?? {};
      f['categories'].push(v?.user?.name ?? '-');
      f['total'].push(total);
      return f;
    }, oData);
  }

  return <BarSimple series={oData.total} categories={oData.categories} />;
};

export default withChartContainer(ActivityGroupTotal);
