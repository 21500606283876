import CountBlock from "@dashboard/dashboard/components/count-block";
import {useGetTicketTrendCounting} from "@dashboard/dashboard/services/desk";
import BlockBody from "@dashboard/dashboard/components/block-body";
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const TicketTrend = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetTicketTrendCounting(props?.filters ?? {});
  });

  const {firstCounting = {}, lastCounting = {}} = data ?? {};

  const labelOfOldValue = 'Last';

  return (
    <>
      <BlockBody className="d-flex bd-t">
        <CountBlock
          className='bd-r bd-b'
          title='New'
          newValue={firstCounting?.new}
          timeLabel={labelOfOldValue}
          oldValue={lastCounting?.new}
        />
        <CountBlock
          className='bd-r bd-b'
          title='Unassigned'
          newValue={firstCounting?.unassigned}
          timeLabel={labelOfOldValue}
          oldValue={lastCounting?.unassigned}
        />
        <CountBlock
          className='bd-r bd-b'
          title='Overdue'
          newValue={firstCounting?.overdue}
          timeLabel={labelOfOldValue}
          oldValue={lastCounting?.overdue}
        />
        <CountBlock
          className='bd-r bd-b'
          title='Due Today'
          newValue={firstCounting?.dueToday}
          timeLabel={labelOfOldValue}
          oldValue={lastCounting?.dueToday}
        />
        <CountBlock
          className='bd-r bd-b'
          title='Unresolved'
          newValue={firstCounting?.unresolved}
          timeLabel={labelOfOldValue}
          oldValue={lastCounting?.unresolved}
        />
        <CountBlock
          className='bd-b'
          title='Resolved'
          newValue={firstCounting?.resolved}
          timeLabel={labelOfOldValue}
          oldValue={lastCounting?.resolved}
        />
      </BlockBody>
      <BlockBody className="d-flex">
        <CountBlock
          className='bd-r'
          title='Avg. 1st Response Time'
          newValueView={firstCounting?.avg1stResponseTimeString}
          newValue={firstCounting?.avg1stResponseTime}
          timeLabel={labelOfOldValue}
          oldValueView={lastCounting?.avg1stResponseTimeString}
          oldValue={lastCounting?.avg1stResponseTime}
        />
        <CountBlock
          className='bd-r'
          title='Avg. Resolution Time'
          newValueView={firstCounting?.avgResolutionTimeString}
          newValue={firstCounting?.avgResolutionTime}
          timeLabel={labelOfOldValue}
          oldValueView={lastCounting?.avgResolutionTimeString}
          oldValue={lastCounting?.avgResolutionTime}
        />
        <CountBlock
          title='Avg. Resolve Time Efficiency'
          newValueView={firstCounting?.avgResolveTimeEfficiencyString}
          newValue={firstCounting?.avgResolveTimeEfficiency}
          timeLabel={labelOfOldValue}
          oldValueView={lastCounting?.avgResolveTimeEfficiencyString}
          oldValue={lastCounting?.avgResolveTimeEfficiency}
        />
      </BlockBody>
    </>
  );
}

export default withChartContainer(TicketTrend);