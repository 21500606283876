import React, {ReactNode} from 'react';
import withMiModal from '@base/hooks/hocs/withMiModal';
import styled from "@emotion/styled";
import Button from "@base/components/form/button";

const ContainerDiv = styled.div`
  background-color: var(--background-container);
`;

const ContentDiv = styled.div`
  max-height: calc(100vh - 200px);
`;

interface IDndModalProps {
  children: ReactNode;
  onClose?: () => void;
  onSave?: () => void;
  allowSave?: boolean;
}

const DndModal: React.FC<IDndModalProps> = (props: IDndModalProps) => {
  const { children, onClose, onSave, allowSave = false } = props;

  return (
    <ContainerDiv className="pd-15">
      <div className="tx-orange">
        Drag and Drop widget to change position!!!
      </div>
      <hr className="bd-dotted pd-y-5"/>
      <ContentDiv className="scroll-box">
        {children}
      </ContentDiv>
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="d-flex mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose && onClose()}>
            Cancel
          </button>
          <Button
            color="success"
            onClick={() => onSave && onSave()}
            disabled={!allowSave}
            loading={false}
            name="Apply"
          />
        </div>
      </div>
    </ContainerDiv>
  );
}

export default withMiModal(DndModal);