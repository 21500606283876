import {chartComponents} from "@dashboard/dashboard/config/chart";
import {EChartType} from "@dashboard/main/types/enum";
import {groupByAssignToUser} from "@dashboard/main/configs/default-query-string";

export const userConfigs = {
  [EChartType.CHART_ACTIVITY_TREND]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_TREND],
    key: EChartType.CHART_ACTIVITY_TREND,
    componentProps: {
      title: 'My Activity Trends',
      me: true,
      filterProps: {
        isDateNoPeriod: true
      }
    },
    wrapperProps: {
      gridCol: 12,
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_TODO]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_TODO],
    key: EChartType.CHART_ACTIVITY_TODO,
    componentProps: {
      title: 'My Todo',
      me: true,
      filterProps: {
        isShow: false
      }
    },
    wrapperProps: {
      gridCol: 12,
      gridColLandscape: 24,
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_REP_PERFORMANCE]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_REP_PERFORMANCE],
    key: EChartType.CHART_ACTIVITY_REP_PERFORMANCE,
    componentProps: {
      title: 'My Group\'s Reps Performance',
      me: true,
      filterProps: {
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      gridCol: 12,
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_TYPE]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_TYPE],
    key: EChartType.CHART_ACTIVITY_TYPE,
    componentProps: {
      title: 'My Activities',
      me: true
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_DURATION]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_DURATION],
    key: EChartType.CHART_ACTIVITY_DURATION,
    componentProps: {
      title: 'My Duration',
      me: true
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_GROUP_DURATION]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_GROUP_DURATION],
    key: EChartType.CHART_ACTIVITY_GROUP_DURATION,
    componentProps: {
      title: 'My Group Duration',
      me: true,
      filterProps: {
        onlyDateRangeFilter: true
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_GROUP_TOTAL]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_GROUP_TOTAL],
    key: EChartType.CHART_ACTIVITY_GROUP_TOTAL,
    componentProps: {
      title: 'My Group Total Activities',
      me: true,
      filterProps: {
        defaultQuery: 'groupBy=ASSIGN_TO_USER assignToGroup=MY_GROUP'
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  }
};

export const adminConfigs = {
  [EChartType.CHART_ACTIVITY_TREND]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_TREND],
    key: EChartType.CHART_ACTIVITY_TREND,
    componentProps: {
      title: 'Activity Trends',
      filterProps: {
        isDateNoPeriod: true
      }
    },
    wrapperProps: {
      gridCol: 12,
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_GROUP_PERFORMANCE]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_GROUP_PERFORMANCE],
    key: EChartType.CHART_ACTIVITY_GROUP_PERFORMANCE,
    componentProps: {
      title: 'Group Performance',
      filterProps: {
        defaultQuery: 'groupBy=assignToGroup'
      }
    },
    wrapperProps: {
      gridCol: 12,
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_REP_PERFORMANCE]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_REP_PERFORMANCE],
    key: EChartType.CHART_ACTIVITY_REP_PERFORMANCE,
    componentProps: {
      title: 'Rep. Performance',
      filterProps: {
        isDateUser: true,
        defaultQuery: 'groupBy=ASSIGN_TO_USER assignToGroup=MY_GROUP'
      }
    },
    wrapperProps: {
      gridCol: 12,
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_TOTAL_BY_GROUP]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_TOTAL_BY_GROUP],
    key: EChartType.CHART_ACTIVITY_TOTAL_BY_GROUP,
    componentProps: {
      title: 'Total Activities By Group',
      filterProps: {
        isDateGroup: true
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_TOTAL_BY_REP]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_TOTAL_BY_REP],
    key: EChartType.CHART_ACTIVITY_TOTAL_BY_REP,
    componentProps: {
      title: 'Total Activities By Rep',
      filterProps: {
        isDateUser: true,
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_CALL]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_CALL],
    key: EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_CALL,
    componentProps: {
      title: 'Top Performer by Call',
      filterProps: {
        isDateUser: true,
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_TASK]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_TASK],
    key: EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_TASK,
    componentProps: {
      title: 'Top Performer by Task',
      filterProps: {
        isDateUser: true,
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_EMAIL]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_EMAIL],
    key: EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_EMAIL,
    componentProps: {
      title: 'Top Performer by Email',
      filterProps: {
        isDateUser: true,
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_SMS]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_SMS],
    key: EChartType.CHART_ACTIVITY_TOP_PERFORMER_BY_SMS,
    componentProps: {
      title: 'Top Performer by Sms',
      filterProps: {
        isDateUser: true,
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_PURPOSE]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_PURPOSE],
    key: EChartType.CHART_ACTIVITY_PURPOSE,
    componentProps: {
      title: 'Activities By Purpose',
      filterProps: {
        isDateGroup: true
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_BY_TYPE]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_BY_TYPE],
    key: EChartType.CHART_ACTIVITY_BY_TYPE,
    componentProps: {
      title: 'Activities By Type',
      filterProps: {
        isDateGroup: true
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_OVERDUE_REP_CHART]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_OVERDUE_REP_CHART],
    key: EChartType.CHART_ACTIVITY_OVERDUE_REP_CHART,
    componentProps: {
      title: 'Overdue Activities By Rep',
      filterProps: {
        isDateUser: true,
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_ACTIVITY_BY_PRIORITY_CHART]: {
    ...chartComponents[EChartType.CHART_ACTIVITY_BY_PRIORITY_CHART],
    key: EChartType.CHART_ACTIVITY_BY_PRIORITY_CHART,
    componentProps: {
      title: 'Activities By Priority',
      filterProps: {
        isDateGroup: true
      }
    },
    wrapperProps: {
      gridCol: 6,
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
};