import {useGetActivityPerformance} from '@dashboard/dashboard/services/activity';
import Donut from '@dashboard/dashboard/components/chart/donut';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";

const ActivityTotalByGroup = (props: ChartComponentProps) => {
  const {data} = useChartQuery(props, () => {
    return useGetActivityPerformance(props?.filters ?? {});
  });

  const { results = [] } = data ?? {};

  let chartData: any = { l: [], s: [] };
  if (!!results) {
    chartData = results.reduce((f: any, v: any) => {
      f['l'].push(v?.user?.name ?? '-');
      f['s'].push(v?.counting?.total ?? 0);
      return f;
    }, chartData);
  }

  return <Donut series={chartData.s} categories={chartData.l} />;
};

export default withChartContainer(ActivityTotalByGroup);
