import { lazy } from 'react';

const MainPage = lazy(() => import('./main'));

export default [
  {
    path: '/site/*',
    component: MainPage,
  },
];
