import React  from 'react';
import BarSimple from '@dashboard/dashboard/components/chart/bar-simple';
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";

const CustomerSatisfactionQuestion1 = (props: ChartComponentProps) => {
  let chartData: any = { l: [
      ['John', 'Doe'],
      ['Joe', 'Smith'],
      ['Jake', 'Williams'],
      'Amber',
      ['Peter', 'Brown'],
      ['Mary', 'Evans'],
      ['David', 'Wilson'],
      ['Lily', 'Roberts']
    ], s: [21, 22, 10, 28, 16, 21, 13, 30] };

  return <BarSimple series={chartData.s} categories={chartData.l} />;
};

export default withChartContainer(CustomerSatisfactionQuestion1);
