import React, {Fragment, useEffect, useState} from "react";
import {ArrowDown} from "react-feather";
import {convertDateTimeServerToClient} from "@base/utils/helpers";
import {useGetLastUpdatedCustomers} from "@dashboard/dashboard/services/customer";
import withChartContainer, {ChartComponentProps} from "@dashboard/dashboard/hooks/hocs/withChartContainer";
import {useChartQuery} from "@dashboard/dashboard/hooks/useChartQuery";
import {cloneDeep} from "lodash";

interface ICustomerUpdatedBlockProps {
  name: string;
  time: string;
}

const CustomerUpdatedBlock: React.FC<ICustomerUpdatedBlockProps> = (props: ICustomerUpdatedBlockProps) => {
  const {name, time} = props;

  return (
    <>
      <div className="avatar"><span className="avatar-initial rounded-circle bg-gray-600">s</span></div>
      <div className="pd-l-10">
        <p className="tx-medium mg-b-0">{name}</p>
        <small className="tx-12 tx-color-03 mg-b-0">{convertDateTimeServerToClient({
          date: time,
          humanize: true
        })}</small>
      </div>
    </>
  );
};

// ----

const CustomerUpdated = (props: ChartComponentProps) => {
  const {
    filters: iFilters = {},
    setToolbarProps,
    ...restProps
  } = props;

  const [filters, setFilters] = useState<any>({
    filter: {
      ...iFilters?.filter ?? {},
      paging: {
        page: 1,
        size: 15
      }
    }
  });

  const setPaging = (pageNumber: string) => {
    let newFilter = {
      ...filters,
      filter: {
        ...filters.filter,
        paging: {
          ...filters.filter.page,
          page: pageNumber
        }
      },
    };
    setFilters(newFilter);
  };

  const {data, refetch} = useChartQuery(restProps, () => {
    return useGetLastUpdatedCustomers(cloneDeep(filters));
  });

  useEffect(() => {
    setToolbarProps && setToolbarProps({
      onRefetch: refetch
    });
  }, []);

  const {results = [], paging = {}} = data ?? {};

  const [customers, setCustomers] = useState<any[]>([]);

  useEffect(() => {
    if (results && !!results?.length) {
      setCustomers([...customers, ...results])
    }
  }, [results])

  return (
      <div className="d-flex flex-column ht-100p">
        <div className="flex-grow-1 overflow-y-auto">
          <ul className="list-group list-group-flush tx-13">
            {
              !!customers?.length && customers.map((v: any, i: number) => {
                return (
                  <Fragment key={i}>
                    <li key={i} className="d-flex no-border pd-15 pd-sm-x-20">
                      <CustomerUpdatedBlock name={v.name} time={v.updatedAt}/>
                    </li>
                    {i !== customers.length && <hr className="bd-dotted wd-90p align-self-center mg-y-0"/>}
                  </Fragment>
                );
              })
            }
          </ul>
        </div>
        {
          paging?.currentPage < paging?.totalPage && <div className="card-footer text-center tx-13">
                <a className="link-03" onClick={() => setPaging(paging.currentPage + 1)}>View More
                    Customers <ArrowDown size={14}/></a>
            </div>
        }
      </div>
  );
};

export default withChartContainer(CustomerUpdated);