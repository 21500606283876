import * as keyNames from '@dashboard/report/configs/key-names';
import * as commonComponents from '@base/config/write-field/components';
import {IWriteConfig} from '@base/types/interfaces/common';
import validators from '@base/utils/validation/field-validator';
import * as components from './components';
import {isArray, isString, keys} from "lodash";
import {EDisplayGridType} from "@dashboard/report/types/enum";
import {dateRangeOptions} from "@base/config/options";
import {EDateRangeType} from "@base/types/enums";

const writeConfig: IWriteConfig = {
  [keyNames.REPORT_NAME]: {
    component: commonComponents.Input,
    componentProps: {
      type: 'text',
    },
    validate: {
      required: validators.required,
    },
    showFullRow: true,
  },
  [keyNames.REPORT_RECIPIENT]: {
    component: commonComponents.UserAutoComplete,
    componentProps: {},
    showFullRow: true,
    parseParam: (value: any) => {
      return !!value ? value?.map((u: any) => ({
        user: {
          id: u.id ? u.id : '',
          name: u.name ? u.name : '',
        },
        group: {
          id: u.properties?.crmGroups?.length > 0 ? u.properties?.crmGroups[0]?.id : '',
          name: u.properties?.crmGroups?.length > 0 ? u.properties?.crmGroups[0]?.name : '',
        },
      })) : null;
    },
  },
  [keyNames.REPORT_SUBJECT]: {
    component: commonComponents.Input,
    componentProps: {
      type: 'text',
    },
    validate: {
      required: validators.required,
    },
    showFullRow: true,
  },
  [keyNames.REPORT_CONTENT]: {
    component: commonComponents.TextArea,
    componentProps: {},
    showFullRow: true,
    parseParam: (value: any) => {
      return value;
    },
  },
  [keyNames.REPORT_ASSIGNMENT_GROUP]: {
    component: components.AssignmentGroup,
    componentProps: {},
    showFullRow: true,
    parseParam: (value: any) => {
      return {
        assignmentGroupType: !!value ? value?.assignmentGroupType : null,
        assignmentGroups: !!value ? value?.assignmentGroups?.map((g: any) => ({
          id: g.id ? g.id : '',
          name: g.name ? g.name : '',
        })) : null,
      }
    },
  },
  [keyNames.REPORT_DATE_RANGE]: {
    component: commonComponents.Select,
    componentProps: {
      options: keys(dateRangeOptions).map((k) => {
        return {
          label: dateRangeOptions[k],
          value: k
        };
      }),
      isSearchable: false,
      single: true,
    },
    defaultValue: {
      label: dateRangeOptions[EDateRangeType.DATE_RANGE_THIS_MONTH],
      value: EDateRangeType.DATE_RANGE_THIS_MONTH
    },
    showFullRow: true,
    parseParam: (value: any) => {
      return value && value?.value ? value.value : null;
    },
  },
  [keyNames.REPORT_REPORTING_CYCLE]: {
    component: components.ReportingCycle,
    componentProps: {},
    showFullRow: true,
    parseParam: (value: any) => {
      return !!value ? value : null;
    },
  },
  [keyNames.REPORT_REPORTING_CONTENT]: {
    component: components.ReportingContent,
    componentProps: {},
    showFullRow: true,
    parseParam: (value: any) => {
      let pages = !!value && isArray(value) ? value.map((p) => {
        if(p?.hasOwnProperty('displayGrid') && isString(p.displayGrid)){
          p.displayGrid = p.displayGrid === EDisplayGridType.DISPLAY_GRID_SHOW;
        }
        if(!!p?.isNewPage){
          delete p.id;
          delete p.isNewPage;
        }
        return p;
      }) : null;

      return {
        pages
      }
    },
  }
};

export default writeConfig;
