import {EChartType} from "@dashboard/main/types/enum";
import {chartComponents} from "@dashboard/dashboard/config/chart";
import {
  assignToMe,
  assignToMyGroup,
  groupByAssignToGroup,
  groupByAssignToUser
} from "@dashboard/main/configs/default-query-string";

export const userConfigs = {
  [EChartType.CHART_TICKET_TREND]: {
    ...chartComponents[EChartType.CHART_TICKET_TREND],
    key: EChartType.CHART_TICKET_TREND,
    componentProps: {
      title: 'My Ticket Trend',
      me: true,
      filterProps: {
        isDateNoPeriod: true
      }
    },
    wrapperProps: {
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY]: {
    ...chartComponents[EChartType.CHART_TICKET_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY],
    key: EChartType.CHART_TICKET_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY,
    componentProps: {
      title: 'My Performer by Resolve Time Efficiency',
      me: true,
      filterProps: {
        defaultQuery: assignToMe
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_OVERVIEW]: {
    ...chartComponents[EChartType.CHART_TICKET_OVERVIEW],
    key: EChartType.CHART_TICKET_OVERVIEW,
    componentProps: {
      title: 'My Tickets Overview',
      me: true,
      filterProps: {
        isDateGroup: true
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_PERFORMER_BY_RESOLVE_TIME]: {
    ...chartComponents[EChartType.CHART_TICKET_PERFORMER_BY_RESOLVE_TIME],
    key: EChartType.CHART_TICKET_PERFORMER_BY_RESOLVE_TIME,
    componentProps: {
      title: 'My Performer by Resolution Time',
      me: true,
      filterProps: {
        defaultQuery: assignToMe
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE]: {
    ...chartComponents[EChartType.CHART_TICKET_GROUP_PERFORMANCE],
    key: EChartType.CHART_TICKET_GROUP_PERFORMANCE,
    componentProps: {
      title: 'My Group Performance',
      me: true
    },
    wrapperProps: {
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_ASSIGNED_TICKET]: {
    ...chartComponents[EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_ASSIGNED_TICKET],
    key: EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_ASSIGNED_TICKET,
    componentProps: {
      title: 'My Group Performer by Assigned Tickets',
      me: true,
      filterProps: {
        defaultQuery: assignToMyGroup
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVE_TIME_EFFICIENCY]: {
    ...chartComponents[EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVE_TIME_EFFICIENCY],
    key: EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVE_TIME_EFFICIENCY,
    componentProps: {
      title: 'My Group Performance by Resolve Time Efficiency',
      me: true,
      filterProps: {
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVED_TICKET]: {
    ...chartComponents[EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVED_TICKET],
    key: EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLVED_TICKET,
    componentProps: {
      title: 'My Group Performer by Resolved Tickets',
      me: true,
      filterProps: {
        defaultQuery: groupByAssignToGroup
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_UNRESOLVED_TICKET]: {
    ...chartComponents[EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_UNRESOLVED_TICKET],
    key: EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_UNRESOLVED_TICKET,
    componentProps: {
      title: 'My Group Performance by Unresolved Tickets',
      me: true,
      filterProps: {
        defaultQuery: groupByAssignToGroup
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLUTION_TIME]: {
    ...chartComponents[EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLUTION_TIME],
    key: EChartType.CHART_TICKET_GROUP_PERFORMANCE_BY_RESOLUTION_TIME,
    componentProps: {
      title: 'My Group Performance by Resolution Time',
      me: true,
      filterProps: {
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_PRIORITY]: {
    ...chartComponents[EChartType.CHART_TICKET_PRIORITY],
    key: EChartType.CHART_TICKET_PRIORITY,
    componentProps: {
      title: 'My Tickets by Priority',
      me: true,
      filterProps: {
        defaultQuery: assignToMe
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_TOP_ISSUE]: {
    ...chartComponents[EChartType.CHART_TICKET_TOP_ISSUE],
    key: EChartType.CHART_TICKET_TOP_ISSUE,
    componentProps: {
      title: 'My Top 10 Issues',
      me: true
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_KB_REP_PUBLISHED_ARTICLE]: {
    ...chartComponents[EChartType.CHART_KB_REP_PUBLISHED_ARTICLE],
    key: EChartType.CHART_KB_REP_PUBLISHED_ARTICLE,
    componentProps: {
      title: 'My Group Performer by Published Articles',
      me: true,
      filterProps: {
        defaultQuery: assignToMyGroup
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_KB_REP_INSERTED_ARTICLE]: {
    ...chartComponents[EChartType.CHART_KB_REP_INSERTED_ARTICLE],
    key: EChartType.CHART_KB_REP_INSERTED_ARTICLE,
    componentProps: {
      title: 'My Group Performer by Inserted Articles',
      me: true,
      filterProps: {
        defaultQuery: assignToMyGroup
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  }
};

export const adminConfigs = {
  [EChartType.CHART_TICKET_TREND]: {
    ...chartComponents[EChartType.CHART_TICKET_TREND],
    key: EChartType.CHART_TICKET_TREND,
    componentProps: {
      title: 'Ticket Trend',
      filterProps: {
        isDateNoPeriod: true
      }
    },
    wrapperProps: {
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_BY_CATEGORY]: {
    ...chartComponents[EChartType.CHART_TICKET_BY_CATEGORY],
    key: EChartType.CHART_TICKET_BY_CATEGORY,
    componentProps: {
      title: 'Tickets By Category',
      filterProps: {
        isDateGroup: true,
        defaultQuery: 'typeOf=TICKET_BLOCK_BY_CATEGORY'
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_AVG_SUPPORT_PERFORMER]: {
    ...chartComponents[EChartType.CHART_TICKET_AVG_SUPPORT_PERFORMER],
    key: EChartType.CHART_TICKET_AVG_SUPPORT_PERFORMER,
    componentProps: {
      title: 'Ticket Avg. Support Performance'
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_OVERVIEW]: {
    ...chartComponents[EChartType.CHART_TICKET_OVERVIEW],
    key: EChartType.CHART_TICKET_OVERVIEW,
    componentProps: {
      title: 'Tickets Overview',
      filterProps: {
        isDateGroup: true
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_GROUP_PERFORMER]: {
    ...chartComponents[EChartType.CHART_TICKET_GROUP_PERFORMER],
    key: EChartType.CHART_TICKET_GROUP_PERFORMER,
    componentProps: {
      title: 'Group Performance',
      filterProps: {
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_REP_PERFORMER]: {
    ...chartComponents[EChartType.CHART_TICKET_REP_PERFORMER],
    key: EChartType.CHART_TICKET_REP_PERFORMER,
    componentProps: {
      title: 'Rep Performance',
      filterProps: {
        isDateUser: true,
        defaultQuery: groupByAssignToUser
      }
    },
    wrapperProps: {
      className: "col-12"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_AVG_RESOLUTION_TIME]: {
    ...chartComponents[EChartType.CHART_TICKET_TOP_PERFORMER_BY_AVG_RESOLUTION_TIME],
    key: EChartType.CHART_TICKET_TOP_PERFORMER_BY_AVG_RESOLUTION_TIME,
    componentProps: {
      title: 'Top Performer by Avg.Resolution Time',
      filterProps: {
        defaultQuery: groupByAssignToGroup
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_RESOLVED_TICKET]: {
    ...chartComponents[EChartType.CHART_TICKET_TOP_PERFORMER_BY_RESOLVED_TICKET],
    key: EChartType.CHART_TICKET_TOP_PERFORMER_BY_RESOLVED_TICKET,
    componentProps: {
      title: 'Top Performer by Resolved Tickets',
      filterProps: {
        defaultQuery: groupByAssignToGroup
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY]: {
    ...chartComponents[EChartType.CHART_TICKET_TOP_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY],
    key: EChartType.CHART_TICKET_TOP_PERFORMER_BY_RESOLVE_TIME_EFFICIENCY,
    componentProps: {
      title: 'Top Performer by Resolve Time Efficiency',
      filterProps: {
        defaultQuery: groupByAssignToGroup
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_TOP_PERFORMER_BY_UNRESOLVED_TICKET]: {
    ...chartComponents[EChartType.CHART_TICKET_TOP_PERFORMER_BY_UNRESOLVED_TICKET],
    key: EChartType.CHART_TICKET_TOP_PERFORMER_BY_UNRESOLVED_TICKET,
    componentProps: {
      title: 'Top Performer by Unresolved Tickets',
      filterProps: {
        defaultQuery: groupByAssignToGroup
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_REP_BY_AVG_RESOLUTION_TIME]: {
    ...chartComponents[EChartType.CHART_TICKET_REP_BY_AVG_RESOLUTION_TIME],
    key: EChartType.CHART_TICKET_REP_BY_AVG_RESOLUTION_TIME,
    componentProps: {
      title: 'Reps by Avg. Resolution Time',
      filterProps: {
        defaultQuery: 'typeOf=TICKET_BLOCK_REP_BY_AVG_RESOLUTION_TIME'
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_REP_BY_RESOLVED_TICKET]: {
    ...chartComponents[EChartType.CHART_TICKET_REP_BY_RESOLVED_TICKET],
    key: EChartType.CHART_TICKET_REP_BY_RESOLVED_TICKET,
    componentProps: {
      title: 'Reps by Resolved Tickets',
      filterProps: {
        isDateUser: true,
        defaultQuery: 'typeOf=TICKET_BLOCK_REP_BY_RESOLVED_TICKETS'
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_REP_BY_RESOLVE_TIME_EFFICIENCY]: {
    ...chartComponents[EChartType.CHART_TICKET_REP_BY_RESOLVE_TIME_EFFICIENCY],
    key: EChartType.CHART_TICKET_REP_BY_RESOLVE_TIME_EFFICIENCY,
    componentProps: {
      title: 'Reps by Resolve Time Efficiency',
      filterProps: {
        isDateUser: true,
        defaultQuery: 'typeOf=TICKET_BLOCK_REP_BY_RESOLVED_TIME_EFFICIENCY'
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_REP_BY_UNRESOLVED_TICKET]: {
    ...chartComponents[EChartType.CHART_TICKET_REP_BY_UNRESOLVED_TICKET],
    key: EChartType.CHART_TICKET_REP_BY_UNRESOLVED_TICKET,
    componentProps: {
      title: 'Reps by Unresolved Tickets',
      filterProps: {
        isDateUser: true,
        defaultQuery: 'typeOf=TICKET_BLOCK_TOP_PERFORMANCE_BY_UNRESOLVED_TICKETS'
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME]: {
    ...chartComponents[EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME],
    key: EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME,
    componentProps: {
      title: 'Top Group by Avg. Resolution Time',
      filterProps: {
        defaultQuery: 'typeOf=TICKET_BLOCK_TOP_PERFORMANCE_BY_UNRESOLVED_TICKETS'
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME_EFFICIENCY]: {
    ...chartComponents[EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME_EFFICIENCY],
    key: EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLUTION_TIME_EFFICIENCY,
    componentProps: {
      title: 'Top Group by Avg. Resolve Time Efficiency',
      filterProps: {
        defaultQuery: 'typeOf=TICKET_BLOCK_TOP_GROUP_BY_AVG_RESOLVED_TIME_EFFICIENCY'
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLVED_TICKET]: {
    ...chartComponents[EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLVED_TICKET],
    key: EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_RESOLVED_TICKET,
    componentProps: {
      title: 'Top Group by Resolved Tickets',
      filterProps: {
        defaultQuery: 'typeOf=TICKET_BLOCK_TOP_GROUP_BY_AVG_RESOLVED_TICKETS'
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_UNRESOLVED_TICKET]: {
    ...chartComponents[EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_UNRESOLVED_TICKET],
    key: EChartType.CHART_TICKET_TOP_GROUP_BY_AVG_UNRESOLVED_TICKET,
    componentProps: {
      title: 'Top Group by Unresolved Tickets',
      filterProps: {
        defaultQuery: 'typeOf=TICKET_BLOCK_TOP_GROUP_BY_AVG_UNRESOLVED_TICKETS'
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_KB_OVERVIEW]: {
    ...chartComponents[EChartType.CHART_KB_OVERVIEW],
    key: EChartType.CHART_KB_OVERVIEW,
    componentProps: {
      title: 'KB Overview'
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_KB_REP_PUBLISHED_ARTICLE]: {
    ...chartComponents[EChartType.CHART_KB_REP_PUBLISHED_ARTICLE],
    key: EChartType.CHART_KB_REP_PUBLISHED_ARTICLE,
    componentProps: {
      title: 'Reps by Published Articles',
      filterProps: {
        isDateUser: true
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_KB_REP_VIEWED_ARTICLE]: {
    ...chartComponents[EChartType.CHART_KB_REP_VIEWED_ARTICLE],
    key: EChartType.CHART_KB_REP_VIEWED_ARTICLE,
    componentProps: {
      title: 'Reps by Viewed Articles',
      filterProps: {
        isDateUser: true
      }
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  },
  [EChartType.CHART_KB_REP_INSERTED_ARTICLE]: {
    ...chartComponents[EChartType.CHART_KB_REP_INSERTED_ARTICLE],
    key: EChartType.CHART_KB_REP_INSERTED_ARTICLE,
    componentProps: {
      title: 'Reps by Inserted Articles',
      isDateUser: true
    },
    wrapperProps: {
      className: "col-12 col-lg-6 ht-500"
    },
    dndItemProps: {
      className: "mg-y-5"
    }
  }
};