import { lazy } from 'react';
export const ProductAutoComplete = lazy(
  () => import('@product/product/containers/product-auto-complete'),
);
export const TicketPriority = lazy(
  () => import('@desk/ticket/containers/view-field/ticket-priority'),
);
export const CustomerView = lazy(
  () => import('@customer/customer/containers/view-field/customer-view'),
);
// export const CategoryProductView = lazy(
//   () => import('@desk/ticket/containers/view-field/ticket-category-product'),
// );
export const AssginedGroupView = lazy(
  () => import('@desk/ticket/containers/view-field/assigned-group'),
);
export const AssignedUserView = lazy(
  () => import('@desk/ticket/containers/view-field/assigned-user'),
);
export const AssignGroupRepView = lazy(
  () => import('@desk/ticket/containers/view-field/assign-group-rep'),
);
export const CcUsersView = lazy(() => import('@desk/ticket/containers/view-field/cc-users'));
export const FristRespondDueView = lazy(
  () => import('@desk/ticket/containers/view-field/frist-respond-due'),
);
export const ResolutionDueView = lazy(
  () => import('@desk/ticket/containers/view-field/resolution-due'),
);
export const TicketClassificationView = lazy(
  () => import('@desk/ticket/containers/view-field/ticket-classification'),
);
export const CustomerContactView = lazy(
  () => import('@desk/ticket/containers/view-field/customer-contact'),
);

/********* PUBLIC ******************* */
export const CategoryProductView = lazy(
  () => import('@site-page/containers/desk/fields/ticket-category-product'),
);
export const TagView = lazy(() => import('@site-page/containers/desk/fields/tags'));
export const ClassificationView = lazy(
  () => import('@site-page/containers/desk/fields/ticket-classification'),
);
export const EditorView = lazy(() => import('@site-page/containers/desk/fields/editor'));
