import { Select } from '@base/components/form';
import { isUndefined } from 'lodash';
import React, { useState } from 'react';
import { LabelValue } from '@base/types/types/app';
import { extractUrlParams } from '@site-page/utils/Params';

interface TagsEditProps {
  options?: LabelValue[];
  outSide?: boolean;
  value: any;
  onChange: (params: any) => void;
  fieldValue?: string;
  fieldLabel?: string;
  fetchList?: (keyword: string, token: string) => {};
}

const TagsEdit = (props: TagsEditProps) => {
  const {
    value,
    onChange,
    options,
    fieldValue = 'value',
    fieldLabel = 'label',
    fetchList,
    outSide = false,
  } = props;
  //state
  const [keyword, setKeyword] = useState('');
  const placeholder = 'Select...';
  const siteParams = extractUrlParams();

  //get data
  const { data, isLoading }: any = options
    ? { data: { results: options }, isLoading: false }
    : fetchList && fetchList(keyword, siteParams?.tk || '');

  //value change
  const onInputChange = (newValue: string, event: any) => {
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setKeyword(newValue);
    }
  };

  return (
    <Select
      outSide={outSide}
      onChange={onChange}
      isMulti
      isSearchable={true}
      isLoading={isLoading}
      placeholder={<div className="react-select-placeholder">{placeholder}</div>}
      options={data?.results ?? data?.data}
      getOptionLabel={(c: any) => {
        return !isUndefined(c[fieldLabel]) ? c[fieldLabel] : '';
      }}
      getOptionValue={(c: any) => {
        return !isUndefined(c[fieldValue]) ? c[fieldValue] : '';
      }}
      inputValue={keyword}
      defaultValue={value}
      onInputChange={onInputChange}
    />
  );
};

export default TagsEdit;
