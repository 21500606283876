import React from 'react';
import {repeatUnitOptions, weekDayOptions} from "@dashboard/main/configs/options";
import {ERepeatUnit} from "@dashboard/main/types/enum";
import {ViewTextNoData} from "./style";

interface ViewProps {
  value: any;
}

const getShortNameOfDay = (day: number) => {
  const lastNumber = parseInt(day.toString().slice(-1))
  if ([1, 2, 3].includes(lastNumber)) {
    return {
      1: 'st',
      2: 'nd',
      3: 'rd'
    }[lastNumber];
  }
  return 'th';
}

const View: React.FC<ViewProps> = (props: ViewProps) => {
  const {value} = props;

  if(!value) {
    return <div className="d-flex flex-wrap align-items-center">
      <ViewTextNoData/>
    </div>;
  }

  const {
    frequency,
    dayAt,
    monthAt,
    timeAt
  } = value ?? {};

  const frequencyStr = repeatUnitOptions[frequency];

  const getEvery = (): string => {
    if(frequency === ERepeatUnit.REPEAT_UNIT_DAILY){
      return timeAt;
    } else if(frequency === ERepeatUnit.REPEAT_UNIT_WEEKLY){
      return `${weekDayOptions[dayAt]} ${timeAt}`;
    } else if (frequency === ERepeatUnit.REPEAT_UNIT_MONTHLY){
      return `${dayAt}${getShortNameOfDay(dayAt)} ${timeAt}`;
    } else if(frequency === ERepeatUnit.REPEAT_UNIT_YEARLY){
      return `${monthAt}/${dayAt}${getShortNameOfDay(dayAt)} ${timeAt}`;
    }
    return '';
  };

  return <div className="d-flex flex-wrap align-items-center">
    <span className="badge badge-success">{frequencyStr}</span>
    <span className="mg-x-10">Every:</span>
    <span className="badge badge-success">{getEvery()}</span>
  </div>;
};

export default View;
